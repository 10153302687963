import firebase from 'firebase/app'
import 'firebase/messaging'
//https://blog.logrocket.com/push-notifications-with-react-and-firebase/

let messaging = null
var firebaseConfig = {
  apiKey: "AIzaSyDZriN_6mIoUWTbs_-OEttLt9R58qYNVE0",
  authDomain: "buggy-live-staging.firebaseapp.com",
  databaseURL: "https://buggy-live-staging.firebaseio.com",
  projectId: "buggy-live-staging",
  storageBucket: "buggy-live-staging.appspot.com",
  messagingSenderId: "641167209771",
  appId: "1:641167209771:web:983c97395e0ec2edf5016e",
  measurementId: "G-YZ7DWNSZWX"
}
if (firebase.messaging.isSupported()) {
  firebase.initializeApp(firebaseConfig)
  messaging = firebase.messaging()
} else {
  console.log("Firebase messaging not supported")
}



export const getToken = (setTokenFound) => {
  try {
    return messaging.getToken({ vapidKey: 'BPkY6144XKqbu7zqAb-7a4usYyDE5Zlua9ykdANUC59LUl8qde1S1-2jnbUVyWRYeOdnvz0_7hXktelU_IdMBps' }).then((currentToken) => {
      if (currentToken) {
        setTokenFound(currentToken)
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.')
        setTokenFound(null)
        // shows on the UI that permission is required 
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err)
      // catch error while creating client token
    })
  } catch { }
}

export const onMessageListener = (callback) =>
  new Promise((resolve) => {
    if (messaging) {
      messaging.onMessage((payload) => {
        callback(payload)
      })
    }
  })