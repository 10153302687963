// src/components/AutomationForm.js
import React, { useEffect, useState, useRef } from 'react'
import { compose, graphql } from 'react-apollo'
import { withApollo } from 'react-apollo/index'
import { AllAvailableModelsQuery, ActionTypesAndParamsQuery, ConditionTypes, TriggerTypes } from './Queries'
import { CREATE_AUTOMATION_WORKFLOW } from './Mutations'
import {
    Button,
    Form,
    FormText,
    FormGroup,
    Label,
    Input,
    Container,
    Row,
    Col,
    Popover,
    PopoverHeader,
    PopoverBody,
    Badge,
} from 'reactstrap'
import Select from 'react-select'
import { Link } from "react-router-dom"



const AutomationForm = ({ allAvailableModels, actionTypesAndParams, conditionTypes, triggerTypes, createAutomationWorkflow, ...props }) => {
    const [name, setName] = useState('')
    const [isActive, setIsActive] = useState(true)
    const [triggerType, setTriggerType] = useState('')
    const [columnsUpdated, setColumnsUpdated] = useState([])
    const [availableModel, setAvailableModel] = useState(null)
    const [logicalOperator, setLogicalOperator] = useState('AND')
    const DEFAULT_CONDITION = { version: 'CURRENT', fieldName: '', conditionType: '', conditionValue: '' }
    const [conditions, setConditions] = useState([DEFAULT_CONDITION])
    const [actionType, setActionType] = useState()
    const [actionParams, setActionParams] = useState({})
    const [actionParamsValues, setActionParamsValues] = useState({})
    const [error, setError] = useState(null)
    const [openModal, setOpenModal] = useState('')
    const [currentCursorPosition, setCurrentCursorPosition] = useState(0)
    const inputRefs = useRef({})



    const parseRecentObjects = (recentObjects) => {
        const result = JSON.parse(recentObjects)
        return result
    }

    const handleConditionChange = (index, field, value) => {
        const newConditions = [...conditions]
        newConditions[index][field] = value
        setConditions(newConditions)
    }

    const handleActionParamChange = (field, value, append) => {
        const newActionParamsValues = { ...actionParamsValues }
        newActionParamsValues[field] = append && newActionParamsValues[field] ? `${newActionParamsValues[field]}${value}` : value
        setActionParamsValues(newActionParamsValues)
    }

    const handleInstanceFieldClick = (paramName, field) => {
        const newActionParamsValues = { ...actionParamsValues }
        const inputRef = inputRefs.current[paramName]
        const value = newActionParamsValues[paramName]
        const newValue = `${value.slice(0, currentCursorPosition)}{{instance.${field}}}${value.slice(currentCursorPosition)}`
        newActionParamsValues[paramName] = newValue
        setActionParamsValues(newActionParamsValues)
        inputRef.focus()
        inputRef.setSelectionRange(currentCursorPosition + 11, currentCursorPosition + 11 + field.length)
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        // Validations
        // If trigger is on_update, then at least one trigger column should be selected
        if (triggerType === 'on_update' && !columnsUpdated.length) {
            setError('At least one trigger column should be selected for on_update trigger type')
            return
        }
        if (!availableModel || !name || !triggerType || !logicalOperator || !actionType) {
            setError('All fields are required')
            return
        }

        // Run mutation
        let inputConditions = conditions.filter((condition) => condition.fieldName && condition.conditionType)
        createAutomationWorkflow({
            variables: {
                input: {
                    name,
                    isActive,
                    triggerType,
                    availableModelId: availableModel.id,
                    columnsUpdated,
                    logicalOperator,
                    conditions: inputConditions,
                    actionType,
                    actionParams: JSON.stringify(actionParamsValues),
                },
            },
        }).then(({ data }) => {
            if (data.createAutomationWorkflow.ok) {
                // Redirect to list page
                window.location.href = '/automations/'
            } else {
                setError(data.createAutomationWorkflow.errors[0].messages[0])
            }
        })
    }

    useEffect(() => {
        // When actionTypes changes, set default values for actionParams
        if (actionType && actionParams && actionParams.length > 0) {
            const defaultActionParams = {}
            actionParams.forEach((param) => {
                defaultActionParams[param.name] = param.default || ''
            })
            setActionParamsValues(defaultActionParams)
        }
    }, [actionType, actionParams])


    return (
        <Container fluid>
            <Row>
                <Col xs="6">
                    <Row>
                        <Col>
                            {/* Small button to go back */}
                            <Link to="/automations/" className="btn btn-sm btn-secondary mb-2"> {"<"} Back</Link>
                            <h2>New Automation Workflow</h2>
                            <p>Automations are workflows that are triggered based on certain conditions and perform actions based on those conditions.</p>
                            {error && <div className="alert alert-danger">{error}</div>}
                            <Form onSubmit={handleSubmit}>
                                <FormGroup>
                                    <Label for="name">Name</Label>
                                    <Input
                                        type="text"
                                        id="name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label>Model to trigger from</Label>
                                    <br />
                                    <Select className="bos-custom-select"
                                        classNamePrefix="bos-select" isLoading={false}
                                        options={
                                            allAvailableModels && allAvailableModels.edges.map(({ node }) => ({
                                                label: `${node.contentType.appLabel.toUpperCase()} - ${node.contentType.model.toUpperCase()}`,
                                                value: node,
                                            }))}
                                        placeholder="-- Select model --"
                                        onChange={(option) => setAvailableModel(option.value)}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="triggerType">Trigger Type</Label>
                                    <Select className="bos-custom-select"
                                        classNamePrefix="bos-select" isLoading={false}
                                        options={
                                            triggerTypes && triggerTypes.states.map((item) => ({
                                                label: item.name ? item.name.replaceAll('_', ' ').toUpperCase() : '',
                                                value: item.name,
                                            }))
                                        }
                                        placeholder="-- Select trigger --"
                                        onChange={(option) => setTriggerType(option.value)}
                                    />
                                </FormGroup>
                                {triggerType === 'ON_UPDATE' && (
                                    <FormGroup>
                                        <Label for="columnsUpdated">Updated Columns</Label>
                                        <Select
                                            className="bos-custom-select"
                                            classNamePrefix="bos-select"
                                            isLoading={false}
                                            isMulti
                                            options={
                                                availableModel ? availableModel.availableModelFields.map((field) => ({
                                                    label: field ? field.replaceAll('_', ' ').toUpperCase() : '',
                                                    value: field,
                                                })) : []
                                            }
                                            placeholder="Select columns"
                                            onChange={(options) => setColumnsUpdated(options.map((option) => option.value))}
                                        />
                                    </FormGroup>
                                )}
                                {/* Add section for condition group and its conditions */}
                                <hr />
                                <h3>Conditions</h3>
                                <FormGroup>
                                    <Label for="logicalOperator">Logical Operator</Label>
                                    <Input
                                        type="select"
                                        id="logicalOperator"
                                        value={logicalOperator}
                                        onChange={(e) => setLogicalOperator(e.target.value)}
                                    >
                                        <option value="AND">AND</option>
                                        <option value="OR">OR</option>
                                    </Input>
                                </FormGroup>
                                {conditions.map((condition, index) => (
                                    <Row key={index}>
                                        <Col>
                                            <FormGroup>
                                                <Label for="fieldName">Version</Label>
                                                <Input
                                                    type="select"
                                                    id="conditionType"
                                                    value={condition.version}
                                                    onChange={(e) => handleConditionChange(index, 'version', e.target.value)}
                                                >
                                                    <option value="CURRENT">CURRENT</option>
                                                    {triggerType === 'ON_UPDATE' && <option value="PREVIOUS">PREVIOUS</option>}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for="fieldName">Field Name</Label>
                                                <Select className="bos-custom-select"
                                                    classNamePrefix="bos-select" isLoading={false}
                                                    options={
                                                        availableModel ? availableModel.availableModelFields.map((field) => ({
                                                            label: field ? field.replaceAll('_', ' ').toUpperCase() : '',
                                                            value: field,
                                                        })) : []
                                                    }
                                                    placeholder=""
                                                    onChange={(option) => handleConditionChange(index, 'fieldName', option.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for="conditionType">Condition Type</Label>
                                                <Select className="bos-custom-select"
                                                    classNamePrefix="bos-select" isLoading={false}
                                                    options={
                                                        conditionTypes && conditionTypes.states.map((item) => ({
                                                            label: item.name ? item.name.replaceAll('_', ' ').toUpperCase() : '',
                                                            value: item.name,
                                                        }))
                                                    }
                                                    placeholder=""
                                                    onChange={(option) => handleConditionChange(index, 'conditionType', option.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for="conditionValue">Condition Value</Label>
                                                <Input
                                                    type="text"
                                                    id="conditionValue"
                                                    value={condition.conditionValue}
                                                    onChange={(e) => handleConditionChange(index, 'conditionValue', e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                ))}
                                {/* Button should be on the right */}
                                <FormGroup className="text-right">

                                    <Button
                                        color="secondary"
                                        disabled={
                                            !availableModel || !name || !triggerType || !logicalOperator
                                            || conditions &&
                                            (conditions.filter((condition) => condition.fieldName || condition.conditionType).length > 0
                                                && conditions.some((condition) => !condition.fieldName || !condition.conditionType)
                                            )
                                        }
                                        onClick={() => setConditions([...conditions, DEFAULT_CONDITION])}
                                    >
                                        Add Condition
                                    </Button>
                                </FormGroup>
                                <hr />
                                <h3>Action</h3>
                                <small>To use instance properties in the action fields, enter {"{{instance.field_name}}"}. e.g To say hello to a driver with their name, use this:
                                    <strong><br />Hello {"{{instance.name}}"}<br /></strong>
                                </small>
                                {/* {
                                    {
                                        "data": {
                                            "actionTypesAndParams": [
                                                {
                                                    "actionType": "send_email",
                                                    "actionParams": "[{\"name\": \"recipient\", \"type\": \"str\", \"input\": \"input\", \"description\": \"Recipient email address\", \"choices\": null, \"default\": null, \"is_required\": true}, {\"name\": \"sender\", \"type\": \"str\", \"input\": \"input\", \"description\": \"Sender email address\", \"choices\": null, \"default\": null, \"is_required\": true}, {\"name\": \"subject\", \"type\": \"str\", \"input\": \"input\", \"description\": \"Email subject\", \"choices\": null, \"default\": null, \"is_required\": true}, {\"name\": \"body\", \"type\": \"str\", \"input\": \"textarea\", \"description\": \"Email body\", \"choices\": null, \"default\": null, \"is_required\": true}]"
                                                },
                                                {
                                                    "actionType": "send_webhook",
                                                    "actionParams": "[{\"name\": \"url\", \"type\": \"str\", \"input\": \"input\", \"description\": \"Webhook URL\", \"choices\": null, \"default\": null, \"is_required\": true}, {\"name\": \"method\", \"type\": \"str\", \"input\": \"select\", \"description\": \"HTTP method\", \"choices\": [\"GET\", \"POST\"], \"default\": null, \"is_required\": true}, {\"name\": \"body\", \"type\": \"str\", \"input\": \"textarea\", \"description\": \"Request body\", \"choices\": null, \"default\": null, \"is_required\": true}]"
                                                },
                                                {
                                                    "actionType": "send_sms",
                                                    "actionParams": "[{\"name\": \"recipient\", \"type\": \"str\", \"input\": \"input\", \"description\": \"Recipient phone number\", \"choices\": null, \"default\": null, \"is_required\": true}, {\"name\": \"sender\", \"type\": \"str\", \"input\": \"input\", \"description\": \"Sender phone number\", \"choices\": null, \"default\": null, \"is_required\": true}, {\"name\": \"message\", \"type\": \"str\", \"input\": \"textarea\", \"description\": \"SMS message\", \"choices\": null, \"default\": null, \"is_required\": true}]"
                                                },
                                                {
                                                    "actionType": "send_push_notification",
                                                    "actionParams": "[{\"name\": \"to\", \"type\": \"str\", \"input\": \"input\", \"description\": \"Recipient device token\", \"choices\": null, \"default\": null, \"is_required\": true}, {\"name\": \"message\", \"type\": \"str\", \"input\": \"textarea\", \"description\": \"Push notification message\", \"choices\": null, \"default\": null, \"is_required\": true}]"
                                                },
                                                {
                                                    "actionType": "send_slack_message",
                                                    "actionParams": "[{\"name\": \"channel\", \"type\": \"str\", \"input\": \"input\", \"description\": \"Slack channel\", \"choices\": null, \"default\": null, \"is_required\": true}, {\"name\": \"message\", \"type\": \"str\", \"input\": \"textarea\", \"description\": \"Slack message\", \"choices\": null, \"default\": null, \"is_required\": true}]"
                                                },
                                                {
                                                    "actionType": "create_task",
                                                    "actionParams": "[{\"name\": \"title\", \"type\": \"str\", \"input\": \"input\", \"description\": \"Task title\", \"choices\": null, \"default\": null, \"is_required\": true}, {\"name\": \"description\", \"type\": \"str\", \"input\": \"textarea\", \"description\": \"Task description\", \"choices\": null, \"default\": null, \"is_required\": true}, {\"name\": \"due_date\", \"type\": \"str\", \"input\": \"input\", \"description\": \"Task due date, format: YYYY-MM-DD\", \"choices\": null, \"default\": null, \"is_required\": true}]"
                                                }
                                            ]
                                        }
                                    }
                                } */}
                                <FormGroup>
                                    <Label for="actionType">Action Type</Label>
                                    <Select className="bos-custom-select"
                                        classNamePrefix="bos-select" isLoading={false}
                                        options={
                                            actionTypesAndParams && actionTypesAndParams.map((item) => ({
                                                label: item.actionType ? item.actionType.replaceAll('_', ' ').toUpperCase() : '',
                                                value: item,
                                            }))
                                        }
                                        placeholder="-- Select action --"
                                        onChange={(option) => {
                                            setActionType(option.value.actionType)
                                            setActionParams(JSON.parse(option.value.actionParams))
                                            setActionParamsValues({})
                                        }}
                                    />
                                </FormGroup>
                                {actionType && actionParams && actionParams.length > 0 && (
                                    <div>
                                        {actionParams.map((param) => (
                                            <FormGroup key={param.name}>
                                                <Label for={param.name}>{param.name.toUpperCase()}{param.is_required && " *"}</Label>
                                                {param.description && <FormText color="muted">{param.description}</FormText>}
                                                {param.input === 'input' ? (
                                                    <Input
                                                        type="text"
                                                        id={param.name + "actionParam"}
                                                        value={actionParamsValues[param.name]}
                                                        defaultValue={param.default || ''}
                                                        required={param.is_required}
                                                        onChange={(e) => handleActionParamChange(param.name, e.target.value)}
                                                        onFocus={(e) => setOpenModal(`Popover${param.name}`)}
                                                        onKeyUp={(e) => { setCurrentCursorPosition(e.target.selectionStart) }}
                                                        innerRef={(el) => inputRefs.current[param.name] = el}
                                                    />
                                                ) : param.input === 'textarea' ? (
                                                    <Input
                                                        type="textarea"
                                                        id={param.name + "actionParam"}
                                                        value={actionParamsValues[param.name]}
                                                        defaultValue={param.default || ''}
                                                        required={param.is_required}
                                                        onChange={(e) => handleActionParamChange(param.name, e.target.value)}
                                                        onFocus={(e) => setOpenModal(`Popover${param.name}`)}
                                                        onKeyUp={(e) => { setCurrentCursorPosition(e.target.selectionStart) }}
                                                        innerRef={(el) => inputRefs.current[param.name] = el}
                                                    />
                                                ) : param.input === 'select' ? (
                                                    <Input
                                                        type="select"
                                                        id={param.name + "actionParam"}
                                                        value={actionParamsValues[param.name]}
                                                        required={param.is_required}
                                                        onChange={(e) => handleActionParamChange(param.name, e.target.value)}
                                                    >
                                                        {param.choices.map((choice) => (
                                                            <option key={choice} value={choice}>{choice}</option>
                                                        ))}
                                                    </Input>
                                                ) : null}

                                                <Popover placement="bottom" target={param.name + "actionParam"} isOpen={openModal === `Popover${param.name}`} toggle={() => setOpenModal('')}>
                                                    <PopoverHeader>Populate from {availableModel && `${availableModel.contentType.model}`} fields</PopoverHeader>
                                                    <PopoverBody>
                                                        {availableModel && availableModel.availableModelFields.map((field) => (
                                                            <Badge
                                                                className="text-dark m-2 p-2" key={field}
                                                                style={{ cursor: "pointer", fontSize: 10 }} color="warning"
                                                                onClick={() => handleInstanceFieldClick(param.name, field)}>
                                                                {field.replaceAll('_', ' ').toUpperCase()}
                                                            </Badge>
                                                        ))}
                                                    </PopoverBody>
                                                </Popover>
                                            </FormGroup>
                                        ))}
                                    </div>
                                )}
                                <hr />
                                {error && <div className="alert alert-danger">{error}</div>}
                                {/* Button to create and button to discard and link to automations page */}
                                <FormGroup className="text-right">
                                    <Button
                                        type="submit"
                                        color="primary"
                                        disabled={!availableModel || !name || !triggerType || !logicalOperator || !actionType}
                                    >Create Automation</Button>
                                </FormGroup>
                            </Form>

                        </Col>
                    </Row>
                </Col>

                <Col xs="6">
                    <h2>Preview</h2>
                    <p>Showing fields of recent samples from available model selected</p>

                    <p>Recent objects from {availableModel && availableModel.contentType && availableModel.contentType.model ? availableModel && availableModel.contentType && availableModel.contentType.model.toUpperCase() : ""} model:</p>
                    <pre>
                        {availableModel && availableModel.recentObjects && parseRecentObjects(availableModel.recentObjects).length > 0 ?
                            Object.entries(parseRecentObjects(availableModel.recentObjects)[0]).map(([key, value]) => (
                                <pre key={key}>
                                    <span>{key}: {JSON.stringify(value)}</span>
                                </pre>
                            ))
                            : 'No recent objects'
                        }
                    </pre>


                </Col>
            </Row>
        </Container>
    )
}

// AllAvailableModels, AllAvailableModelFields
export default compose(
    withApollo,
    graphql(AllAvailableModelsQuery, {
        options: () => ({
            variables: {},
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-and-network'
        }),
        props({ data: { loading, allAvailableModels } }) {
            return {
                loading,
                allAvailableModels,
            }
        },
    }),
    graphql(ActionTypesAndParamsQuery, {
        options: () => ({ variables: {}, notifyOnNetworkStatusChange: true, fetchPolicy: 'cache-and-network' }),
        props({ data: { loading, actionTypesAndParams } }) {
            return { loading, actionTypesAndParams }
        },
    }),
    graphql(ConditionTypes, {
        options: () => ({ variables: {}, notifyOnNetworkStatusChange: true, fetchPolicy: 'cache-and-network' }),
        props({ data: { loading, conditionTypes } }) {
            return { loading, conditionTypes }
        },
    }),
    graphql(CREATE_AUTOMATION_WORKFLOW, { name: 'createAutomationWorkflow' }),
    graphql(TriggerTypes, {
        options: () => ({ variables: {}, notifyOnNetworkStatusChange: true, fetchPolicy: 'cache-and-network' }),
        props({ data: { loading, triggerTypes } }) {
            return { loading, triggerTypes }
        },
    }),

)(AutomationForm)
