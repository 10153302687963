import gql from 'graphql-tag'

const EnumQuery = gql`
query EnumQuery{
    agreementStates:__type(name: "AgreementStage") {
        states: enumValues {
            name
            description
        }
     }
     carReturnStates:__type(name: "CarReturnStage") {
        states: enumValues {
            name
            description
        }
     }
     reservationPickupStates:__type(name: "ReservationPickupType") {
        states: enumValues {
            name
            description
        }
     }
     chargeTypes:__type(name: "TransactionChargeType") {
        states: enumValues {
            name
            description
        }
     }
     paymentPlanReasons:__type(name: "PaymentPlanReason") {
      states: enumValues {
          name
          description
      }
     }

     accountTypes:__type(name: "TransactionAccount") {
        states: enumValues {
            name
            description
        }
     }

    returnTypes:  __type(name:"CarReturnReturnType"){
      states: enumValues{
        name
        description
      }
    }
    returnCategories: __type(name:"CarReturnReturnCategory"){
      states: enumValues{
        name
        description
      }
    }
}
`

const UserQuery = gql`
query UserQuery{
    currentUser{
      id
      unreadNotificationsCount
      username
      email
      isSuperuser
      isManager
      isBilling
      isFrontOffice
      isTopManagement
      authLevel
      isBroker
      groupNames
      member {
        id
        globalView
        preferredTimezone
        preferredTimeFormat
        preferredDateFormat
        preferredMenuItems
        showPinnedMenuItems
        transactionTablePreference
        tenant { id name }
        defaultBranch{ id name }
        branches {
          edges { node { id name } }
        }
        selectedBranches{
          edges { node { id name } }
        }
      }
    }
}
`
const GetConfigurationSettings = gql`
query GetConfigurationSettings($name:String!, $branchId:ID, $driverId:ID){
  getConfigurationSettings(name:$name, branchId:$branchId, driverId:$driverId)
}
`
const GlobalSettingsQuery = gql`
query GlobalSettingsQuery{
    globalSettings {
        edges {
          node {
            settingsName
            settingsValue
          }
        }
    }
}
`
const RepsQuery = gql`
    query AllRepsQuery($groups:[String], $permissions:[String], $orderBy:[String]){
      allReps(isStaff:true, isActive:true, groups: $groups, permissions: $permissions, orderBy:$orderBy){
        edges{
          node{
            id
            name
            username
            email
            isBilling
            isFrontOffice
            assignedCount
          }
        }
      }
    }
`

const AuditLogsQuery = gql`
query AuditLogsQuery{
    allAuditLogs(auditStage:"0"){
      edges{
        node{
          id
        }
      }
    }
  }
`
const CarStages = gql`query CarStages{
  carStages: __type(name: "CarStage") {
      states: enumValues {
          name
          description
      }
  }
}`
const CarDealTypes = gql`query CarDealTypes{
  carDealTypes: __type(name: "CarDealDealType") {
    states: enumValues {
        name
        description
    }
}
}`
const CarDealEntityTypes = gql`query CarDealEntityTypes{
  carDealEntityTypes: __type(name: "CarDealEntityEntityType") {
    states: enumValues {
        name
        description
    }
  }
}`
const CarIssueStatuses = gql`query CarIssueStatuses{
  carIssueStatuses:__type(name:"CarIssuesStatus"){
    states: enumValues{
      name
      description
    }
  }
}`
const JobStatuses = gql`query JobStatuses{
  jobStatuses:__type(name:"JobStatus"){
    states: enumValues{
      name
      description
    }
  }
}`
const CarIssueCategories = gql`query CarIssueCategories{
  carIssueCategories: __type(name: "CarIssueCategoryType") {
      states: enumValues {
          name
          description
      }
  }
}`
const TagsQuery = gql`query TagsQuery{
  tags: allTags{
    edges {
      node {
        id
        name
        color
      }
    }
  }
  driverTags: allTags(contentType: 13){
    edges {
      node {
        id
        name
        color
      }
    }
  }
  carTags: allTags(contentType: 11){
    edges {
      node {
        id
        name
        color
      }
    }
  }
  agreementStages: __type(name: "AgreementStage") {
        states: enumValues {
            name
            description
        }
    }
  }`

const assignDriverToRepMutation = gql`
    mutation CreateNewAssignment($repID:ID!, $drivers:ID!){
      createAssignment(input:{repId:$repID, drivers:[$drivers]}){
        assignment{
            id
        }
      }
  }`

const HasPermissionsQuery = gql`query hasPermissions($userPermissions: [String]!){
    hasPermissions(userPermissions:$userPermissions)
}`

export { EnumQuery, UserQuery, GlobalSettingsQuery, GetConfigurationSettings, AuditLogsQuery, RepsQuery, TagsQuery, assignDriverToRepMutation, CarStages, CarDealTypes, CarDealEntityTypes, CarIssueStatuses, JobStatuses, HasPermissionsQuery, CarIssueCategories }
