import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { Row, Col, Label, Alert, Button, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter, Progress } from 'reactstrap'
import moment from 'moment'
import { EnumQuery } from '../../Functions/querys'
import { TransactionsQuery } from "./Queries"
import Select from "react-select"
import "./PaymentPlan.css"

const CreatePaymentPlan = gql`mutation createPaymentPlan($input: CreatePaymentPlanMutationInput!){
    createPaymentPlan(input:$input){
        ok
        errors{
        messages
        }
    }
} `

class NewPaymentPlan extends Component {
    constructor(props) {
        super(props)
        this.state = {
            input: { "intervalCount": 1, "paydownStartDate": moment().tz("America/New_York").format(moment.HTML5_FMT.DATE), "paydownNotes": "", "reason": "", transactions: this.props.selectedTransactions },
            error: null,
            loading: false
        }
    }
    updateInput = (e) => {
        if (this.props.driverId) {
            let name = e.target.name
            let value = e.target.value
            if (e.target.type === "number") {
                if (isNaN(parseFloat(value))) {
                    value = 0
                } else {
                    value = parseFloat(value)
                }
            }
            let input = this.state.input
            input["driverId"] = this.props.driverId
            input[name] = value
            this.setState({ input: input })
        }
    }
    createPaymentPlan = (e) => {
        this.setState({ loading: true })
        let input = this.state.input
        this.props.client.mutate({
            mutation: CreatePaymentPlan,
            variables: { input },
        }).then((result) => {
            if (result && result.data && result.data.createPaymentPlan && result.data.createPaymentPlan.ok) {
                this.props.refetchQuery()
                this.props.handleClose()
            } else {
                let error = "An error occurred, could not complete request."
                if (result && result.data && result.data.createPaymentPlan && result.data.createPaymentPlan.errors[0] && result.data.createPaymentPlan.errors[0].messages) {
                    error = result.data.createPaymentPlan.errors[0].messages.toString()
                }
                this.setState({ error: error })
            }
            this.setState({ loading: false })
        }).catch((err) => {
            let error = "An error has occured"
            this.setState({ error: error, loading: false })
        })
    }
    validateTransactions = () => {
        let error = null
        if (this.props.transactions.edges.some(transaction => transaction.node.chargeType.paymentPlanCompatible === false)) {
            error = "Unable to create payment plan with transaction that is not payment plan compatible"
        } else if (this.props.transactions.edges.some(transaction => transaction.node.amount < 0)) {
            error = "Cannot create a paydown with a negative transaction"
        }
        this.setState({ error: error })
    }


    componentDidUpdate(prevProps) {
        if (prevProps.transactions !== this.props.transactions || (this.props.open && !prevProps.open)) {
            if (this.props.transactions && this.props.transactions.edges && this.props.transactions.edges.length > 0) {
                this.validateTransactions()
            }
        }
    }

    componentDidMount() {
        if (this.props.transactions && this.props.transactions.edges && this.props.transactions.edges.length > 0) {
            this.validateTransactions()
        }
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.open} className="payment-plan">
                    <ModalHeader toggle={this.toggle}>Create Payment Plan</ModalHeader>
                    <ModalBody>
                        {this.state.error && <Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col>}
                        {this.props.loading ? <Col xs={12}><Progress style={{ marginTop: "10px", maxHeight: "5px" }} animated color="info" value="100" /></Col> :
                            <Row>
                                <Col xs={6}>
                                    <FormGroup>
                                        <Label for="paydownOriginalBalance">Total amount driver is paying off</Label>
                                        <Input type={"number"} min={0} name="paydownOriginalBalance" id="paydownOriginalBalance" disabled value={this.props.transactions && this.props.transactions.edges && this.props.transactions.edges.length > 0 && this.props.transactions.edges.reduce((acc, transaction) => acc + parseFloat(transaction.node.amount), 0)} />
                                    </FormGroup>
                                </Col>
                                <Col xs={6}>
                                    <FormGroup>
                                        <Label for="paydownStartDate">When will the paydown start?</Label>
                                        <Input type={"date"} name="paydownStartDate" id="paydownStartDate" onChange={this.updateInput} defaultValue={this.state.input.paydownStartDate} placeholder="Start date" />
                                    </FormGroup>
                                </Col>
                                <Col xs={6}>
                                    <FormGroup>
                                        <Label for="paydownAmount">Weekly paydown amount</Label>
                                        <Input type={"number"} name="paydownAmount" id="paydownAmount" disabled value={this.props.transactions && this.props.transactions.edges && this.props.transactions.edges.length > 0 && (this.props.transactions.edges.reduce((acc, transaction) => acc + parseFloat(transaction.node.amount), 0) / this.state.input.intervalCount)} />
                                    </FormGroup>
                                </Col>
                                <Col xs={6}>
                                    <FormGroup>
                                        <Label for="intervalCount">Intervals to split the amount</Label>
                                        <Input type={"number"} min={0} name="intervalCount" id="intervalCount" onChange={this.updateInput} defaultValue={this.state.input.intervalCount} placeholder="Enter intervals count" />
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label for="reason">Select paydown reason</Label>
                                        <Select
                                            className="bos-custom-select mt-2 mb-2"
                                            classNamePrefix="bos-select"
                                            name="reason"
                                            options={this.props.paymentPlanReasons && this.props.paymentPlanReasons.states.map(reason => ({ value: reason.description, label: reason.description }))}
                                            placeholder="Select Reason"
                                            onChange={(option) => this.setState({ input: { ...this.state.input, reason: option.value } })}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col xs={12}>
                                    <FormGroup>
                                        <Label for="paydownNotes">Paydown notes</Label>
                                        <Input type={"text"} name="paydownNotes" id="paydownNotes" onChange={this.updateInput} defaultValue={this.state.input.paydownNotes} placeholder="Notes" />
                                    </FormGroup>
                                </Col>
                            </Row>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button className="payment-plan-modal-primary-btn" onClick={this.createPaymentPlan} disabled={this.state.error || this.state.loading || this.state.input.reason === "" || this.state.input.intervalCount <= 1}>{this.state.loading ? "Loading..." : "Create Payment Plan"}</Button>&nbsp;&nbsp;
                        <Button className="payment-plan-modal-secondary-btn" onClick={this.props.handleClose}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(EnumQuery, { props({ data: { paymentPlanReasons } }) { return { paymentPlanReasons } } }),
    graphql(TransactionsQuery, {
        options: ({ selectedTransactions }) => ({
            variables: {
                ids: selectedTransactions,
                orderBy: ["-date_added"],
                first: 30
            }
        }),
        // This function re-runs every time `data` changes, including after `updateQuery`,
        // meaning our loadMoreEntries function will always have the right cursor
        props({ data: { loading, transactions, fetchMore, variables } }) {
            return {
                loading,
                transactions,
                variables,
                refetchTransactionsQuery: () => {
                    //No apollo function that refetches in place with pagination considered so this function instead
                    let currentLength = transactions && transactions.edges && transactions.edges.length > 30 ? transactions.edges.length : 30
                    return fetchMore({
                        query: TransactionsQuery,
                        variables: {
                            ...variables,
                            first: currentLength,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.transactions.edges
                            const pageInfo = fetchMoreResult.transactions.pageInfo
                            return {
                                transactions: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    __typename: previousResult.transactions.__typename
                                },
                            }
                        },
                    })
                },
                loadMoreEntries: () => {
                    return fetchMore({
                        query: TransactionsQuery,
                        variables: {
                            cursor: transactions.pageInfo.endCursor,
                            ...variables
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.transactions.edges
                            const pageInfo = fetchMoreResult.transactions.pageInfo

                            return {
                                // Put the new comments at the end of the list and update `pageInfo`
                                // so we have the new `endCursor` and `hasNextPage` values
                                transactions: {
                                    edges: [...previousResult.transactions.edges, ...newEdges],
                                    pageInfo,
                                    __typename: previousResult.transactions.__typename
                                },
                            }
                        },
                    })
                },
            }
        },
    }),
)(NewPaymentPlan)
