import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import { Row, Col, Label, Alert, Button, FormGroup, FormFeedback, Input, Modal, ModalHeader, ModalBody, ModalFooter, Progress } from 'reactstrap'
import Select from 'react-select'
import { CreateBranchMutation } from "../Mutations"
import { AllBranches, AllCarDocumentTypesQuery, AllDriverDocumentTypesQuery, AllTenants } from "../Queries"
import States from "../../Material/States.json"
import Cities from "../../Material/Cities.json"
import toTitleCase from "../../Functions/toTitleCase"
import "./BranchListContainer.css"
import gql from 'graphql-tag'
import moment from "moment"
import SearchPlaces from "../../Material/SearchPlaces"

const BranchDetailQuery = gql`query Branch($id: ID!){
    branch(id: $id){
        id
        name
        allowCcSurcharge
        requiresInsurance
        autoChargeCustomers
        autoDisableCustomers
        forTlc
        legalEntity
        lineOfBusiness{
            id
            name
        }
        tenant {
            id
            name
        }
        driverDocTypes {
            edges {
                node {
                   id
                   typeName
                }
            }
        }
        driverDocsRequiredForRental {
            edges {
              node {
                id
              }
            }
          }
          driverDocsRequiredForApplication {
            edges {
              node {
                id
              }
            }
          }
          driverDocsRequiredForInsurance {
            edges {
              node {
                id
              }
            }
          }
          carDocsRequiredForRental {
            edges {
              node {
                id
              }
            }
          }
          carDocsRequiredForInspection {
            edges {
              node {
                id
              }
            }
          } 
          
    }
}`
const BranchSettingsQuery = gql`query allBranchSettings($branchId: ID!){
    allBranchSettings(branchId: $branchId){
        edges{
            node{
                id
                value
                key {
                    id
                    name
                    isRequired
                }
            }
        }
    }
}`

const BranchSettingKeyQuery = gql`query AllBranchSettingKeys{
    allBranchSettingKeys {
      edges {
        node {
          id
          name
          description
          type
          isRequired
        }
      }
    }
}`

const BranchLegalEntity = gql`
    query BranchLegalEntity{
      BranchLegalEntity:__type(name: "BranchLegalEntity") {
            values: enumValues {
                name
                description
            }
        }
    }`

const AllLineOfBusinessQuery = gql`
    query AllLineOfBusiness{
        allLineOfBusiness {
            edges {
                node {
                    id
                    name
                }
            }   
        }
    }
`

const TIMEZONES = [
    { value: "America/New_York", label: "America/New_York" },
    { value: "America/Phoenix", label: "America/Phoenix" },
    { value: "America/Houston", label: "America/Houston" },
    { value: "America/Chicago", label: "America/Chicago" },
    { value: "America/Salt Lake City", label: "America/Salt Lake City" },
    { value: "America/Anchorage", label: "America/Anchorage" },
    { value: "America/Adak", label: "America/Adak" },
    { value: "America/Los_Angeles", label: "America/Los_Angeles" },
    { value: "Mexico/Cancún", label: "Mexico/Cancún" },
    { value: "Mexico/Mexico City", label: "Mexico/Mexico City" },
    { value: "Mexico/Hermosillo", label: "Mexico/Hermosillo" }

]

class NewBranchModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: null,
            customId: null,
            description: null,
            addressLine1: null,
            addressLine2: null,
            city: null,
            defaultDeductible: null,
            stateCode: null,
            postalCode: null,
            country: "US",
            errorMessage: "",
            loading: false,
            forTlc: false,
            requiresInsurance: false,
            allowCcSurcharge: false,
            autoChargeCustomers: true,
            autoDisableCustomers: false,
            driverRentalDoc: null,
            driverInsuranceDoc: null,
            driverApplicationDoc: null,
            driverDocTypes: null,
            carRentalDoc: null,
            tenant: null,
            selectedBranch: null,
            addMoreSettings: false,
            lineOfBusinessId: null,
            legalEntity: null,
            settings: {},
            errors: {
                driverRentalDoc: "",
                driverInsuranceDoc: "",
                driverApplicationDoc: "",
                driverDocTypes: "",
                carRentalDoc: "",
                tenant: "",
                lineOfBusinessId: "",
                legalEntity: "",
                settings: {
                    SMS_FROM_DEFAULT: "",
                    EMAIL_FROM_DEFAULT: "",
                    STRIPE_API_KEY: "",
                    STRIPE_PUBLIC_KEY: "",
                    STRIPE_WEBHOOK_SECRET_KEY: "",
                    UNSCHEDULED_RETURN_FEE: "",
                    INSURANCE_AUTO_DENY_DAYS: "",
                    INSURANCE_EXPIRATION_DAYS_WITHOUT_RENTAL: "",
                    INSURANCE_EXPIRATION_DAYS_WITH_RENTAL: "",
                    PREFERRED_TIMEZONE: "",
                    INSURANCE_AUTO_DENY: "",
                    ENABLE_MOBILE_APP_ARGYLE_BANNER: "",
                    DEFAULT_BROKER_ID: "",
                    MINIMUM_DEPOSIT_REFUND_DAYS: "",
                    SALES_TAX_RATE: ""
                }
            }

        }
    }
    createBranch = (e) => {
        if (this.state.tenant && this.state.legalEntity && this.state.lineOfBusinessId && this.state.name && this.state.customId && this.state.addressLine1 && this.state.city && this.state.stateCode && this.state.country && this.state.postalCode && (!this.state.requiresInsurance || this.state.driverInsuranceDoc) && this.state.driverRentalDoc && this.state.driverApplicationDoc && this.state.carRentalDoc) {
            try {
                this.setState({ loading: true, errorMessage: "" })
                let input = {
                    name: this.state.name,
                    tenantId: this.state.tenant,
                    customId: this.state.customId,
                    description: this.state.description,
                    defaultDeductible: this.state.defaultDeductible ? this.state.defaultDeductible : 1000.00,
                    addressLine1: this.state.addressLine1,
                    addressLine2: this.state.addressLine2 || "",
                    city: this.state.city,
                    stateCode: this.state.stateCode,
                    postalCode: this.state.postalCode,
                    country: this.state.country,
                    forTlc: this.state.forTlc,
                    requiresInsurance: this.state.requiresInsurance,
                    allowCcSurcharge: this.state.allowCcSurcharge,
                    autoChargeCustomers: this.state.autoChargeCustomers,
                    autoDisableCustomers: this.state.autoDisableCustomers,
                    driverDocsRequiredForInsurance: this.state.driverInsuranceDoc,
                    driverDocsRequiredForRental: this.state.driverRentalDoc,
                    driverDocsRequiredForApplication: this.state.driverApplicationDoc,
                    driverDocTypes: this.state.driverDocTypes,
                    carDocsRequiredForRental: this.state.carRentalDoc,
                    carDocsRequiredForInspection: this.state.carInspectionDoc,
                    lineOfBusinessId: this.state.lineOfBusinessId,
                    legalEntity: this.state.legalEntity,
                    settings: Object.entries(this.state.settings).map(([keyId, value]) => ({ keyId, value }))
                }
                this.props.client.mutate({
                    mutation: CreateBranchMutation,
                    variables: { input }
                }).then((result) => {
                    if (result && result.data && result.data.createBranch && result.data.createBranch.ok) {
                        this.setState({ errorMessage: "" })
                        this.props.handleSuccess()
                        this.props.handleClose()
                    } else {
                        let errorMessage = "An error occurred, could not complete request."
                        if (result.data && result.data.createBranch && result.data.createBranch.errors && result.data.createBranch.errors[0] && result.data.createBranch.errors[0].messages) {
                            errorMessage = result.data.createBranch.errors[0].messages.toString()
                        }
                        this.setState({ errorMessage: errorMessage })
                    }
                    this.setState({ loading: false })
                }).catch((err) => {
                    let errorMessage = "An error has occured " + err
                    this.setState({ errorMessage: errorMessage, loading: false })
                })
            }
            catch (err) {
                let errorMessage = "An error has occured " + err
                this.setState({ errorMessage: errorMessage, loading: false })
            }
        } else
            this.setState({ errorMessage: "Error: Missing Required Fields" })
    }

    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (value === "null") {
            value = null
        }
        if (e.target.dataset.type) {
            if (value === "false") {
                value = false
            } else {
                value = true
            }
        }
        this.setState({ [name]: value })
    };

    updateSettingsInput = (e) => {
        const { name, value, type } = e.target
        const newValue = type === 'select-one' ? (value === 'true') : (value === 'null' ? null : value)

        this.setState(prevState => ({
            settings: {
                ...prevState.settings,
                [name]: newValue
            }
        }))
    };

    pObj = (jsonString) => {
        try {
            return JSON.parse(jsonString)
        } catch (error) {
            return {}
        }
    }

    getBranchSettings = (id) => {
        this.setState({ loading: true, selectedBranch: id })
        this.props.client.query({
            query: BranchSettingsQuery,
            variables: { branchId: id }
        }).then((result) => {
            this.setState({ loading: false })
            if (result.data && result.data.allBranchSettings && result.data.allBranchSettings.edges && result.data.allBranchSettings.edges.length > 0) {
                let branchSettingsEntries = []
                result.data.allBranchSettings.edges.map(item => {
                    branchSettingsEntries.push({ keyId: item.node.key.id, value: item.node.value })
                })
                let defaultSettings = this.props.allBranchSettingKeys.edges.filter(item => item.node.isRequired).map((key) => {
                    let matchedKey = branchSettingsEntries.find(({ keyId }) => keyId === key.node.id)
                    let value = matchedKey ? matchedKey.value : null
                    return {
                        [key.node.id]: value ? value : null,
                    }
                })
                let defaultNullSettings = defaultSettings.filter(item => Object.keys(item).filter(key => item[key] === null).length > 0).map(item => Object.keys(item)).flat()
                let requiredSettings = this.props.allBranchSettingKeys.edges.filter(item => item.node.isRequired).filter(item => defaultNullSettings.includes(item.node.id)).map(item => ({ [item.node.name]: "This Field is Required" })).flat()
                this.setState((prevState) => ({
                    settings: {
                        ...prevState.settings,
                        ...Object.assign({}, ...defaultSettings),
                    },
                    errors: {
                        ...prevState.errors,
                        settings: {
                            ...prevState.errors.settings,
                            ...Object.assign({}, requiredSettings.reduce((acc, item) => ({ ...acc, ...item }), {}))
                        }
                    }
                }))
            } else {
                this.props.allBranchSettingKeys.edges.forEach((key) => {
                    this.setState((prevState) => ({
                        errors: {
                            ...prevState.errors,
                            settings: {
                                ...prevState.errors.settings,
                                [key.node.name]: "This Field is Required"
                            }
                        }
                    }))
                })
            }
        })
    }
    getBranchAttributes = (id) => {
        this.setState({ loading: true, selectedBranch: id })
        this.props.client.query({
            query: BranchDetailQuery,
            variables: { id: id }
        }).then((result) => {
            this.setState({ loading: false })
            if (result.data && result.data.branch) {
                this.getBranchSettings(id)
                let docs = {
                    "driverRentalDoc": [],
                    "driverInsuranceDoc": [],
                    "driverApplicationDoc": [],
                    "carRentalDoc": [],
                    "carInspectionDoc": [],
                    "driverDocTypes": []
                }
                if (result.data.branch.driverDocTypes && result.data.branch.driverDocTypes.edges && result.data.branch.driverDocTypes.edges.length > 0) {
                    result.data.branch.driverDocTypes.edges.map(item => {
                        docs.driverDocTypes.push(item.node.id)
                    })
                }
                if (result.data.branch.driverDocsRequiredForRental && result.data.branch.driverDocsRequiredForRental.edges && result.data.branch.driverDocsRequiredForRental.edges.length > 0) {
                    result.data.branch.driverDocsRequiredForRental.edges.map(item => {
                        docs.driverRentalDoc.push(item.node.id)
                    })
                }
                if (result.data.branch.driverDocsRequiredForInsurance && result.data.branch.driverDocsRequiredForInsurance.edges && result.data.branch.driverDocsRequiredForInsurance.edges.length > 0) {
                    result.data.branch.driverDocsRequiredForInsurance.edges.map(item => {
                        docs.driverInsuranceDoc.push(item.node.id)
                    })
                }
                if (result.data.branch.driverDocsRequiredForApplication && result.data.branch.driverDocsRequiredForApplication.edges && result.data.branch.driverDocsRequiredForApplication.edges.length > 0) {
                    result.data.branch.driverDocsRequiredForApplication.edges.map(item => {
                        docs.driverApplicationDoc.push(item.node.id)
                    })
                }
                if (result.data.branch.carDocsRequiredForRental && result.data.branch.carDocsRequiredForRental.edges && result.data.branch.carDocsRequiredForRental.edges.length > 0) {
                    result.data.branch.carDocsRequiredForRental.edges.map(item => {
                        docs.carRentalDoc.push(item.node.id)
                    })
                }
                if (result.data.branch.carDocsRequiredForInspection && result.data.branch.carDocsRequiredForInspection.edges && result.data.branch.carDocsRequiredForInspection.edges.length > 0) {
                    result.data.branch.carDocsRequiredForInspection.edges.map(item => {
                        docs.carInspectionDoc.push(item.node.id)
                    })
                }
                let emptyDocs = {}
                Object.keys(docs).forEach(key => {
                    if (docs[key].length === 0) {
                        emptyDocs[key] = "This field is required"
                    }
                })
                this.setState({
                    forTlc: result.data.branch.forTlc,
                    autoChargeCustomers: result.data.branch.autoChargeCustomers,
                    autoDisableCustomers: result.data.branch.autoDisableCustomers,
                    allowCcSurcharge: result.data.branch.allowCcSurcharge,
                    requiresInsurance: result.data.branch.requiresInsurance,
                    driverRentalDoc: docs.driverRentalDoc,
                    driverInsuranceDoc: docs.driverInsuranceDoc,
                    driverApplicationDoc: docs.driverApplicationDoc,
                    carRentalDoc: docs.carRentalDoc,
                    carInspectionDoc: docs.carInspectionDoc,
                    driverDocTypes: docs.driverDocTypes,
                    legalEntity: result.data.branch.legalEntity !== null ? result.data.branch.legalEntity : "",
                    lineOfBusinessId: result.data.branch.lineOfBusiness ? result.data.branch.lineOfBusiness.id : "",
                    errors: {
                        ...this.state.errors,
                        ...emptyDocs,
                        legalEntity: result.data.branch.legalEntity ? "" : "This field is required",
                        lineOfBusinessId: result.data.branch.lineOfBusinessId ? "" : "This field is required",
                    }
                })
            }
        })
    }

    getStateVal = (value) => {
        for (let [key, state] of Object.entries(States)) {
            if (state.trim().toLowerCase() === value.trim().toLowerCase())
                return key
        }
        return undefined
    }
    render() {
        const requiredFields = this.props.allBranchSettingKeys && this.props.allBranchSettingKeys.edges && this.props.allBranchSettingKeys.edges.length > 0 ? this.props.allBranchSettingKeys.edges.filter(item => item.node.isRequired).map(item => item.node.id) : []
        const isDisabled = requiredFields.some((field) => !this.state.settings[field])
        return (
            <div>
                <Modal isOpen={this.props.open} className="bos-create-branch-modal">
                    <ModalHeader>ADD NEW BRANCH</ModalHeader>
                    <ModalBody>
                        <Row>
                            {this.state.errorMessage && <Col xs={12}><Alert color="danger">{this.state.errorMessage}</Alert></Col>}
                            <Col xs={4}>
                                <FormGroup>
                                    <Label for="state">Tenant *</Label>
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        isLoading={this.props.tenantsLoading}
                                        options={this.props.allTenants && this.props.allTenants.edges && this.props.allTenants.edges.length > 0 && this.props.allTenants.edges.map(tenant => ({ label: tenant.node.name, value: tenant.node.id }))}
                                        placeholder="Select Tenant"
                                        onChange={(tenant) => this.setState({ tenant: tenant.value })}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={4}>
                                <FormGroup>
                                    <Label for="name">Branch Name *</Label>
                                    <Input name="name" id="name" onChange={this.updateInput} placeholder="Branch Name" />
                                </FormGroup>
                            </Col>
                            <Col xs={4}>
                                <FormGroup>
                                    <Label for="customId">Custom ID *</Label>
                                    <Input name="customId" id="customId" onChange={this.updateInput} placeholder="Custom ID e.g MIAMI_FL" />
                                </FormGroup>
                            </Col>
                            <Col xs={8}>
                                <FormGroup>
                                    <Label for="addressLine1">Line Address 1</Label>
                                    <Input name="addressLine1" id="addressLine1" onChange={this.updateInput} placeholder="Line Address 1" />
                                </FormGroup>
                            </Col>
                            <Col xs={4}>
                                <FormGroup>
                                    <Label for="addressLine2">Line Address 2</Label>
                                    <Input name="addressLine2" id="addressLine2" onChange={this.updateInput} placeholder="Line Address 2" />
                                </FormGroup>
                            </Col>
                            <Col xs={3}>
                                <FormGroup>
                                    <Label for="country">Country Code *</Label>
                                    <Input name="country" id="country" disabled value={this.state.country} maxLength={2} minLength="2" onChange={this.updateInput} placeholder="Country code e.g US" />
                                </FormGroup>
                            </Col>
                            <Col xs={3}>
                                <FormGroup>
                                    <Label for="state">State *</Label>
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        options={Object.keys(Cities).map(state => ({ value: this.getStateVal(state), label: state }))}
                                        placeholder="Select State"
                                        onChange={(state) => this.setState({ stateCode: state.value })}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={3}>
                                <FormGroup>
                                    <Label for="city">City *</Label>
                                    <SearchPlaces onSaveCityCode={(city) => this.setState({ city: city.value })} initialCityName={null} searchType={"city"} />
                                </FormGroup>
                            </Col>
                            <Col xs={3}>
                                <FormGroup>
                                    <Label for="postalCode">Postal Code *</Label>
                                    <Input type="number" name="postalCode" maxLength="5" minLength="12" id="postalCode" onChange={this.updateInput} placeholder="Postal Code" />
                                </FormGroup>
                            </Col>
                            <Col xs={12}>
                                <FormGroup>
                                    <Label for="description">Description</Label>
                                    <Input name="description" id="description" onChange={this.updateInput} placeholder="Description" />
                                </FormGroup>
                            </Col>
                            <Col xs={4}>
                                <FormGroup>
                                    <Label for="defaultDeductible">Default Collision Deductible *</Label>
                                    <Input type="number" name="defaultDeductible" id="defaultDeductible" onChange={this.updateInput} defaultValue={1000.00} placeholder="Collision Deductible" />
                                </FormGroup>
                            </Col>
                            <Col xs={4}>
                                <FormGroup>
                                    <Label for="defaultDeductible">Branch Legal Entity *</Label>
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        options={this.props.BranchLegalEntity && this.props.BranchLegalEntity.values && this.props.BranchLegalEntity.values.length > 0 ? this.props.BranchLegalEntity.values.map(item => ({ value: item.description, label: item.description })) : []}
                                        value={this.props.BranchLegalEntity && this.props.BranchLegalEntity.values && this.props.BranchLegalEntity.values.length > 0 ? this.props.BranchLegalEntity.values.map(item => ({ value: item.description, label: item.description })).find(item => item.value == this.state.legalEntity) : null}
                                        placeholder="Choose Branch Legal Entity"
                                        onChange={(legalEntity) => this.setState({ legalEntity: legalEntity.value })}
                                    />
                                    {this.state.errors.legalEntity && !this.state.legalEntity && <span className="text-danger">{this.state.errors.legalEntity}</span>}
                                </FormGroup>
                            </Col>
                            <Col xs={4}>
                                <FormGroup>
                                    <Label for="defaultDeductible">Branch Line Of Bussiness *</Label>
                                    <Select
                                        className="bos-custom-select" classNamePrefix="bos-select"
                                        options={this.props.allLineOfBusiness && this.props.allLineOfBusiness.edges && this.props.allLineOfBusiness.edges.length > 0 ? this.props.allLineOfBusiness.edges.map(item => ({ value: item.node.id, label: item.node.name })) : []}
                                        value={this.state.lineOfBusinessId && this.props.allLineOfBusiness && this.props.allLineOfBusiness.edges && this.props.allLineOfBusiness.edges.length > 0 ? this.props.allLineOfBusiness.edges.map(item => ({ value: item.node.id, label: item.node.name })).find(item => item.value == this.state.lineOfBusinessId) : null}
                                        placeholder="Choose Line Of Bussiness"
                                        onChange={(lineOfBusiness) => this.setState({ lineOfBusinessId: lineOfBusiness.value })}
                                    />
                                    {this.state.errors.lineOfBusinessId && !this.state.lineOfBusinessId && <span className="text-danger">{this.state.errors.lineOfBusinessId}</span>}
                                </FormGroup>
                            </Col>
                            {this.state.selectedBranch === null && <Col xs={4}>
                                <FormGroup>
                                    <Label style={{ color: "green" }}><b>Select Branch To Clone Branch Settings</b></Label>
                                    <Select
                                        className="bos-custom-select"
                                        classNamePrefix="bos-select"
                                        isLoading={this.props.branchesLoading}
                                        options={this.props.allBranches && this.props.allBranches.edges && this.props.allBranches.edges.length > 0 && this.props.allBranches.edges.map(branch => ({ label: branch.node.name, value: branch.node.id }))}
                                        onChange={(branch) => this.getBranchAttributes(branch.value)}
                                        placeholder="Select Branch"
                                    />
                                </FormGroup>
                            </Col>}
                            <Col xs={2}>
                                <Label>For TLC *</Label>
                                <FormGroup>
                                    <span>Yes</span>
                                    <div className="toggle-switch">
                                        <input
                                            type="checkbox"
                                            className="toggle-switch-checkbox"
                                            name="forTlc"
                                            id="forTlc"
                                            checked={!this.state.forTlc}
                                            onChange={() => this.setState({ forTlc: !this.state.forTlc })}
                                        />
                                        <label className="toggle-switch-label" htmlFor="forTlc">
                                            <span className="toggle-switch-inner" />
                                            <span className="toggle-switch-switch" />
                                        </label>
                                    </div>
                                    <span>No</span>
                                </FormGroup>
                            </Col>
                            <Col xs={2}>
                                <Label>CC Surcharge *</Label>
                                <FormGroup>
                                    <span>Yes</span>
                                    <div className="toggle-switch">
                                        <input
                                            type="checkbox"
                                            className="toggle-switch-checkbox"
                                            name="allowCcSurcharge"
                                            id="allowCcSurcharge"
                                            checked={!this.state.allowCcSurcharge}
                                            onChange={(prevState) => this.setState({ allowCcSurcharge: !prevState.allowCcSurcharge })}
                                        />
                                        <label className="toggle-switch-label" htmlFor="allowCcSurcharge">
                                            <span className="toggle-switch-inner" />
                                            <span className="toggle-switch-switch" />
                                        </label>
                                    </div>
                                    <span>No</span>
                                </FormGroup>
                            </Col>
                            <Col xs={2}>
                                <Label>Auto Charge Customers *</Label>
                                <FormGroup>
                                    <span>Yes</span>
                                    <div className="toggle-switch">
                                        <input
                                            type="checkbox"
                                            className="toggle-switch-checkbox"
                                            name="autoChargeCustomers"
                                            id="autoChargeCustomers"
                                            checked={!this.state.autoChargeCustomers}
                                            onChange={(prevState) => this.setState({ autoChargeCustomers: !prevState.autoChargeCustomers })}
                                        />
                                        <label className="toggle-switch-label" htmlFor="autoChargeCustomers">
                                            <span className="toggle-switch-inner" />
                                            <span className="toggle-switch-switch" />
                                        </label>
                                    </div>
                                    <span>No</span>
                                </FormGroup>
                            </Col>
                            <Col xs={2}>
                                <Label>Insurance Required *</Label>
                                <FormGroup>
                                    <span>Yes</span>
                                    <div className="toggle-switch">
                                        <input
                                            type="checkbox"
                                            className="toggle-switch-checkbox"
                                            name="requiresInsurance"
                                            id="requiresInsurance"
                                            checked={!this.state.requiresInsurance}
                                            onChange={() => this.setState({ requiresInsurance: !this.state.requiresInsurance })}
                                        />
                                        <label className="toggle-switch-label" htmlFor="requiresInsurance">
                                            <span className="toggle-switch-inner" />
                                            <span className="toggle-switch-switch" />
                                        </label>
                                    </div>
                                    <span>No</span>
                                </FormGroup>
                            </Col>
                            <Col xs={4}>
                                <FormGroup>
                                    <Label>Driver Doc Types</Label>
                                    <Select
                                        className="bos-custom-select"
                                        classNamePrefix="bos-select"
                                        isLoading={this.props.driverDocumentTypeLoading}
                                        options={this.props.allDriverDocumentTypes && this.props.allDriverDocumentTypes.edges && this.props.allDriverDocumentTypes.edges.length > 0 && this.props.allDriverDocumentTypes.edges.map(docType => ({ label: docType.node.typeName, value: docType.node.id }))}
                                        value={this.props.allDriverDocumentTypes && this.props.allDriverDocumentTypes.edges && this.props.allDriverDocumentTypes.edges.length > 0 && this.props.allDriverDocumentTypes.edges.filter(docType => this.state.driverDocTypes && this.state.driverDocTypes.includes(docType.node.id)).map(docType => ({ value: docType.node.id, label: docType.node.typeName }))}
                                        onChange={(driverDocType) => this.setState({ driverDocTypes: driverDocType.map(item => item.value) })}
                                        placeholder="Driver Doc Types"
                                        isMulti
                                    />
                                    {this.state.errors.driverDocTypes && this.state.driverDocTypes.length === 0 && <span className="text-danger">{this.state.errors.driverDocTypes}</span>}
                                </FormGroup>
                            </Col>
                            <Col xs={4}>
                                <FormGroup>
                                    <Label>Driver Docs Required For Insurance</Label>
                                    <Select
                                        isDisabled={!this.state.requiresInsurance}
                                        className="bos-custom-select"
                                        classNamePrefix="bos-select"
                                        isLoading={this.props.driverDocumentTypeLoading}
                                        options={this.props.allDriverDocumentTypes && this.props.allDriverDocumentTypes.edges && this.props.allDriverDocumentTypes.edges.length > 0 && this.props.allDriverDocumentTypes.edges.filter(docType => this.state.driverDocTypes && this.state.driverDocTypes.includes(docType.node.id)).map(docType => ({ label: docType.node.typeName, value: docType.node.id }))}
                                        value={this.props.allDriverDocumentTypes && this.props.allDriverDocumentTypes.edges && this.props.allDriverDocumentTypes.edges.length > 0 && this.props.allDriverDocumentTypes.edges.filter(docType => this.state.driverInsuranceDoc && this.state.driverInsuranceDoc.includes(docType.node.id)).map(docType => ({ value: docType.node.id, label: docType.node.typeName }))}
                                        onChange={(driverDocType) => this.setState({ driverInsuranceDoc: driverDocType.map(item => item.value) })}
                                        placeholder="Driver Docs Required For Insurance"
                                        isMulti
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={4}>
                                <FormGroup>
                                    <Label>Driver Docs Required For Rental *</Label>
                                    <Select
                                        className="bos-custom-select"
                                        classNamePrefix="bos-select"
                                        isLoading={this.props.driverDocumentTypeLoading}
                                        options={this.props.allDriverDocumentTypes && this.props.allDriverDocumentTypes.edges && this.props.allDriverDocumentTypes.edges.length > 0 && this.props.allDriverDocumentTypes.edges.filter(docType => this.state.driverDocTypes && this.state.driverDocTypes.includes(docType.node.id)).map(docType => ({ label: docType.node.typeName, value: docType.node.id }))}
                                        value={this.props.allDriverDocumentTypes && this.props.allDriverDocumentTypes.edges && this.props.allDriverDocumentTypes.edges.length > 0 && this.props.allDriverDocumentTypes.edges.filter(docType => this.state.driverRentalDoc && this.state.driverRentalDoc.includes(docType.node.id)).map(docType => ({ value: docType.node.id, label: docType.node.typeName }))}
                                        onChange={(driverDocType) => this.setState({ driverRentalDoc: driverDocType.map(item => item.value) })}
                                        placeholder="Driver Docs Required For Rental"
                                        isMulti
                                        invalid={this.state.errors.driverRentalDoc}
                                    />
                                    {this.state.errors.driverRentalDoc && this.state.driverRentalDoc.length === 0 && <span className="text-danger">{this.state.errors.driverRentalDoc}</span>}
                                </FormGroup>
                            </Col>
                            <Col xs={4}>
                                <FormGroup>
                                    <Label>Driver Docs Required For Application *</Label>
                                    <Select
                                        className="bos-custom-select"
                                        classNamePrefix="bos-select"
                                        isLoading={this.props.driverDocumentTypeLoading}
                                        options={this.props.allDriverDocumentTypes && this.props.allDriverDocumentTypes.edges && this.props.allDriverDocumentTypes.edges.length > 0 && this.props.allDriverDocumentTypes.edges.filter(docType => this.state.driverDocTypes && this.state.driverDocTypes.includes(docType.node.id)).map(docType => ({ label: docType.node.typeName, value: docType.node.id }))}
                                        value={this.props.allDriverDocumentTypes && this.props.allDriverDocumentTypes.edges && this.props.allDriverDocumentTypes.edges.length > 0 && this.props.allDriverDocumentTypes.edges.filter(docType => this.state.driverApplicationDoc && this.state.driverApplicationDoc.includes(docType.node.id)).map(docType => ({ value: docType.node.id, label: docType.node.typeName }))}
                                        onChange={(driverDocType) => this.setState({ driverApplicationDoc: driverDocType.map(item => item.value) })}
                                        placeholder="Driver Docs Required For Application"
                                        isMulti
                                    />
                                    {this.state.errors.driverApplicationDoc && this.state.driverApplicationDoc.length === 0 && <span className="text-danger">{this.state.errors.driverApplicationDoc}</span>}
                                </FormGroup>
                            </Col>
                            <Col xs={4}>
                                <FormGroup>
                                    <Label>Car Docs Required For Rental *</Label>
                                    <Select
                                        className="bos-custom-select"
                                        classNamePrefix="bos-select"
                                        isLoading={this.props.carDocumentTypesLoading}
                                        options={this.props.allCarDocumentTypes && this.props.allCarDocumentTypes.edges && this.props.allCarDocumentTypes.edges.length > 0 && this.props.allCarDocumentTypes.edges.map(docType => ({ label: docType.node.typeName, value: docType.node.id }))}
                                        value={this.props.allCarDocumentTypes && this.props.allCarDocumentTypes.edges && this.props.allCarDocumentTypes.edges.length > 0 && this.props.allCarDocumentTypes.edges.filter(docType => this.state.carRentalDoc && this.state.carRentalDoc.includes(docType.node.id)).map(docType => ({ value: docType.node.id, label: docType.node.typeName }))}
                                        onChange={(carDocType) => this.setState({ carRentalDoc: carDocType.map(item => item.value) })}
                                        placeholder="Car Docs Required For Rental"
                                        isMulti
                                    />
                                    {this.state.errors.carRentalDoc && this.state.carRentalDoc.length === 0 && <span className="text-danger">{this.state.errors.carRentalDoc}</span>}
                                </FormGroup>
                            </Col>
                            <Col xs={4}>
                                <FormGroup>
                                    <Label>Car Docs Required For Inspection</Label>
                                    <Select
                                        className="bos-custom-select"
                                        classNamePrefix="bos-select"
                                        isLoading={this.props.carDocumentTypesLoading}
                                        options={this.props.allCarDocumentTypes && this.props.allCarDocumentTypes.edges && this.props.allCarDocumentTypes.edges.length > 0 && this.props.allCarDocumentTypes.edges.map(docType => ({ label: docType.node.typeName, value: docType.node.id }))}
                                        value={this.props.allCarDocumentTypes && this.props.allCarDocumentTypes.edges && this.props.allCarDocumentTypes.edges.length > 0 && this.props.allCarDocumentTypes.edges.filter(docType => this.state.carInspectionDoc && this.state.carInspectionDoc.includes(docType.node.id)).map(docType => ({ value: docType.node.id, label: docType.node.typeName }))}
                                        onChange={(carDocType) => this.setState({ carInspectionDoc: carDocType.map(item => item.value) })}
                                        placeholder="Car Docs Required For Inspection"
                                        isMulti
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "10px" }}>
                            <Col xs={12}>
                                <h4>BRANCH SETTINGS*</h4>
                            </Col>
                        </Row><br />
                        <Row>
                            {this.props.allBranchSettingKeys && this.props.allBranchSettingKeys.edges && this.props.allBranchSettingKeys.edges.length > 0 && this.props.allBranchSettingKeys.edges.filter(item => item.node.isRequired).map((key, index) =>
                                <Col xs={6} key={index}>
                                    <FormGroup>
                                        <Label>
                                            <b>{toTitleCase(key.node.name)}*</b>
                                        </Label>
                                        {key.node.type !== "BOOLEAN" ? <>
                                            <Input
                                                required
                                                name={key.node.id}
                                                id={key.node.id}
                                                type={key.node.type === "float" ? "number" : key.node.type}
                                                defaultValue={this.state.settings && this.state.settings[key.node.id]}
                                                onChange={this.updateSettingsInput}
                                                placeholder={key.node.description}
                                                invalid={!this.state.settings[key.node.id] && this.state.errors.settings[key.node.name]}
                                            />
                                        </> :
                                            <>
                                                <Input
                                                    type="select"
                                                    name={key.node.id}
                                                    id={key.node.id}
                                                    value={this.state.settings && this.state.settings[key.node.id]}
                                                    onChange={this.updateSettingsInput}
                                                    invalid={!this.state.settings[key.node.id] && this.state.errors.settings[key.node.name]}

                                                >
                                                    <option value="">Choose One</option>
                                                    <option value="true">True</option>
                                                    <option value="false">False</option>
                                                </Input>
                                            </>
                                        }
                                        <FormFeedback>{this.state.settings[key.node.id] === null && this.state.errors.settings[key.node.name]}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            )}
                            {!this.props.loading &&
                                <Col>
                                    <Label><b>Click Here To Add More Settings</b></Label><br />
                                    <Button onClick={(e) => this.setState({ addMoreSettings: true })}>Add More</Button>
                                </Col>
                            }
                            {this.state.addMoreSettings && this.props.allBranchSettingKeys && this.props.allBranchSettingKeys.edges && this.props.allBranchSettingKeys.edges.length > 0 && this.props.allBranchSettingKeys.edges.filter(item => !item.node.isRequired).map((key, index) =>
                                <Col xs={6} key={index}>
                                    <FormGroup>
                                        <Label>
                                            {toTitleCase(key.node.name) + " (Optional)"}
                                        </Label>
                                        {key.node.name === "PREFERRED_TIMEZONE" ?
                                            <Input type="select" name="value" id="value" onChange={this.handleChange}>
                                                <option value="true">--- Select Timezone ----</option>
                                                {TIMEZONES.map(zone => <option value={zone.value}>{moment.tz(zone.label).format('zz') + " | " + zone.label}</option>)}
                                            </Input>
                                            :
                                            key.node.type !== "BOOLEAN" ?
                                                <Input
                                                    required
                                                    name={key.node.id}
                                                    id={key.node.id}
                                                    type={key.node.type}
                                                    defaultValue={this.state.settings && this.state.settings[key.node.id]}
                                                    onChange={this.updateSettingsInput}
                                                    placeholder={key.node.description}
                                                />
                                                :
                                                <Input
                                                    type="select"
                                                    name={key.node.id}
                                                    id={key.node.id}
                                                    onChange={this.updateSettingsInput}
                                                    defaultValue={this.state.settings && this.state.settings[key.node.id]}
                                                >
                                                    <option value="true">True</option>
                                                    <option value="false">False</option>
                                                </Input>
                                        }
                                    </FormGroup>
                                </Col>
                            )}
                        </Row>
                        <Row>
                            <Col xs={12}>
                                {(this.state.loading || this.props.loading) && <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row>}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="new-branch-modal-secondary-btn" onClick={this.props.handleClose}>Cancel</Button>&nbsp;&nbsp;
                        <Button className="new-branch-modal-primary-btn" onClick={this.createBranch} disabled={this.state.loading || isDisabled}>Add Branch</Button>
                    </ModalFooter>
                </Modal >
            </div >
        )
    }
}

export default compose(
    withApollo,
    graphql(AllTenants, { props({ data: { allTenants, loading } }) { return { allTenants, tenantsLoading: loading } } }),
    graphql(AllBranches, { props({ data: { allBranches, loading } }) { return { allBranches, branchesLoading: loading } } }),
    graphql(AllCarDocumentTypesQuery, {
        options: { fetchPolicy: 'cache-first' },
        props({ data: { loading, allCarDocumentTypes } }) {
            return { carDocumentTypesLoading: loading, allCarDocumentTypes }
        },
    }),
    graphql(AllDriverDocumentTypesQuery, {
        options: { fetchPolicy: 'cache-first' },
        props({ data: { loading, allDriverDocumentTypes } }) {
            return { driverDocumentTypeLoading: loading, allDriverDocumentTypes }
        },
    }),

    graphql(BranchSettingKeyQuery, { props({ data: { allBranchSettingKeys, loading } }) { return { allBranchSettingKeys, loading } } }),
    graphql(BranchLegalEntity, { props({ data: { BranchLegalEntity } }) { return { BranchLegalEntity } } }),
    graphql(AllLineOfBusinessQuery, {
        options: { fetchPolicy: 'cache-first' },
        props({ data: { loading, allLineOfBusiness } }) {
            return { allLineOfBusinessLoading: loading, allLineOfBusiness }
        },
    }),
)(NewBranchModal)
