import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { withApollo } from 'react-apollo'
import Select from 'react-select'

//This component accept 4 type of searchType 
// "city", For searching cities
// "address", For searching addresses
// "establishment", For searching establishments (e.g., businesses)
// "all", For a more general search
const SEARCH_PLACES = gql`
  query SearchPlaces($searchTerm: String!, $searchType: String!) {
    searchPlaces(searchTerm: $searchTerm, searchType: $searchType) {
      name
    }
  }
`

const SearchPlaces = ({ client, onSaveCityCode, initialCityName, searchType }) => {
    const [placesOptions, setPlacesOptions] = useState([])
    const [selectedPlace, setSelectedPlace] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        if (initialCityName) {
            setSelectedPlace({ label: initialCityName, value: initialCityName })
        } else {
            setSelectedPlace(null)
        }
    }, [initialCityName])

    const fetchCities = async (searchTerm) => {
        setError(null)
        setLoading(true)
        try {
            const { data, errors } = await client.query({
                query: SEARCH_PLACES,
                variables: {
                    searchTerm: searchTerm,
                    searchType: searchType
                },
                fetchPolicy: 'network-only',
            })
            if (errors && errors.length > 0) {
                // Extract the first error message or aggregate them if necessary
                const errorMessage = errors.map(error => error.message).join(', ')
                setError(`GraphQL Error: ${errorMessage}`)
                setPlacesOptions([])  // Clear options if there's an error
                return  // Exit early since there's an error
            }
            const options = data && data.searchPlaces && data.searchPlaces.map((city) => ({
                label: city.name,
                value: city.name,
            }))
            setPlacesOptions(options)
        } catch (err) {
            setError(err.message)
        } finally {
            setLoading(false)
        }
    }

    const handleInputChange = (inputValue) => {
        if (inputValue.length > 2) {
            fetchCities(inputValue)
        } else {
            setPlacesOptions([])
        }
    }

    const handlePlaceChange = (selectedOption) => {
        setSelectedPlace(selectedOption)
        if (onSaveCityCode) {
            onSaveCityCode(selectedOption ? selectedOption : { value: null })
        }
    }

    return (
        <>
            {error && <p className="text-danger">{error}</p>}
            <Select
                className="bos-custom-select"
                classNamePrefix="bos-select"
                options={placesOptions}
                value={selectedPlace}
                onChange={handlePlaceChange}
                onInputChange={handleInputChange}
                placeholder="Type to search for a city"
                isClearable
                isLoading={loading}
                noOptionsMessage={() => "No cities found"}
            />
        </>
    )
}

export default withApollo(SearchPlaces)
