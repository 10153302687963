import React, { useState, useEffect } from "react"
import { compose, graphql, withApollo } from "react-apollo"
import { Row, Col, Input } from "reactstrap"
import renderEnumToInt from "../Functions/renderEnumToInt"
import toTitleCase from "../Functions/toTitleCase"
import debounce from "lodash/debounce"
import getFilterValuesFromQueryString from "../Functions/getFilterValuesFromQueryString"
import handleConfigurationChange from "../Functions/handleConfigurationChange"
import ListingFilters from "../Material/ListingFilters"
import updateFilters from "../Functions/updateFilters"
import { PaymentsStatusesQuery } from "./Queries"

const PaymentSchedulesListHeader = ({ filterValues, history, location, setFilterValues, defaultFilters, client }) => {

    const [openModal, setOpenModal] = useState("")
    const [configurations, setConfigurations] = useState([])

    const updateSearchTerm = debounce((searchTerm) => {
        updateFilters({ ...filterValues, searchTerm }, configurations, history, location, setFilterValues)
    }, 500)

    const removeFilter = (filterName) => {
        let filterValuesObj = filterValues
        let filterKeys = Object.keys(filterValuesObj)
        if (filterKeys.includes(filterName)) {
            delete filterValuesObj[filterName]
            updateFilters({ ...filterValuesObj }, configurations, history, location, setFilterValues)
        }
    }

    const toggleModal = (modal) => {
        setOpenModal(openModal === modal ? "" : modal)
    }

    const getValueByFilterType = (filterConfigs, value) => {
        if (filterConfigs.type == "select") {
            let options = filterConfigs["options"]
            if (options && options.length > 0) {
                if (Array.isArray(value)) {
                    value = value.map(item => options.find(option => option.value == item).label)
                } else {
                    if (value === true) {
                        value = 'true'
                    }
                    else if (value === false) {
                        value = 'false'
                    }
                    value = options.find(option => option.value == value) && options.find(option => option.value == value).label
                }
            }
        } else if (filterConfigs.type === "boolean") {
            value = toTitleCase(value.toString())
        } else if (filterConfigs.type === "date-range")
            value = value[0].startDate && value[0].endDate ? value[0].startDate : ""
        return Array.isArray(value) ? value.join(", ") : value
    }

    const getFilterConfigurations = () => {
        return [
            { type: "select", name: "statuses", title: "Payment Status ", optionsQuery: PaymentsStatusesQuery, valueSelector: "description", labelSelector: "description", placeholder: "Filter By Payment Status", showFilter: true, isMulti: true },
            // { type: "number", name: "pk", title: "Driver ID", placeholder: "1234", showFilter: true },
        ]
    }

    const setConfs = () => {
        let conf = getFilterConfigurations()
        let configurations = conf.filter(filter => filter['showFilter']).map(filter => {
            if (filter['optionsQuery']) {
                let options = []
                client.query({
                    query: filter.optionsQuery,
                    variables: { ...filter.variables }
                }).then(result => {
                    if (result && result.data.optionsList && result.data.optionsList.edges && result.data.optionsList.edges.length > 0) {
                        options = result.data.optionsList.edges.map(options => options.node && ({
                            value: options.node[filter.valueSelector], label: options.node[filter.labelSelector]
                        }))
                    } else if (result && result.data.optionsList && result.data.optionsList.states && result.data.optionsList.states.length > 0) {
                        options = result.data.optionsList.states.map(options => options.node ? ({ value: options.node[filter.valueSelector], label: options.node[filter.labelSelector] }) : ({ value: filter.extractValue ? renderEnumToInt(options[filter.valueSelector]) : options[filter.valueSelector], label: options[filter.labelSelector] }))
                    }
                    filter['options'] = options
                    delete filter['optionsQuery']
                })
            }
            return filter
        })
        setConfigurations(configurations)
    }
    useEffect(() => {
        setConfs()
    }, [])
    useEffect(() => {
        let urlFilterValues = getFilterValuesFromQueryString(location.search, configurations)
        handleConfigurationChange(configurations, configurations, urlFilterValues, setFilterValues, history, location, defaultFilters, updateFilters)
    }, [configurations])

    return (
        <Row >
            {openModal === "viewFilters" &&
                <ListingFilters open={openModal === "viewFilters"} handleClose={() => toggleModal("")}
                    target="viewFilters" filterValues={filterValues} setFilterValues={(filters => updateFilters(filters, configurations, history, location, setFilterValues))}
                    filters={configurations}
                />
            }
            <Col xs={4}>
                <span className="driver-search-filter">
                    <Input type="text" name="searchTerm" placeholder="Search" onChange={(e) => updateSearchTerm(e.target.value)} />
                    <i className="fa fa-lg fa-search search-icon" aria-hidden="true"></i>
                </span>
            </Col>
            <Col xs={8} className="text-right mt-2">
                <a id="viewFilters" className="driver-list-button" onClick={() => toggleModal("viewFilters")}>
                    Apply Filters | <i className="fa fa-filter" aria-hidden="true"></i> {filterValues && Object.keys(filterValues).filter(key => filterValues[key] !== null && configurations && configurations.find(setting => setting.name == key) && configurations.find(setting => setting.name == key).showFilter).length || ""}
                </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </Col>
            <Col xs={12} className="mt-2">
                {Object.keys(filterValues).filter(key => filterValues[key] !== null && configurations && configurations.find(setting => setting.name == key) && configurations.find(setting => setting.name == key).showFilter).map(key =>
                    <span className="search-filter-preview">
                        <span>
                            <i className="fa fa-times-circle" onClick={() => removeFilter(configurations.find(setting => setting.name == key).name)}></i>&nbsp;&nbsp;
                            {configurations.find(setting => setting.name == key).title}
                        </span>
                        {getValueByFilterType(configurations.find(setting => setting.name == key), filterValues[key])}
                    </span>
                )}
            </Col>
        </Row >
    )
}

export default compose(
    withApollo,
    graphql(PaymentsStatusesQuery, {
        props({ data: { loading, error, statuses } }) {
            return {
                loading, error, statuses
            }
        }
    })
)(PaymentSchedulesListHeader)

