import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import { Popover, PopoverBody, PopoverHeader, Button, FormGroup, Label, Input, Progress, Alert } from 'reactstrap'
import Select from 'react-select'
import States from "../../Material/States.json"
import Cities from "../../Material/Cities.json"
import SearchPlaces from "../../Material/SearchPlaces"

const usStates = [
    { value: "", label: "" }, { value: "NY", label: "New York" }, { value: "NJ", label: "New Jersey" }, { value: "PA", label: "Pennsylvania" },
    { value: "MD", label: "Maryland" }, { value: "DC", label: "Dist of Columbia" }, { value: "CT", label: "Connecticut" },
    { value: "AL", label: "Alabama" }, { value: "AK", label: "Alaska" }, { value: "AZ", label: "Arizona" },
    { value: "AR", label: "Arkansas" }, { value: "CA", label: "California" }, { value: "CO", label: "Colorado" },
    { value: "DE", label: "Delaware" },
    { value: "FL", label: "Florida" }, { value: "GA", label: "Georgia" }, { value: "HI", label: "Hawaii" },
    { value: "ID", label: "Idaho" }, { value: "IL", label: "Illinois" }, { value: "IN", label: "Indiana" },
    { value: "IA", label: "Iowa" }, { value: "KS", label: "Kansas" }, { value: "KY", label: "Kentucky" },
    { value: "LA", label: "Louisiana" }, { value: "ME", label: "Maine" },
    { value: "MA", label: "Massachusetts" }, { value: "MI", label: "Michigan" }, { value: "MN", label: "Minnesota" },
    { value: "MS", label: "Mississippi" }, { value: "MO", label: "Missouri" }, { value: "MT", label: "Montana" },
    { value: "NE", label: "Nebraska" }, { value: "NV", label: "Nevada" }, { value: "NH", label: "New Hampshire" },
    { value: "NM", label: "New Mexico" },
    { value: "NC", label: "North Carolina" }, { value: "ND", label: "North Dakota" }, { value: "OH", label: "Ohio" },
    { value: "OK", label: "Oklahoma" }, { value: "OR", label: "Oregon" },
    { value: "RI", label: "Rhode Island" }, { value: "SC", label: "South Carolina" }, { value: "SD", label: "South Dakota" },
    { value: "TN", label: "Tennessee" }, { value: "TX", label: "Texas" }, { value: "UT", label: "Utah" },
    { value: "VT", label: "Vermont" }, { value: "VA", label: "Virginia" }, { value: "WA", label: "Washington" },
    { value: "WV", label: "West Virginia" }, { value: "WI", label: "Wisconsin" }, { value: "WY", label: "Wyoming" },
]

const UpdateBranch = gql`mutation updateBranchMutation($input: UpdateBranchMutationInput!){
    updateBranch(input:$input){
        ok
        errors{
            field
            messages
        }
    }
} `

const AllDriverDocumentTypesQuery = gql`
    query AllDriverDocumentTypes($driverId:ID, $branchId: ID){
        allDriverDocumentTypes(isActive:true, driverId: $driverId, branchId:$branchId){
            edges{
                node{
                    id
                    typeName
                }
            }
      }
    }
`
const AllCarLocationsQuery = gql`
    query AllCarLocations($branchId:ID!){
        allCarLocations(branchId:$branchId){
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`
const AllCarDocumentTypesQuery = gql`
    query AllCarDocumentTypes{
        allCarDocumentTypes(isActive:true){
            edges{
                node{
                    id
                    typeName
                }
            }
        }
    }
`
const AllTransactionSettingsQuery = gql`
    query AllTransactionSettings{
        allTransactionSettings {
            edges {
                node {
                    id
                    chargeType{
                        name
                    }
                    subChargeType{
                        name
                    }
                    isFixed
                    chargeAmount
                    chargeMinAmount
                    chargeMaxAmount
                    chargeInterval
                    createdBy{
                        username
                    }
                }
            }   
        }
    }
`

const BranchLegalEntity = gql`
    query BranchLegalEntity{
      BranchLegalEntity:__type(name: "BranchLegalEntity") {
            values: enumValues {
                name
                description
            }
        }
    }`

const AllLineOfBusinessQuery = gql`
    query AllLineOfBusiness{
        allLineOfBusiness {
            edges {
                node {
                    id
                    name
                }
            }   
        }
    }
`

class UpdateBranchInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            input: {},
            newSettingsKey: "",
            loading: false
        }
    }

    updateBranch = () => {
        if (this.props.branchId) {
            this.setState({ loading: true })
            let input = this.state.input
            input["branchId"] = this.props.branchId
            this.props.client.mutate({
                mutation: UpdateBranch,
                variables: { input },
            }).then((result) => {
                if (result && result.data && result.data.updateBranch && result.data.updateBranch.ok) {
                    this.props.refetchBranchQuery()
                    this.props.handleClose()
                    this.setState({ input: {}, loading: false, error: null })
                } else if (result.data.updateBranch && result.data.updateBranch.errors) {
                    this.setState({ loading: false, error: String(result.data.updateBranch.errors[0].messages) })
                } else {
                    this.setState({ loading: false, error: "An error has occurred. Please check your input or contact admin." })
                }
            }).catch((err) => {
                this.setState({ loading: false, error: "An error has occurred. Please contact admin." })
            })
        } else {
        }
    }


    updateInput = (e) => {
        if (this.props.branchId) {
            let name = e.target.name
            let value = e.target.value
            if (e.target.type === "number") {
                if (isNaN(parseFloat(value))) {
                    value = 0
                } else {
                    value = parseFloat(value)
                }
            }
            let input = this.state.input
            input[name] = value
            this.setState({ input: input })
        }
    }

    componentDidMount() {
        if (this.props.type === "boolean") {
            this.setState({ input: { [this.props.name]: this.props.value } })
        }
    }

    renderCityDropdown = (selectedState) => {
        let cities = selectedState && Object.keys(States).includes(selectedState) && Object.keys(Cities).includes(States[selectedState]) ? Cities[States[selectedState]].map(city => ({ value: city, label: city })) : []
        return <Select
            className="bos-custom-select" classNamePrefix="bos-select"
            isDisabled={!this.props.value}
            options={cities}
            placeholder="Select City"
            onChange={(city) => this.setState({ input: { city: city.value } })}
        />
    }

    render() {
        return (
            <div>
                <Popover placement="bottom" isOpen={this.props.open} target={this.props.target} toggle={this.props.handleClose}>
                    <PopoverHeader onClick={this.props.handleClose}>{this.props.title}&nbsp;<i style={{ float: "right" }} className="fa fa-times-circle" aria-hidden="true"> </i></PopoverHeader>
                    <PopoverBody>
                        {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
                        <FormGroup>
                            <Label for={this.props.name}>{this.props.title}</Label>
                            {this.props.name === "stateCode" ?
                                <Input type={"select"} maxLength={2} name={this.props.name} id={this.props.name} defaultValue={this.props.value} onChange={this.updateInput}>
                                    {usStates.map((state, i) =>
                                        <option key={i} value={state.value}>{state.value} - {state.label}</option>
                                    )}
                                </Input>
                                :
                                this.props.name === "countryCode" ?
                                    <Input type={"select"} maxLength={2} name={this.props.name} id={this.props.name} defaultValue={this.props.value} onChange={this.updateInput}>
                                        {usStates.map((state, i) =>
                                            <option key={i} value={state.value}>{state.value} - {state.label}</option>
                                        )}
                                    </Input>
                                    : ["driverDocsRequiredForInsurance", "driverDocsRequiredForRental", "driverDocsRequiredForApplication", "driverDocTypes"].includes(this.props.name) ?
                                        <Select
                                            className="bos-custom-select" classNamePrefix="bos-select"
                                            isLoading={this.props.allDriverDocumentTypesLoading}
                                            options={this.props.allDriverDocumentTypes && this.props.allDriverDocumentTypes.edges && this.props.allDriverDocumentTypes.edges.length > 0 ? this.props.allDriverDocumentTypes.edges.map(item => ({ value: item.node.id, label: item.node.typeName })) : []}
                                            defaultValue={this.props.value && this.props.value.edges && this.props.value.edges.length > 0 ? this.props.value.edges.map(item => ({ value: item.node.id, label: item.node.typeName })) : null}
                                            placeholder="Driver Document Types"
                                            isMulti
                                            isClearable={false}
                                            onChange={(docTypes) => this.setState({ input: { [this.props.name]: docTypes.map(item => item.value) } })} />
                                        : ["carDocsRequiredForRental", "carDocsRequiredForInspection"].includes(this.props.name) ?
                                            <Select
                                                className="bos-custom-select" classNamePrefix="bos-select"
                                                isLoading={this.props.allCarDocumentTypesLoading}
                                                options={this.props.allCarDocumentTypes && this.props.allCarDocumentTypes.edges && this.props.allCarDocumentTypes.edges.length > 0 ? this.props.allCarDocumentTypes.edges.map(item => ({ value: item.node.id, label: item.node.typeName })) : []}
                                                defaultValue={this.props.value && this.props.value.edges && this.props.value.edges.length > 0 ? this.props.value.edges.map(item => ({ value: item.node.id, label: item.node.typeName })) : null}
                                                placeholder="Car Document Types"
                                                isMulti
                                                isClearable={false}
                                                onChange={(docTypes) => this.setState({ input: { [this.props.name]: docTypes.map(item => item.value) } })} />
                                            : "transactionSettings" === this.props.name ?
                                                <Select
                                                    className="bos-custom-select" classNamePrefix="bos-select"
                                                    isLoading={this.props.allTransactionSettingsLoading}
                                                    options={this.props.allTransactionSettings && this.props.allTransactionSettings.edges && this.props.allTransactionSettings.edges.length > 0 ? this.props.allTransactionSettings.edges.map(item => ({ value: item.node.id, label: `${item.node.chargeType.name}${item.node.subChargeType && item.node.subChargeType.name ? (" " + item.node.subChargeType.name) : ""} ${item.node.isFixed ? ("$" + item.node.chargeAmount) : ("$" + item.node.chargeMinAmount + " - $" + item.node.chargeMaxAmount)} ${item.node.chargeInterval}` })) : []}
                                                    defaultValue={this.props.value && this.props.value.edges && this.props.value.edges.length > 0 ? this.props.value.edges.map(item => ({ value: item.node.id, label: `${item.node.subChargeType && item.node.subChargeType.name ? item.node.subChargeType.name : item.node.chargeType.name} ${item.node.isFixed ? ("$" + item.node.chargeAmount) : ("$" + item.node.chargeMinAmount + " - $" + item.node.chargeMaxAmount)} ${item.node.chargeInterval}` })) : null}
                                                    placeholder="Branch Transaction Settings"
                                                    isMulti
                                                    isClearable={false}
                                                    onChange={(transactionSettings) => this.setState({ input: { [this.props.name]: transactionSettings.map(item => item.value) } })} />
                                                : ["returnLocationId", "pickupLocationId"].includes(this.props.name) ?
                                                    <Select
                                                        className="bos-custom-select" classNamePrefix="bos-select"
                                                        isLoading={this.props.allCarLocationsLoading}
                                                        options={this.props.allCarLocations && this.props.allCarLocations.edges && this.props.allCarLocations.edges.length > 0 ? this.props.allCarLocations.edges.map(item => ({ value: item.node.id, label: item.node.name })) : []}
                                                        defaultValue={this.props.value ? ({ value: this.props.value.id, label: this.props.value.name }) : null}
                                                        placeholder="Car Locations"
                                                        onChange={(location) => this.setState({ input: { [this.props.name]: location.value } })} />
                                                    : this.props.name === "legalEntity" ?
                                                        <Select
                                                            className="bos-custom-select" classNamePrefix="bos-select"
                                                            options={this.props.BranchLegalEntity && this.props.BranchLegalEntity.values && this.props.BranchLegalEntity.values.length > 0 ? this.props.BranchLegalEntity.values.map(item => ({ value: item.description, label: item.description })) : []}
                                                            defaultValue={this.props.value ? ({ value: this.props.value, label: this.props.value }) : null}
                                                            placeholder="Choose Branch Legal Entity"
                                                            onChange={(legalEntity) => this.setState({ input: { [this.props.name]: legalEntity.value } })} />
                                                        : this.props.name === "lineOfBusinessId" ?
                                                            <Select
                                                                className="bos-custom-select" classNamePrefix="bos-select"
                                                                isLoading={this.props.allLineOfBusinessLoading}
                                                                options={this.props.allLineOfBusiness && this.props.allLineOfBusiness.edges && this.props.allLineOfBusiness.edges.length > 0 ? this.props.allLineOfBusiness.edges.map(item => ({ value: item.node.id, label: item.node.name })) : []}
                                                                defaultValue={this.props.value ? ({ value: this.props.value, label: this.props.allLineOfBusiness && this.props.allLineOfBusiness.edges && this.props.allLineOfBusiness.edges.length > 0 ? this.props.allLineOfBusiness.edges.find(item => item.node.id === this.props.value).node.name : "" }) : null}
                                                                placeholder="Choose Line Of Bussiness"
                                                                isClearable={false}
                                                                onChange={(lineOfBussiness) => this.setState({ input: { [this.props.name]: lineOfBussiness.value } })} />
                                                            : this.props.name === "city" ?
                                                                <>
                                                                    <SearchPlaces
                                                                        onSaveCityCode={(city) => this.setState({ input: { city: city.value } })}
                                                                        initialCityName={this.props.value}
                                                                        searchType={"city"}
                                                                    />
                                                                </>
                                                                : this.props.type === "boolean" ?
                                                                    <>
                                                                        <br />
                                                                        <div>
                                                                            <span>Yes</span>
                                                                            <div className="toggle-switch">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="toggle-switch-checkbox"
                                                                                    name={this.props.name}
                                                                                    id={"branchInfo"}
                                                                                    checked={!this.state.input[this.props.name]}
                                                                                    onChange={() => this.setState({ input: { [this.props.name]: !this.state.input[this.props.name] } })}
                                                                                />
                                                                                <label className="toggle-switch-label" htmlFor={"branchInfo"}>
                                                                                    <span className="toggle-switch-inner" />
                                                                                    <span className="toggle-switch-switch" />
                                                                                </label>
                                                                            </div>
                                                                            <span>No</span>
                                                                        </div>
                                                                    </>
                                                                    : <Input autoComplete={"off"} type={this.props.type} maxLength={this.props.maxLength ? this.props.maxLength : 200}
                                                                        name={this.props.name} id={this.props.name} defaultValue={this.props.value}
                                                                        placeholder={this.props.title} onChange={this.updateInput}
                                                                    />
                            }
                        </FormGroup>
                        {this.state.loading ? <Progress animated color="info" value={100} /> :
                            <Button type="button" size="sm" onClick={this.updateBranch} disabled={!(this.props.branchId)}>Submit</Button>
                        }
                    </PopoverBody>
                </Popover>
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(AllDriverDocumentTypesQuery, {
        options: ({ branchId, name }) => ({ variables: { branchId: name !== "driverDocTypes" ? branchId : null }, fetchPolicy: "cache-and-network", }),
        props({ data: { loading, allDriverDocumentTypes } }) {
            return { allDriverDocumentTypesLoading: loading, allDriverDocumentTypes }
        },
    }),
    graphql(AllCarDocumentTypesQuery, {
        options: { fetchPolicy: 'cache-and-network' },
        props({ data: { loading, allCarDocumentTypes } }) {
            return { allCarDocumentTypesLoading: loading, allCarDocumentTypes }
        },
    }),
    graphql(AllCarLocationsQuery, {
        options: ({ branchId }) => ({
            variables: {
                branchId
            },
            fetchPolicy: 'cache-first'
        }),
        props({ data: { loading, allCarLocations } }) {
            return { allCarLocationsLoading: loading, allCarLocations }
        },
    }),
    graphql(AllTransactionSettingsQuery, {
        options: { fetchPolicy: 'cache-first' },
        props({ data: { loading, allTransactionSettings } }) {
            return { allTransactionSettingsLoading: loading, allTransactionSettings }
        },
    }),
    graphql(AllLineOfBusinessQuery, {
        options: { fetchPolicy: 'cache-first' },
        props({ data: { loading, allLineOfBusiness } }) {
            return { allLineOfBusinessLoading: loading, allLineOfBusiness }
        },
    }),
    graphql(BranchLegalEntity, { props({ data: { BranchLegalEntity } }) { return { BranchLegalEntity } } }),
)(UpdateBranchInfo)
