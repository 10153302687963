import React, { Component, Fragment } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import { Link } from 'react-router-dom'
import { Badge, Button, Col, Row, Label, Table, UncontrolledTooltip, Nav, NavItem, NavLink, Popover, PopoverBody, PopoverHeader, Progress } from 'reactstrap'
import { EnumQuery, HasPermissionsQuery } from "../../../../../Functions/querys"
import DriverNotes from "../../../../../ApplicationsNew/DriverNotes"
import UpdateReturnInfo from "../../../../../Cars/CarListNew/UpdateReturnInfo"
import ScheduleReturnForm from "../../../../../Material/ScheduleReturnForm"
import CancelReturn from "../../../../../Material/CancelReturn"
import NewAgreementPrice from "../../../../../Material/NewAgreementPrice"
import UpdateAgreementInfo from "../../../../../Material/UpdateAgreementInfo"
import Loader from "../../../../../Material/Loader"
import Lightbox from "react-awesome-lightbox"
import "react-awesome-lightbox/build/style.css"
import toTitleCase from "../../../../../Functions/toTitleCase"
import renderEnumToInt from "../../../../../Functions/renderEnumToInt"
import CarTrackerModal from "../../../../../Material/CarTrackerModal"
import { ReactComponent as LocationIcon } from '../../../../../assets/images/icons/location-icon.svg'
import CarNotes from "../../../../../Material/CarNotes"
import DatetimeRenderer from "../../../../../Material/DatetimeRenderer"
import UpdateCarInfo from "../../../../../Material/UpdateCarInfo"
import { ReactComponent as Repo } from '../../../../../assets/images/icons/repo.svg'
import UploadDocumentModal from "../../../../../Material/DocumentModal/UploadDocumentModal"
import gql from 'graphql-tag'

const pObj = (jsonString) => {//JSON.parse(jsonString)
    let obj = JSON.parse(jsonString)
    return obj
}
const AgreementDetailQuery = gql`query AgreementDetailQuery($pk: Int, $id:ID) {
    rental(pk:$pk,id: $id){
        id
        pk
        startDate
        stage
        insuranceUpcharge
        agreementType
        endDate
        depositScheme
        deposit
        depositAmount
        notes
        isContractSigned
        upcomingRentalTask {
            amountToCharge
            scheduleTime
        }
        tags {
            id
            name
            color
        }
        branch {
            id
            name
        }
        splitAgreement {
            id
            driver {
                id
                name
            }
        }
        currentReturn {
            id
            pk
            autoDepositRefund
            stage
            scheduledDate
            closedDate
            dateAdded
            returnType
            returnCategory
            returnReason
            returnStage
            nextPickupDate
            gas
            isScheduled
            mileage
            damage
            notes
            history{
                date
                user
                comment
            }
            representative {
                id
                username
            }
            returnCondition {
                id
                gas
                mileage
                carpicturesSet {
                edges {
                    node {
                        id
                        pictureUrl
                        pictureType
                        description
                    }
                }
                }
            }
        }
        carreturnSet(isDeleted:true){
            edges {
                node {
                    id
                    autoDepositRefund
                    dateAdded
                    returnType
                    returnCategory
                    returnReason
                    scheduledDate
                    cancelledDate
                    stage
                    isDeleted
                    representative {
                        id
                        username
                    }
                }
            }
        }
        type {
            id
            name
        }
        transactionSet(orderBy:"-id") {
            edges {
                node {
                    id
                    pk
                    dateAdded
                    amount
                    status
                    eventDatetime
                    balance
                    notes
                    updateFor{
                        id
                        pk
                    }
                    createdBy {
                        id
                        username
                    }
                    chargeType {
                        id
                        name
                    }
                }
            }
        }
        agreementpriceSet {
            edges {
                node {
                    id
                    price
                    interval
                    intervalUnit
                    currency { code symbol }
                    isActive
                    startDate
                    createdBy {
                        id
                        username
                    }
                    updatedBy {
                        id
                        username
                    }
                }
            }
        }
        agreementpromoSet {
            edges {
                node {
                    id
                    promoPrice
                    interval
                    intervalUnit
                    currency { code symbol }
                    isCurrentlyAcive
                    isPercentage
                    startDate
                    createdBy {
                        id
                        username
                    }
                    updatedBy {
                        id
                        username
                    }
                }
            }
        }
        history {
            user
            date
            comment
        }
        pickupCondition {
            id
            gas
            mileage
            formData
            carpicturesSet {
                edges {
                    node {
                        id
                        pictureUrl
                        pictureType
                        description
                    }
                }
            }
        }
        returnCondition {
            id
            formData
        }
        representative {
            id
            username
        }
        driver {
            id
            name
            tlcLicense
            phone
            email
            balance
        }
        car {
            id
            pk
            year
            color
            location
            vin
            trackerStatus
            hasTracker
            notes
            dailymileageSet {
                edges {
                  node {
                    id
                    dateAdded
                    dateModified
                    mileage
                  }
                }
            }
            branch {
                id
                name
            }
            carModel {
                id
                make
                name
                series
                groupType {
                    id
                    name
                }
            }
            plate {
                id
                dmvPlate
                fhv
            }
            trackers {
                trackingId
                trackerType
            }
            activePolicy {
                id
                insurancePolicy {
                    id
                    policyNumber
                    broker {
                        id
                        name
                    }
                }
            }
            equipmentSet(first: 10, removed: false) {
                edges {
                    node {
                        id
                        dateAdded
                        removed
                        customDeviceId
                        equipmentItems {
                            id
                            pk
                            name
                            equipmentItemsGroup {
                                id
                                name
                            }
                        }
                    }
                }
            }
        }
    }
}`

const HISTORY_TABLE_HEADER = [
    { id: "date", name: "Date" },
    { id: "detail", name: "Details" },
    { id: "createdBy", name: "Created By" },
]
const PRICING_TABLE_HEADER = [
    { id: "price", name: "Price" },
    { id: "startDate", name: "Start Date" },
    { id: "status", name: "Status" },
    { id: "createdBy", name: "Created By" },
]
const RENTAL_TRANSACTIONS_HEADER = [
    { id: "id", name: "ID" },
    { id: "date_added", name: "Date Added" },
    { id: "charge_type", name: "Charge Type" },
    { id: "amount", name: "Amount($)" },
    { id: "event_datetime", name: "Event Date" },
    { id: "createdBy", name: "Created By" },
]
class DriverRentalDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            rental: null,
            agreementDetailSubsection: "Transactions History",
            returnDetailSubsection: "Return Info",
            rentalDetailSubsection: "Rental Info",
            carDetailSubsection: "carDetails",
            images: null,
            transactionId: null,
            tab: "",
        }
    }

    getAgreementState = (state) => {
        if (!this.props.agreementStates) {
            return "------"
        }
        let sobj = this.props.agreementStates.states.filter(s => s.name === state)
        return sobj.length ? sobj[0].description : "------"
    }
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }
    toggleSectionNav = (sectionName, value) => {
        this.setState({ [sectionName]: value })
    }
    getCarReturnState = (state) => {
        if (!this.props.carReturnStates) {
            return "------"
        }
        let sobj = this.props.carReturnStates.states.filter(s => s.name === state)
        return sobj.length ? sobj[0].description : "------"
    }
    viewImages(object) {
        let tmpArr = []
        if (object && object.carpicturesSet && object.carpicturesSet.edges && object.carpicturesSet.edges.length > 0) {
            const toBeAdded = object.carpicturesSet.edges.map((pic, i) => ({ url: pic.node.pictureUrl, title: pic.node.pictureType }))
            tmpArr = [...tmpArr, ...toBeAdded]
        }
        this.setState({ images: tmpArr })
        this.toggleModal("viewImages")
    }
    openTransaction = (transactionId) => {
        this.setState({ openModal: "TransactionInfo", transactionId: transactionId })
    }

    renderCarReturn = (carReturn, rental) => {
        return (
            <>
                {
                    <Row>
                        <Col xs={4}>
                            <Label>Return Category</Label>
                            <p className="columnContent">{toTitleCase(carReturn.returnCategory)} <Badge color="secondary">{this.getCarReturnState(carReturn.stage)}</Badge></p>
                        </Col>
                        <Col xs={4}>
                            <Label>Reason</Label>
                            <p className="columnContent">{carReturn.returnReason}</p>
                        </Col>
                        <Col xs={4}>
                            <Label>Rep</Label>
                            <p className="columnContent">{carReturn.representative && carReturn.representative.username ? carReturn.representative.username : "--"}</p>
                        </Col>
                        <Col xs={4}>
                            <Label>
                                <span id={"returnIsScheduledAt" + carReturn.pk}>Scheduled Date</span>
                                <UncontrolledTooltip placement="right" target={"returnIsScheduledAt" + carReturn.pk}>Created On: {carReturn.dateAdded ? <DatetimeRenderer datetime={carReturn.dateAdded} /> : "--"}</UncontrolledTooltip>
                            </Label>
                            <p>
                                {carReturn.scheduledDate ?
                                    <>
                                        <DatetimeRenderer datetime={carReturn.scheduledDate} />
                                        <Badge className="ml-3" id={"returnIsScheduled" + carReturn.pk} color={carReturn.isScheduled ? "success" : "danger"} style={{ height: "10px", padding: "4px" }}>&nbsp;</Badge>
                                        <UncontrolledTooltip placement="right" target={"returnIsScheduled" + carReturn.pk}>{carReturn.isScheduled ? "Scheduled Return" : "Un-Scheduled Return"}</UncontrolledTooltip>
                                    </>
                                    :
                                    "--"}
                            </p>
                        </Col>
                        <Col xs={4}>
                            <Label>{carReturn.cancelledDate ? "Cancelled Date" : "Closed Date"}</Label>
                            <p className="columnContent">{carReturn.cancelledDate ? <DatetimeRenderer datetime={carReturn.cancelledDate} /> : carReturn.closedDate ? <DatetimeRenderer datetime={carReturn.closedDate} /> : "--"}</p>
                        </Col>
                        <Col xs={4}>
                            <Label>Future Pickup Date</Label>
                            <p className="columnContent">{carReturn.nextPickupDate ? <DatetimeRenderer datetime={carReturn.nextPickupDate} /> : "--"}</p>
                        </Col>
                        <Col xs={4}>
                            <Label>Gas</Label>
                            <p className="columnContent">{carReturn.gas ? renderEnumToInt(carReturn.gas) : "--"}</p>
                        </Col>
                        <Col xs={4}>
                            <Label>Mileage</Label>
                            <p className="columnContent">{carReturn.mileage ? carReturn.mileage : "--"}</p>
                        </Col>
                        <Col xs={4}>
                            <Label>Auto Deposit Refund</Label>
                            <p className="columnContent" style={{ color: carReturn.autoDepositRefund ? "green" : "red" }}>{carReturn.autoDepositRefund ? "YES" : "NO"}</p>
                        </Col>
                        {carReturn && carReturn.stage && <Col xs={4}>
                            {rental.stage != "_1" && this.state.openModal === "CancelReturn" + carReturn.id && <CancelReturn handleClose={() => this.toggleModal("")} carReturnId={carReturn.id} open={this.state.openModal === "CancelReturn" + carReturn.id} refetchQuery={() => { this.props.refetchAgreementsQuery() }} returnStages={this.props.carReturnStates} />}
                            {rental.stage != "_1" && this.state.openModal === "previewReturn" + carReturn.id &&
                                <ScheduleReturnForm
                                    refetchQuery={() => this.props.refetchAgreementsQuery()}
                                    handleClose={() => this.toggleModal("")}
                                    rental={rental}
                                    carReturn={carReturn}
                                    car={carReturn.car}
                                    open={this.state.openModal === "previewReturn" + carReturn.id}
                                    editReturn={true}
                                    returnStages={this.props.carReturnStates}
                                    currentUser={this.props.currentUser ? this.props.currentUser : ""}
                                />
                            }
                            <Label>Action</Label><br />
                            {carReturn && carReturn.stage && this.getCarReturnState(carReturn.stage) && this.getCarReturnState(carReturn.stage) === "Awaiting Closeout" &&
                                <>
                                    <Button size="sm" className="primary" onClick={() => this.toggleModal("CloseReturn" + carReturn.id)}>CLOSE RETURN</Button>&nbsp;&nbsp;
                                </>
                            }
                            {carReturn && carReturn.stage && ["Cancelled", "Returned", "Awaiting Closeout"].includes(this.getCarReturnState(carReturn.stage)) ?
                                <><Button size="sm" className="primary" onClick={() => this.toggleModal("previewReturn" + carReturn.id)}>PREVIEW</Button>&nbsp;&nbsp;</> :
                                <><Button size="sm" className="primary" onClick={() => this.toggleModal("CancelReturn" + carReturn.id)}>CANCEL</Button>&nbsp;&nbsp;</>
                            }
                            {carReturn && carReturn.stage && !["Cancelled", "Awaiting Closeout", "Returned"].includes(this.getCarReturnState(carReturn.stage)) &&
                                <><Button size="sm" className="primary" onClick={() => this.toggleModal("EditReturn" + carReturn.id)}>EDIT</Button>&nbsp;&nbsp;</>
                            }
                            {((carReturn && carReturn.returnCondition && carReturn.returnCondition.carpicturesSet && carReturn.returnCondition.carpicturesSet.edges) || (carReturn.returnCondition && carReturn.returnCondition.formData && pObj(carReturn.returnCondition.formData) && 'event_url' in pObj(carReturn.returnCondition.formData))) &&
                                <>
                                    {carReturn && carReturn.returnCondition && carReturn.returnCondition.carpicturesSet && carReturn.returnCondition.carpicturesSet.edges &&
                                        <>
                                            <Button size="sm" className="primary" onClick={() => this.viewImages(carReturn.returnCondition)}>Return Images</Button>&nbsp;&nbsp;
                                        </>
                                    }
                                    {carReturn.returnCondition && carReturn.returnCondition.formData && pObj(carReturn.returnCondition.formData) && 'event_url' in pObj(carReturn.returnCondition.formData) &&
                                        <>
                                            <UncontrolledTooltip target="event_url">Event Url</UncontrolledTooltip>
                                            <a className="more-options" target="_blank" href={pObj(carReturn.returnCondition.formData)["event_url"]}><i id="event_url" className="fa fa-paperclip"></i></a>
                                        </>
                                    }
                                </>
                            }

                            &nbsp;<span style={{ fontSize: 15 }} id={"returnHistory"}><i id={"returnHistory" + carReturn.pk} onClick={() => this.toggleModal("returnHistory" + carReturn.pk)} className={"fa fa-history"}></i><br /></span>
                            <UncontrolledTooltip target="returnHistory">Return Update History</UncontrolledTooltip>

                            {this.state.openModal === "returnHistory" + carReturn.pk &&
                                <Popover className="notes_history" placement={"right"} isOpen={this.state.openModal === "returnHistory" + carReturn.pk} target={"returnHistory" + carReturn.pk} toggle={() => this.toggleModal("")}>
                                    <PopoverHeader onClick={() => this.toggleModal("")} className="d-flex justify-content-center">Return Update History</PopoverHeader>
                                    <PopoverBody>
                                        {this.state.loading && <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row>}
                                        <div className="notes_container">
                                            {!this.state.loading && carReturn.history && carReturn.history.length > 0 ? carReturn.history.map((history, i) =>
                                                <p key={i}>
                                                    <b>Date Modified: {<DatetimeRenderer datetime={history.date} />}</b><br />
                                                    <b>Username: {history.user ? history.user : "--"}<br /></b>
                                                    <b>Comment: </b>{history.comment}
                                                </p>
                                            ) : !this.state.loading && "No Return Update History Found!"}
                                        </div>
                                        <hr />
                                    </PopoverBody>
                                </Popover>
                            }
                        </Col>}
                        <Col xs={12}>
                            <br />
                            <Label id={"returnNotes" + carReturn.pk}>
                                Return Notes&nbsp;&nbsp;
                                <i onClick={() => this.toggleModal("returnNotesEdit" + carReturn.pk)} className={"fa fa-pencil-square-o"}></i>&nbsp;&nbsp;
                                <i onClick={() => this.toggleModal("returnNotesHistory" + carReturn.pk)} className={"fa fa-history"}></i><br />
                            </Label>
                            {rental.stage != "_1" && this.state.openModal === "returnNotesEdit" + carReturn.pk &&
                                <UpdateReturnInfo carReturn={carReturn} title="Edit Return Notes" name="notes" value={carReturn.notes} target={"returnNotes" + carReturn.pk} id="returnNotes" type="text"
                                    open={this.state.openModal === "returnNotesEdit" + carReturn.pk} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchAgreementsQuery} placement={"left"}
                                />
                            }
                            {this.state.openModal === "returnNotesHistory" + carReturn.pk &&
                                <DriverNotes driver={rental.driver} noteType="RETURN" title="Return Notes" objectId={carReturn.id} target={"returnNotes" + carReturn.pk} open={this.state.openModal === "returnNotesHistory" + carReturn.pk} handleClose={() => this.toggleModal("")} placement={"left"} />
                            }
                            <br />
                            <p>
                                {carReturn.notes && carReturn.notes.split('\n').map((line, i) =>
                                    <span key={i}>{line}</span>
                                )}
                            </p>
                        </Col>
                    </Row>
                }
                {rental.stage != "_1" && this.state.openModal === "CloseReturn" + carReturn.id &&
                    <ScheduleReturnForm refetchQuery={() => { this.props.refetchAgreementsQuery() }} handleClose={() => this.toggleModal("")} rental={rental} carReturn={carReturn} car={carReturn.car} driver={rental.driver} open={this.state.openModal === "CloseReturn" + carReturn.id} editReturn={true} closeReturn={true} returnStages={this.props.carReturnStates} currentUser={this.props.currentUser ? this.props.currentUser : ""} />
                }
                {rental.stage != "_1" && this.state.openModal === "EditReturn" + carReturn.id &&
                    <ScheduleReturnForm refetchQuery={() => { this.props.refetchAgreementsQuery() }} handleClose={() => this.toggleModal("")} rental={rental} carReturn={carReturn} car={carReturn.car} open={this.state.openModal === "EditReturn" + carReturn.id} editReturn={true} returnStages={this.props.carReturnStates} currentUser={this.props.currentUser ? this.props.currentUser : ""} />
                }
            </>
        )
    }
    render() {
        return (
            <>
                <Col className="zero-padding">
                    {this.props.loading ? <Loader /> : <>
                        {this.state.openModal === "NewAgreementPrice" && <NewAgreementPrice handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchAgreementsQuery} rental={this.props.rental} open={this.state.openModal === "NewAgreementPrice"} />}
                        {this.state.openModal === "viewImages" && <Lightbox images={this.state.images} onClose={() => this.toggleModal("viewImages")} />}
                        {this.props.rental && <div className="agreement-detail">
                            <Row>
                                <Col>
                                    <Nav tabs>
                                        <NavItem className={this.state.tab === "carDetails" ? "active" : "inactive"}>
                                            <NavLink className={this.state.carDetailSubsection === "carDetails" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("carDetailSubsection", "carDetails")}>
                                                CAR DETAILS
                                            </NavLink>
                                        </NavItem>
                                        <NavItem className={this.state.tab === "dailyMileage" ? "active" : "inactive"}>
                                            <NavLink className={this.state.carDetailSubsection === "dailyMileage" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("carDetailSubsection", "dailyMileage")}>
                                                DAILY MILEAGE
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    {this.state.carDetailSubsection === "dailyMileage" &&
                                        <div className="info-container" style={{ borderTopLeftRadius: "0px" }}>
                                            <Row>
                                                <Col><Label>Date Added</Label></Col>
                                                <Col><Label>Date Modified</Label></Col>
                                                <Col><Label>Mileage</Label></Col>
                                            </Row>
                                            {
                                                this.props.rental &&
                                                    this.props.rental.car &&
                                                    this.props.rental.car.dailymileageSet &&
                                                    this.props.rental.car.dailymileageSet.edges &&
                                                    this.props.rental.car.dailymileageSet.edges.length > 0 &&
                                                    this.props.rental.car.dailymileageSet.edges.length > 0 ?
                                                    this.props.rental.car.dailymileageSet.edges.reverse().map(item =>
                                                        <Row key={item.node.id}>
                                                            <Col>
                                                                <p>{item.node.dateAdded ? <DatetimeRenderer datetime={item.node.dateAdded} /> : "--"}</p>
                                                            </Col>
                                                            <Col>
                                                                <p>{item.node.dateModified ? <DatetimeRenderer datetime={item.node.dateModified} /> : "--"}</p>
                                                            </Col>
                                                            <Col>
                                                                <p>{item.node.mileage ? item.node.mileage : "--"}</p>
                                                            </Col>
                                                        </Row>
                                                    ) :
                                                    <Row> <Col>No Daily Mileage found! </Col></Row>}
                                        </div>
                                    }
                                    {this.state.carDetailSubsection === "carDetails" &&
                                        <div className="info-container" style={{ margin: "0px", borderTopLeftRadius: "0px" }}>
                                            <Row>
                                                <Col>
                                                    {this.props.rental ?
                                                        <div>
                                                            <Row>
                                                                <Col xs={2}>
                                                                    <Label>Car ID</Label>
                                                                    <p className="columnContent">
                                                                        {this.props.rental.car ? this.props.rental.car.pk : "---"}
                                                                        {this.props.rental.car && this.props.rental.car.pk && <small>&nbsp;<Link color="primary" to={'/car/' + this.props.rental.car.pk} target="_blank"><i className={"fa fa-external-link-square"}></i></Link></small>}
                                                                    </p>
                                                                </Col>
                                                                <Col xs={2}>
                                                                    <Label>Vin</Label>
                                                                    <p className="columnContent">{this.props.rental.car ? this.props.rental.car.vin : "---"}</p>
                                                                </Col>
                                                                <Col xs={2}>
                                                                    <Label>DMV Plate</Label>
                                                                    <p className="columnContent">{this.props.rental.car && this.props.rental.car.plate ? this.props.rental.car.plate.dmvPlate : "---"}</p>
                                                                </Col>
                                                                {this.props.rental && this.props.rental.car && this.props.rental.car.plate && this.props.rental.car.plate.fhv &&
                                                                    <Col xs={2}>
                                                                        <Label>FHV</Label>
                                                                        <p className="columnContent">{this.props.rental.car && this.props.rental.car.plate ? this.props.rental.car.plate.fhv : "---"}</p>
                                                                    </Col>
                                                                }
                                                                <Col xs={2}>
                                                                    <Label>Model</Label>
                                                                    <p className="columnContent">{this.props.rental.car && this.props.rental.car.carModel ? toTitleCase(this.props.rental.car.carModel.make ? this.props.rental.car.carModel.make : "--") + " " + toTitleCase(this.props.rental.car.carModel.name ? this.props.rental.car.carModel.name : "--") : "--"}  <small>{this.props.rental.car && this.props.rental.car.carModel && this.props.rental.car.carModel.series ? this.props.rental.car.carModel.series : ""}</small></p>
                                                                </Col>
                                                                <Col xs={2}>
                                                                    <Label>Group</Label>
                                                                    <p className="columnContent">{this.props.rental.car && this.props.rental.car.carModel && this.props.rental.car.carModel.groupType ? this.props.rental.car.carModel.groupType.name : "--"}</p>
                                                                </Col>
                                                                <Col xs={2}>
                                                                    <Label>Color/Year</Label>
                                                                    <p className="columnContent">{this.props.rental.car ? this.props.rental.car.color : "---"} - {this.props.rental.car ? this.props.rental.car.year : "---"}</p>
                                                                </Col>
                                                                <Col xs={2}>
                                                                    <Label>Policy</Label>
                                                                    <p className="columnContent">
                                                                        {this.props.rental.car && this.props.rental.car.activePolicy && this.props.rental.car.activePolicy.insurancePolicy ?
                                                                            (this.props.rental.car.activePolicy.insurancePolicy.broker && this.props.rental.car.activePolicy.insurancePolicy.broker.name ? this.props.rental.car.activePolicy.insurancePolicy.broker.name : "--") + ": " + this.props.rental.car.activePolicy.insurancePolicy.policyNumber :
                                                                            "None found"
                                                                        }
                                                                    </p>
                                                                </Col>
                                                                <Col xs={2}>
                                                                    <Label>Car Branch</Label>
                                                                    <p className="columnContent"><Badge className='bos-badge-blue'>{this.props.rental && this.props.rental.car && this.props.rental.car.branch ? this.props.rental.car.branch.name : "No Car Branch"}</Badge> </p>
                                                                </Col>
                                                                {this.props.rental.driver && this.getAgreementState(this.props.rental.stage) !== "Returned" && this.getAgreementState(this.props.rental.stage) !== "Canceled" && this.props.rental.car &&
                                                                    <Fragment>
                                                                        <Col xs={2}>
                                                                            <Label>Equipments</Label>
                                                                            <p className="columnContent">
                                                                                {this.props.rental.car && this.props.rental.car.equipmentSet && this.props.rental.car.equipmentSet.edges && this.props.rental.car.equipmentSet.edges.length > 0 ? this.props.rental.car.equipmentSet.edges.filter((_, i) => i < 1).map((equipment, i) =>
                                                                                    <><Badge size="sm" className={"black"}>{equipment.node.equipmentItems.name}</Badge>&nbsp;&nbsp;</>
                                                                                ) : "None Added"}
                                                                                {this.props.rental.car && this.props.rental.car.equipmentSet && this.props.rental.car.equipmentSet.edges && this.props.rental.car.equipmentSet.edges.length > 1 && <>
                                                                                    <span id={"more-equipments"}>+{this.props.rental.car.equipmentSet.edges.length - 1} more</span>
                                                                                    <UncontrolledTooltip className="more-info-tooltip" target={"more-equipments"} placement="right" delay={{ "show": 400, "hide": 200 }} autohide={false}>
                                                                                        {this.props.rental.car.equipmentSet.edges.filter((_, i) => i >= 1).map((equipment, i) =>
                                                                                            <>
                                                                                                <span>{equipment.node.equipmentItems && equipment.node.equipmentItems.name ? equipment.node.equipmentItems.name : "--"}</span><br />
                                                                                            </>
                                                                                        )}
                                                                                    </UncontrolledTooltip>
                                                                                </>}
                                                                                {/* {this.props.rental.car && this.props.rental.car.equipmentSet && this.props.rental.car.equipmentSet.edges && this.props.rental.car.equipmentSet.edges.length > 0 ?
                                                                                    this.props.rental.car.equipmentSet.edges.map((equipment, i) =>
                                                                                        <Badge size="sm" className={"black"}>
                                                                                            {equipment.node.equipmentItems && equipment.node.equipmentItems.name ? equipment.node.equipmentItems.name : "--"}
                                                                                        </Badge>) : <span>None added</span>
                                                                                } */}
                                                                            </p>
                                                                        </Col>
                                                                        {this.props.rental.car && this.props.rental.car.equipmentSet && this.props.rental.car.equipmentSet.edges && this.props.rental.car.equipmentSet.edges.filter(equipment => (
                                                                            equipment.node.equipmentItems.equipmentItemsGroup.name === "GPS")) &&
                                                                            this.props.rental.car.equipmentSet.edges.filter(equipment => (equipment.node.equipmentItems.equipmentItemsGroup.name === "GPS")).map((equipment, index) =>
                                                                                <Col xs={2}>
                                                                                    <Label>
                                                                                        {equipment.node.equipmentItems && equipment.node.equipmentItems.name}
                                                                                    </Label>
                                                                                    <Row>
                                                                                        <Col className="text-left">
                                                                                            <ul className="tracker-actions">
                                                                                                {(["Enabled", "", "Pending Enable"].indexOf(this.props.rental.car.trackerStatus) > -1) &&
                                                                                                    <li>
                                                                                                        <i id={"disable" + index} className="fa fa-power-off danger" aria-hidden="true" onClick={() => this.toggleModal("disable" + index)}></i>
                                                                                                        <UncontrolledTooltip target={"disable" + index} placement="top">Disable</UncontrolledTooltip>
                                                                                                        {this.state.openModal === "disable" + index &&
                                                                                                            <CarTrackerModal trackerId={equipment.node.customDeviceId} open={this.state.openModal === "disable" + index} handleClose={() => this.toggleModal("disable" + index)} command={"Disable"} car={this.props.rental.car} />
                                                                                                        }
                                                                                                    </li>
                                                                                                }&nbsp;&nbsp;
                                                                                                {(["Disabled", "Repo", "Pending Disable"].indexOf(this.props.rental.car.trackerStatus) > -1) &&
                                                                                                    <li>
                                                                                                        <i id={"enable" + index} className="fa fa-power-off fa-lg success" aria-hidden="true" onClick={() => this.toggleModal("enable" + index)}></i>
                                                                                                        <UncontrolledTooltip target={"enable" + index} placement="top">Enable</UncontrolledTooltip>
                                                                                                        {this.state.openModal === "enable" + index &&
                                                                                                            <CarTrackerModal trackerId={equipment.node.customDeviceId} open={this.state.openModal === "enable" + index} handleClose={() => this.toggleModal("enable" + index)} command={"Enable"} car={this.props.rental.car} />
                                                                                                        }
                                                                                                    </li>
                                                                                                }
                                                                                                <li>
                                                                                                    <LocationIcon id={"locate" + index} width={20} fill="#303E67" onClick={() => this.toggleModal("location" + index)} />&nbsp;&nbsp;
                                                                                                    <UncontrolledTooltip target={"locate" + index} placement="top">Locate Car</UncontrolledTooltip>
                                                                                                    {this.state.openModal === "location" + index &&
                                                                                                        <CarTrackerModal trackerId={equipment.node.customDeviceId} open={this.state.openModal === "location" + index} handleClose={() => this.toggleModal("location" + index)} command={"Location"} car={this.props.rental.car} />
                                                                                                    }
                                                                                                </li>
                                                                                                <li>
                                                                                                    <Repo id={"repo" + index} width={25} height={25} onClick={() => this.toggleModal("repo" + index)} />&nbsp;&nbsp;
                                                                                                    <UncontrolledTooltip target={"repo" + index} placement="top">Repo Car</UncontrolledTooltip>
                                                                                                    {this.state.openModal === "repo" + index &&
                                                                                                        <CarTrackerModal trackerId={equipment.node.customDeviceId} driver={this.props.rental.driver} open={this.state.openModal === "repo" + index} handleClose={() => this.toggleModal("repo" + index)} command={"Repo"} car={this.props.rental.car} />
                                                                                                    }
                                                                                                </li>
                                                                                                {["Teltonika FMC130", "General Motors OnStar"].includes(equipment.node.equipmentItems.name) &&
                                                                                                    <li>
                                                                                                        <li>
                                                                                                            <i id={"lock" + index} className="fa fa-solid fa-lock" onClick={() => this.toggleModal("lock" + index)}></i>&nbsp;&nbsp;
                                                                                                            <UncontrolledTooltip target={"lock" + index} placement="top">Lock</UncontrolledTooltip>
                                                                                                            {this.state.openModal === "lock" + index &&
                                                                                                                <CarTrackerModal trackerId={equipment.node.customDeviceId} open={this.state.openModal === "lock" + index} handleClose={() => this.toggleModal("lock" + index)} command={"Lock"} car={this.props.rental.car} />
                                                                                                            }
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <i id={"unlock" + index} className="fa fa-solid fa-unlock" onClick={() => this.toggleModal("unlock" + index)}></i>
                                                                                                            <UncontrolledTooltip target={"unlock" + index} placement="top">Unlock</UncontrolledTooltip>
                                                                                                            {this.state.openModal === "unlock" + index &&
                                                                                                                <CarTrackerModal trackerId={equipment.node.customDeviceId} open={this.state.openModal === "unlock" + index} handleClose={() => this.toggleModal("unlock" + index)} command={"Unlock"} car={this.props.rental.car} />
                                                                                                            }
                                                                                                        </li>
                                                                                                    </li>
                                                                                                }
                                                                                            </ul>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Col>
                                                                            )
                                                                        }
                                                                        <Col xs={2}>
                                                                            <Label>Car Status</Label><br />
                                                                            {this.props.rental.car && this.props.rental.car.hasTracker ?
                                                                                <Badge className={`columnContent ${["Disabled", "Repo", "Pending Disable"].indexOf(this.props.rental.car.trackerStatus) > -1 ? "danger" : ""}`}>{this.props.rental.car.trackerStatus}</Badge>
                                                                                :
                                                                                <Badge className="columnContent">No tracker</Badge>
                                                                            }
                                                                        </Col>
                                                                    </Fragment>
                                                                }
                                                                {this.props.rental.splitAgreement &&
                                                                    <Col xs={2}>
                                                                        <Label>Split Driver</Label>
                                                                        <p>{this.props.rental.splitAgreement.driver ? <Link target="_blank" to={"/driver/" + this.props.rental.splitAgreement.driver.id}><Button size="sm" className="primary">{this.props.rental.splitAgreement.driver.name}</Button></Link> : ""}</p>
                                                                    </Col>
                                                                }
                                                                <Col xs={12}>
                                                                    <Label id={"carNotesEdit"}>
                                                                        Car Notes&nbsp;&nbsp;
                                                                        <i onClick={() => this.toggleModal("notesHistory")} className={"fa fa-history"}></i>&nbsp;&nbsp;
                                                                        <i onClick={() => this.toggleModal("carNotesEdit")} className={"fa fa-pencil-square-o"}></i>&nbsp;&nbsp;
                                                                    </Label>
                                                                    {this.state.openModal === "notesHistory" &&
                                                                        <CarNotes car={this.props.rental.car} target={"carNotesEdit"} open={this.state.openModal === "notesHistory"} handleClose={() => this.toggleModal("")} />
                                                                    }
                                                                    {this.state.openModal === "carNotesEdit" &&
                                                                        <UpdateCarInfo car={this.props.rental.car} title="Edit Notes" name="notes" value={this.props.rental.car.notes} target={"carNotesEdit"} type="text" open={this.state.openModal === "carNotesEdit"} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchAgreementsQuery} />
                                                                    }
                                                                    <p className="columnContent">
                                                                        {this.props.rental.car.notes ? this.props.rental.car.notes : "--"}
                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                        </div> : <p>No Info Found</p>
                                                    }
                                                </Col>
                                            </Row>
                                        </div>}
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col style={{ marginBottom: "15px" }}>
                                    <Nav tabs>
                                        <NavItem className={this.state.tab === "Rental Info" ? "active" : "inactive"}>
                                            <NavLink className={this.state.rentalDetailSubsection === "Rental Info" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("rentalDetailSubsection", "Rental Info")}>
                                                RENTAL INFO
                                            </NavLink>
                                        </NavItem>
                                        <NavItem className={this.state.tab === "Rental Pricing" ? "active" : "inactive"}>
                                            <NavLink className={this.state.rentalDetailSubsection === "Rental Pricing" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("rentalDetailSubsection", "Rental Pricing")}>
                                                RENTAL PRICING
                                            </NavLink>
                                        </NavItem>
                                        <NavItem className={this.state.tab === "Rental History" ? "active" : "inactive"}>
                                            <NavLink className={this.state.rentalDetailSubsection === "Rental History" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("rentalDetailSubsection", "Rental History")}>
                                                RENTAL HISTORY
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    {this.state.rentalDetailSubsection === "Rental Info" &&
                                        <div className="info-container" style={{ borderTopLeftRadius: "0px" }}>
                                            <Row>
                                                <Col xs={12}>
                                                    <Row>
                                                        {this.props.rental && this.props.rental.agreementpriceSet && this.props.rental.agreementpriceSet.edges && this.props.rental.agreementpriceSet.edges.length > 0 ?
                                                            this.props.rental.agreementpriceSet.edges.filter(price => price.node.isActive).map((plan, i) =>
                                                                <>
                                                                    <Col xs={4}>
                                                                        <Label>{plan.node.isActive ? "ACTIVE " : ""}Price</Label>
                                                                        <div className="input-alternative-box">{"$" + plan.node.price + " per " + (plan.node.interval > 1 ? plan.node.interval : "") + plan.node.intervalUnit}</div>
                                                                    </Col>
                                                                    <Col xs={4}>
                                                                        <Label>UPCOMING CHARGE</Label>
                                                                        <div className="input-alternative-box">
                                                                            {this.props.rental.upcomingRentalTask && this.props.rental.upcomingRentalTask.amountToCharge ? ("$" + this.props.rental.upcomingRentalTask.amountToCharge + " on ") : "--"}&nbsp;{this.props.rental.upcomingRentalTask && this.props.rental.upcomingRentalTask.scheduleTime ? (<DatetimeRenderer datetime={this.props.rental.upcomingRentalTask.scheduleTime} />) : "--"}
                                                                        </div>
                                                                    </Col>
                                                                </>
                                                            ) :
                                                            <>
                                                                <Col xs={4}>
                                                                    <Label>Active Price</Label>
                                                                    <div className="input-alternative-box">--</div>
                                                                </Col>
                                                                <Col xs={4}>
                                                                    <Label>UPCOMING CHARGE</Label>
                                                                    <div className="input-alternative-box">--</div>
                                                                </Col>
                                                            </>
                                                        }
                                                        <Col xs={4}>
                                                            <Label id={"agreementStage"}>Stage&nbsp;&nbsp;
                                                                {this.props.rental.stage && this.props.currentUser && this.props.hasPermissions && ((this.props.hasPermissions.includes("custom_can_cancel_agreement") && this.props.rental.stage === "A_10") || (this.props.hasPermissions.includes("custom_can_reactivate_agreement") && this.props.rental.stage === "A_50")) && <i onClick={() => this.toggleModal("UpdateAgreementInfo")} className={"fa fa-lg fa-pencil-square"}></i>}
                                                            </Label>
                                                            {this.props.rental.stage ?
                                                                <div style={{ color: "white" }}>
                                                                    <Badge style={{ backgroundColor: this.props.rental.stage === "_1" ? "red" : this.props.rental.stage === "A_10" ? "green" : "orange" }}>{this.getAgreementState(this.props.rental.stage)}</Badge>
                                                                    {this.props.rental.stage != "_1" && this.state.openModal === "UpdateAgreementInfo" &&
                                                                        <UpdateAgreementInfo agreementId={this.props.rental.id} title="Change Agreement Stage" name="stage" value={this.getAgreementState(this.props.rental.stage)} target={"agreementStage"} id="agreementStage" type="text" returnStage={this.props.rental.currentReturn && this.props.rental.currentReturn.stage}
                                                                            open={this.state.openModal === "UpdateAgreementInfo"} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchAgreementsQuery} placement={"left"}
                                                                        />
                                                                    }
                                                                    <br />
                                                                </div> : "---"
                                                            }
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <br />
                                            <Row>
                                                <Col xs={4}>
                                                    <Label>Start Date</Label>
                                                    <p>{this.props.rental &&
                                                        this.props.rental.startDate ? <DatetimeRenderer datetime={this.props.rental.startDate} /> : "--"}
                                                    </p>
                                                </Col>
                                                <Col xs={4}>
                                                    <Label>End Date</Label>
                                                    <p>{this.props.rental &&
                                                        this.props.rental.endDate ? <DatetimeRenderer datetime={this.props.rental.endDate} /> : "--"}
                                                    </p>
                                                </Col>
                                                <Col xs={4}>
                                                    <Label>Agreement Type</Label>
                                                    <p>{this.props.rental && this.props.rental.agreementType ? toTitleCase(this.props.rental.agreementType) : "--"}</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={4}>
                                                    <Label>Gas Level</Label>
                                                    <p>{this.props.rental.pickupCondition && this.props.rental.pickupCondition.gas ? this.props.rental.pickupCondition.gas : "--"}</p>
                                                </Col>
                                                <Col xs={4}>
                                                    <Label>Mileage</Label>
                                                    <p>{this.props.rental.pickupCondition && this.props.rental.pickupCondition.gas ? this.props.rental.pickupCondition.mileage : "--"}</p>
                                                </Col>
                                                <Col xs={4}>
                                                    <Label id={"agreementDeposit"}>
                                                        Deposit&nbsp;&nbsp;
                                                        {this.props.rental.stage === "A_10" && this.props.currentUser && this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_update_deposit_amount") && <i onClick={() => this.toggleModal("UpdateAgreementDeposit")} className={"fa fa-lg fa-pencil-square"}></i>}
                                                    </Label>
                                                    <p className="columnContent">{toTitleCase(this.props.rental.depositScheme)} - {`$${this.props.rental.depositAmount}`}</p>
                                                    {this.state.openModal === "UpdateAgreementDeposit" &&
                                                        <UpdateAgreementInfo
                                                            agreementId={this.props.rental.id}
                                                            title="Change Agreement Deposit"
                                                            name="deposit"
                                                            value={{ deposit: this.props.rental.deposit, depositScheme: toTitleCase(this.props.rental.depositScheme) }}
                                                            target={"agreementDeposit"}
                                                            id="agreementDeposit"
                                                            open={this.state.openModal === "UpdateAgreementDeposit"}
                                                            handleClose={() => this.toggleModal("")}
                                                            refetchQuery={this.props.refetchAgreementsQuery}
                                                            placement={"left"}
                                                        />
                                                    }
                                                </Col>
                                                <Col xs={4}>
                                                    <Label>Agreement Branch</Label>
                                                    <p className="columnContent"><Badge className='bos-badge-blue'>{this.props.rental && this.props.rental && this.props.rental.branch ? this.props.rental.branch.name : "No Agreement Branch"}</Badge> </p>
                                                </Col>
                                                <Col xs={4}>
                                                    <Label>
                                                        Insurance Surcharge&nbsp;&nbsp;
                                                        {this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_edit_insurance_surcharge") &&
                                                            <i id={"updateSurcharge"} onClick={() => this.toggleModal("updateSurcharge")} className={"fa fa-pencil-square-o"}></i>}&nbsp;&nbsp;
                                                    </Label>
                                                    {this.state.openModal === "updateSurcharge" &&
                                                        <UpdateAgreementInfo
                                                            agreementId={this.props.rental.id}
                                                            title="Update Agreement Surcharge"
                                                            name="insuranceUpcharge"
                                                            value={this.props.rental.insuranceUpcharge}
                                                            target={"updateSurcharge"}
                                                            id="updateSurcharge"
                                                            type="text"
                                                            open={this.state.openModal === "updateSurcharge"}
                                                            handleClose={() => this.toggleModal("")}
                                                            refetchQuery={this.props.refetchAgreementsQuery}
                                                            placement={"top"}
                                                        />
                                                    }
                                                    <p className="columnContent">
                                                        {this.props.rental.insuranceUpcharge ? this.props.rental.insuranceUpcharge : 0}
                                                    </p>
                                                </Col>
                                                <Col xs={4}>
                                                    <Label>Action</Label>
                                                    <p>
                                                        {this.props.rental && this.props.rental.pickupCondition && this.props.rental.pickupCondition.carpicturesSet &&
                                                            this.props.rental.pickupCondition.carpicturesSet.edges && this.props.rental.pickupCondition.carpicturesSet.edges.length > 0 &&
                                                            <a href="#" onClick={() => this.viewImages(this.props.rental.pickupCondition)}>PICKUP PICTURES</a>
                                                        }
                                                        &nbsp;&nbsp;
                                                        {this.props.rental.pickupCondition && this.props.rental.pickupCondition.formData && pObj(this.props.rental.pickupCondition.formData) && 'event_url' in pObj(this.props.rental.pickupCondition.formData) &&
                                                            <a target="_blank" href={pObj(this.props.rental.pickupCondition.formData)["event_url"]}>PICKUP FORM</a>
                                                        }
                                                    </p>
                                                </Col>
                                                <Col xs={4}>
                                                    <Label >
                                                        Contract Status&nbsp;&nbsp;&nbsp;&nbsp;
                                                        {this.props.rental && !this.props.rental.isContractSigned && this.props.rental.stage === "A_10" &&
                                                            <>
                                                                <i id={"uploadContract"} className="fa fa-upload fa-lg" aria-hidden="true" onClick={() => this.toggleModal("uploadContract")} ></i>
                                                                <UncontrolledTooltip target={"uploadContract"} placement="top">Signed contract missing. Click here to upload signed contract.</UncontrolledTooltip>
                                                                {this.state.openModal === "uploadContract" &&
                                                                    <UploadDocumentModal
                                                                        handleClose={() => this.toggleModal("uploadContract")}
                                                                        open={this.state.openModal === "uploadContract"}
                                                                        objectType={"driver"}
                                                                        id={this.props.rental && this.props.rental.driver && this.props.rental.driver.id}
                                                                        agreementId={this.props.rental.id}
                                                                        refetchQuery={this.props.refetchAgreementsQuery ? this.props.refetchAgreementsQuery : () => { return true }}
                                                                    />
                                                                }
                                                            </>
                                                        }
                                                    </Label>
                                                    <p style={{ color: this.props.rental && this.props.rental.isContractSigned ? "green" : "orange" }}>
                                                        {this.props.rental && this.props.rental.isContractSigned ? "Signed" : "Not Signed"}
                                                    </p>
                                                </Col>
                                                <Col xs={8}>
                                                    <Label>Tags</Label>
                                                    <p>
                                                        {this.props.rental && this.props.rental.tags && this.props.rental.tags.map((tag, i) => (
                                                            <Badge pill key={i} size="sm" style={{ background: `#${tag.color ? tag.color : 'black'}` }}>{tag.name}</Badge>
                                                        ))}&nbsp;
                                                    </p>
                                                </Col>
                                                <Col xs={12}>
                                                    <br />
                                                    <Label id={"agreementNotesEdit" + this.props.rental.pk}>
                                                        Notes&nbsp;&nbsp;
                                                        <i onClick={() => this.toggleModal("agreementNotesEdit" + this.props.rental.pk)} className={"fa fa-pencil-square-o"}></i>&nbsp;&nbsp;
                                                        {this.state.openModal === "agreementNotesEdit" + this.props.rental.pk &&
                                                            <UpdateAgreementInfo
                                                                agreementId={this.props.rental.id}
                                                                title="Update Notes"
                                                                name="notes"
                                                                value={this.props.rental.notes}
                                                                target={"agreementNotesEdit" + this.props.rental.pk}
                                                                id={"agreementNotesEdit" + this.props.rental.pk}
                                                                type="text"
                                                                open={this.state.openModal === "agreementNotesEdit" + this.props.rental.pk}
                                                                handleClose={() => this.toggleModal("")}
                                                                refetchQuery={this.props.refetchAgreementsQuery}
                                                                placement={"top"}
                                                            />
                                                        }
                                                    </Label>
                                                    <p>
                                                        {this.props.rental.notes ? this.props.rental.notes : "No Rental Notes Found"}
                                                    </p>
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                    {this.state.rentalDetailSubsection === "Rental History" &&
                                        <div className="info-container" style={{ borderTopLeftRadius: "0px", maxHeight: "300px", overflow: "auto" }}>
                                            <Row>
                                                {HISTORY_TABLE_HEADER.map(tableHeader =>
                                                    <Col key={tableHeader.id} xs={4}><Label >{tableHeader.name}</Label></Col>
                                                )}
                                            </Row>
                                            {this.props.rental.history.length > 0 ? this.props.rental.history.map(history =>
                                                <Row>
                                                    <Col><p>{history.date ? <DatetimeRenderer datetime={history.date} /> : "--"}</p></Col>
                                                    <Col><p>{history.comment ? history.comment : "--"}</p></Col>
                                                    <Col><p>{history.user ? history.user : "--"}</p></Col>
                                                </Row>) :
                                                <Row>
                                                    <Col>
                                                        <p>No Rental History</p>
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                    }
                                    {this.state.rentalDetailSubsection === "Rental Pricing" &&
                                        <div className="info-container" style={{ borderTopLeftRadius: "0px" }}>
                                            <Row>
                                                {<NewAgreementPrice handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchAgreementsQuery} rental={this.props.rental} open={this.state.openModal === "NewAgreementPrice"} />}
                                                {this.props.rental && this.props.rental.stage === "A_10" &&
                                                    <Col className="d-flex justify-content-end">
                                                        <Button size="sm" onClick={() => this.toggleModal("NewAgreementPrice")} className="primary">Add/Change Plan</Button>
                                                        <p></p>
                                                    </Col>
                                                }
                                            </Row>
                                            <Row>
                                                {this.props.rental && this.props.rental.agreementpriceSet && this.props.rental.agreementpriceSet.edges && this.props.rental.agreementpriceSet.edges.length > 0 && PRICING_TABLE_HEADER.map(tableHeader =>
                                                    <Col key={tableHeader.id}>
                                                        <Label>{tableHeader.name}</Label>
                                                    </Col>
                                                )}
                                            </Row>
                                            {this.props.rental && this.props.rental.agreementpriceSet && this.props.rental.agreementpriceSet.edges && this.props.rental.agreementpriceSet.edges.length > 0 ? this.props.rental.agreementpriceSet.edges.map(plan =>
                                                <Row className={plan.node.isActive ? "activePrice" : ""} style={{ background: "#3e4c6d", opacity: plan.node.isActive ? 1 : 0.8, color: "white", padding: "5px", borderRadius: "5px", justifyContent: "center", alignItems: "center" }}>
                                                    <Col>${plan.node.price} per {plan.node.interval > 1 ? plan.node.interval : ""}{plan.node.intervalUnit}</Col>
                                                    <Col>{plan.node.startDate ? <DatetimeRenderer datetime={plan.node.startDate} format={"ddd, MMM Do"} /> : "--"}</Col>
                                                    <Col>{plan.node.isActive ? "ACTIVE" : "IN-ACTIVE"}</Col>
                                                    <Col>{this.props.rental.representative.username ? this.props.rental.representative.username : "--"}</Col>
                                                </Row>) : <Row><Col xs={12}><p>No Rental Price Found</p></Col></Row>
                                            }
                                            <br />
                                            {this.props.rental && this.props.rental.agreementpromoSet && this.props.rental.agreementpromoSet.edges && this.props.rental.agreementpromoSet.edges.length > 0 ? this.props.rental.agreementpromoSet.edges.map(promo =>
                                                <Row style={{ backgroundColor: "green", color: "white", padding: "5px", borderRadius: "5px", justifyContent: "center", alignItems: "center", opacity: promo.node.isCurrentlyAcive ? 1 : 0.8 }}>
                                                    <Col><i className="fa fa-tags" aria-hidden="true" />&nbsp;&nbsp;{!promo.node.isPercentage ? "$" : ""}{promo.node.promoPrice}{promo.node.isPercentage ? "%" : ""} per {promo.node.interval > 1 ? promo.node.interval : ""}{promo.node.intervalUnit}</Col>
                                                    <Col>{promo.node.startDate ? <DatetimeRenderer datetime={promo.node.startDate} /> : "--"}</Col>
                                                    <Col>{promo.node.isCurrentlyAcive ? "ACTIVE" : "IN-ACTIVE"}</Col>
                                                    <Col>{promo.node.createdBy.username ? promo.node.createdBy.username : "--"}</Col>
                                                </Row>) : <Row><Col xs={12}><p>No Promo Found</p></Col></Row>
                                            }
                                        </div>

                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Nav tabs>
                                        <NavItem className={this.state.tab === "Return Info" ? "active" : "inactive"}>
                                            <NavLink className={this.state.returnDetailSubsection === "Return Info" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("returnDetailSubsection", "Return Info")}>
                                                RETURN INFO
                                            </NavLink>
                                        </NavItem>
                                        <NavItem className={this.state.tab === "Return History" ? "active" : "inactive"}>
                                            <NavLink className={this.state.returnDetailSubsection === "Return History" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("returnDetailSubsection", "Return History")}>
                                                RETURN HISTORY
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    {this.state.returnDetailSubsection === "Return Info" &&
                                        <div className="info-container" style={{ borderTopLeftRadius: "0px" }}>
                                            {this.props.rental.currentReturn ?
                                                this.renderCarReturn(this.props.rental.currentReturn, this.props.rental) :
                                                this.getAgreementState(this.props.rental.stage) && this.getAgreementState(this.props.rental.stage).includes("Active") ?
                                                    <Row className="cardRow" style={{ margin: "0px" }}>
                                                        {this.props.rental &&
                                                            <Col xs={12}>
                                                                {this.state.openModal === "ScheduleReturnForm" + this.props.rental.id && <ScheduleReturnForm refetchQuery={this.props.refetchAgreementsQuery} handleClose={() => this.toggleModal("")} rental={this.props.rental} open={this.state.openModal === "ScheduleReturnForm" + this.props.rental.id} editReturn={false} currentUser={this.props.currentUser ? this.props.currentUser : ""} />}
                                                                <Button className="primary" onClick={() => this.toggleModal("ScheduleReturnForm" + this.props.rental.id)}>Schedule Return</Button>&nbsp;&nbsp;
                                                            </Col>
                                                        }
                                                    </Row> :
                                                    <Row>
                                                        <Col xs={12}>
                                                            <p className="columnContent">No Return Information Available.</p>
                                                        </Col>
                                                    </Row>
                                            }
                                        </div>
                                    }
                                    {this.state.returnDetailSubsection === "Return History" &&
                                        <div className="info-container" style={{ borderTopLeftRadius: "0px" }}>
                                            <Row>
                                                <Col><Label>Date Added</Label></Col>
                                                <Col><Label>Return Category</Label></Col>
                                                <Col><Label>Driver Cancel Reason</Label></Col>
                                                <Col><Label>Cancelled Date</Label></Col>
                                                <Col><Label>Scheduled Date</Label></Col>
                                                <Col><Label>Auto Deposit Refund</Label></Col>
                                                <Col><Label>Rep</Label></Col>
                                            </Row>
                                            {
                                                this.props.rental.carreturnSet &&
                                                    this.props.rental.carreturnSet.edges &&
                                                    this.props.rental.carreturnSet.edges.length > 0 &&
                                                    this.props.rental.carreturnSet.edges.length > 0 ?
                                                    this.props.rental.carreturnSet.edges.filter(item => !this.props.rental.currentReturn || item.node.id !== this.props.rental.currentReturn.id).reverse().map(item =>
                                                        <Row key={item.node.id}>
                                                            <Col>
                                                                <p>{item.node.dateAdded ? <DatetimeRenderer datetime={item.node.dateAdded} /> : "--"} {item.node.isDeleted && <Badge color="danger">Deleted</Badge>}</p>
                                                            </Col>
                                                            <Col>
                                                                <p>{toTitleCase(item.node.returnCategory)}</p>
                                                            </Col>
                                                            <Col>
                                                                <p>{item.node.returnReason}</p>
                                                            </Col>
                                                            <Col>
                                                                <p>{item.node.cancelledDate ? <DatetimeRenderer datetime={item.node.cancelledDate} /> : "--"}</p>
                                                            </Col>
                                                            <Col>
                                                                <p>{item.node.scheduledDate ? <DatetimeRenderer datetime={item.node.scheduledDate} /> : "--"}</p>
                                                            </Col>
                                                            <Col>
                                                                <p className="columnContent" style={{ color: item.node.autoDepositRefund ? "green" : "red" }}>{item.node.autoDepositRefund ? "YES" : "NO"}</p>
                                                            </Col>
                                                            <Col>
                                                                <p>{item.node.representative ? item.node.representative.username : "--"}</p>
                                                            </Col>
                                                        </Row>
                                                    ) :
                                                    <Row><Col><p> No returns history found! </p></Col></Row>}
                                        </div>
                                    }
                                </Col>
                            </Row>
                            <br />
                            <Row >
                                <Col>
                                    <Nav tabs>
                                        <NavItem className={this.state.tab === "Transactions History" ? "active" : "inactive"}>
                                            <NavLink className={this.state.agreementDetailSubsection === "Transactions History" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("agreementDetailSubsection", "Transactions History")}>
                                                TRANSACTIONS HISTORY
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <div className="info-container" style={{ borderTopLeftRadius: "0px", maxHeight: "300px", overflow: "auto" }}>
                                        {this.state.agreementDetailSubsection === "Transactions History" &&
                                            <>
                                                <Row style={{ position: "sticky", top: "-1" }}>
                                                    {RENTAL_TRANSACTIONS_HEADER.map(tableHeader => {
                                                        return (
                                                            tableHeader.sortable ?
                                                                <Col>
                                                                    <Label key={tableHeader.id} onClick={() => this.updateOrder(tableHeader.id)} style={{ cursor: "pointer" }}>{tableHeader.name}</Label>
                                                                </Col> :
                                                                <Col>
                                                                    <Label key={tableHeader.id}>{tableHeader.name}</Label>
                                                                </Col>
                                                        )
                                                    })
                                                    }
                                                </Row>

                                                {
                                                    this.props.rental &&
                                                        this.props.rental.transactionSet &&
                                                        this.props.rental.transactionSet.edges &&
                                                        this.props.rental.transactionSet.edges.length > 0 ?
                                                        this.props.rental.transactionSet.edges.map((transaction, i) =>
                                                            <Row key={i + 1}>
                                                                <Col>
                                                                    <p>
                                                                        {transaction.node.pk}
                                                                        {transaction.node.updateFor && <><br /><small style={{ opacity: 0.9, fontSize: "0.9rem", textDecoration: "line-through" }}>{transaction.node.updateFor.pk}</small></>}
                                                                    </p>
                                                                </Col>
                                                                <Col><p>{transaction.node.dateAdded ? <DatetimeRenderer datetime={transaction.node.dateAdded} /> : "--"}</p></Col>
                                                                <Col className={`shortText ${transaction.node.amount > 0 ? "danger" : "success"}`}>
                                                                    <p>
                                                                        {transaction.node.chargeType ? transaction.node.chargeType.name : '--'} <small>{transaction.node.groupType ? " (" + transaction.node.groupType.name + ")" : ''}</small>
                                                                        {transaction.node.notes &&
                                                                            <>
                                                                                <UncontrolledTooltip placement="top" target={"notes" + i}>{transaction.node.notes}</UncontrolledTooltip>
                                                                                <br /><small style={{ opacity: 0.9, fontSize: "0.9rem", cursor: "pointer" }} id={"notes" + i}>{transaction.node.notes}</small>
                                                                            </>
                                                                        }
                                                                    </p>
                                                                </Col>
                                                                <Col className={transaction.node.amount > 0 ? "danger" : "success"}>
                                                                    <p>
                                                                        {transaction.node.amount}
                                                                        {transaction.node.status === "0" && <Badge style={{ "fontSize": "xx-small" }}>PENDING</Badge>}
                                                                    </p>
                                                                </Col>
                                                                <Col><p>{transaction.node.eventDatetime ? <DatetimeRenderer datetime={transaction.node.eventDatetime} /> : "--"}</p></Col>
                                                                <Col><p>{transaction.node.createdBy ? transaction.node.createdBy.username : "System"}</p></Col>
                                                            </Row>) :
                                                        this.props.loading ?
                                                            <Row>
                                                                <Col>&nbsp;&nbsp;&nbsp;Loading Transactions List...</Col>
                                                            </Row> :
                                                            <Row>
                                                                <Col>&nbsp;&nbsp;&nbsp;No Transaction Found</Col>
                                                            </Row>
                                                }
                                            </>
                                        }
                                    </div>
                                </Col>
                            </Row>


                        </div >}
                    </>}
                </Col >
            </>
        )
    }
}

export default compose(
    withApollo,
    graphql(EnumQuery, {
        props({ data: { agreementStates, carReturnStates } }) {
            return { agreementStates, carReturnStates }
        }
    }),
    graphql(AgreementDetailQuery, {
        options: ({ id }) => ({ variables: { id: id }, fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true, }),
        props({ data: { loading, rental, fetchMore, variables } }) {
            return {
                loading,
                rental,
                variables,
                refetchAgreementsQuery: () => {
                    return fetchMore({
                        query: AgreementDetailQuery,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return {
                                rental: fetchMoreResult.rental,
                                __typename: previousResult.rental.__typename
                            }
                        },
                    })
                }
            }
        },

    }),
    graphql(HasPermissionsQuery, {
        options: ({ }) => ({ variables: { userPermissions: ["custom_can_cancel_agreement", "custom_can_reactivate_agreement", "custom_can_update_deposit_amount", "custom_can_edit_insurance_surcharge"] }, fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true, }),
        props({ data: { hasPermissions, loading, variables } }) {
            return {
                hasPermissions, loading, variables,
            }
        }
    }),
)(DriverRentalDetail)
