import React, { useState } from "react"
import { graphql, withApollo, compose } from "react-apollo"
import {
    Button,
    Form,
    FormGroup,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
} from 'reactstrap'
import { AllAvailableModelsQuery, TriggerTypes, AllActionsQuery, TriggerQuery } from "./Queries"
import Select from "react-select"
import { CreateTriggerMutation, UpdateTriggerMutation } from "./Mutations"

const TriggerForm = ({ trigger, allAvailableModels, triggerTypes, handleClose, isOpen, isUpdate, createTrigger, updateTrigger, triggerId, automationId }) => {

    const [triggerType, setTriggerType] = useState('')
    const [columnsUpdated, setColumnsUpdated] = useState([])

    const [error, setError] = useState(null)
    const [availableModel, setAvailableModel] = useState("")

    const [errors, setErrors] = useState({})
    // const [openModal, setOpenModal] = useState("")

    const validateForm = () => {
        const newErrors = {}
        if (!triggerType) newErrors.triggerType = "Trigger Type is required."
        if (!availableModel) newErrors.availableModel = "Model is required."
        if (triggerType === 'ON_UPDATE' && columnsUpdated.length === 0) newErrors.columnsUpdated = "Columns to be updated are required."
        setErrors(newErrors)
        return Object.keys(newErrors).length === 0
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (validateForm()) {
            // if (isUpdate) {
            //     updateTrigger({
            //         variables: {
            //             input: {
            //                 id: triggerId,
            //                 triggerType: trigger.triggerType,
            //                 columnsUpdated: trigger.columnsUpdated,
            //                 contentTypeId: availableModel.id,
            //             },
            //         },
            //     }).then(({ data }) => {
            //         if (data.updateTrigger.ok) {
            //             handleClose()
            //         } else {
            //             setError(data.updateTrigger.errors[0].messages[0])
            //         }
            //     })
            // } else {
            createTrigger({
                variables: {
                    input: {
                        automationId,
                        triggerType: triggerType,
                        columnsUpdated: columnsUpdated,
                        contentTypeId: availableModel.id,
                    }
                }
            }).then(({ data }) => {
                if (data.createTrigger.ok) {
                    handleClose()
                } else {
                    setError(data.createTrigger.errors[0].messages[0])
                }
            })
        }
    }

    return (
        <Modal isOpen={isOpen} toggle={handleClose}>
            <ModalHeader toggle={handleClose}>Action Detail</ModalHeader>
            <ModalBody>
                {error && <div className="alert alert-danger">{error}</div>}
                <Form className="ActionForm" onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label>Model to trigger from</Label>
                        <br />
                        <Select className="bos-custom-select"
                            classNamePrefix="bos-select" isLoading={false}
                            options={
                                allAvailableModels && allAvailableModels.edges.map(({ node }) => ({
                                    label: `${node.contentType.appLabel.toUpperCase()} - ${node.contentType.model.toUpperCase()}`,
                                    value: node,
                                }))}
                            defaultValue={trigger && trigger.contentType ? {
                                label: `${trigger.contentType.model.toUpperCase()}`,
                                value: trigger.contentType,
                            } : null}
                            isDisabled={isUpdate}
                            placeholder="-- Select model --"
                            onChange={(option) => setAvailableModel(option.value)}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="triggerType">Trigger Type</Label>
                        <Select className="bos-custom-select"
                            classNamePrefix="bos-select" isLoading={false}
                            options={
                                triggerTypes && triggerTypes.states.map((item) => ({
                                    label: item.name ? item.name.replaceAll('_', ' ').toUpperCase() : '',
                                    value: item.name,
                                }))
                            }
                            defaultValue={trigger && trigger.triggerType ? {
                                label: trigger.triggerType.replaceAll('_', ' ').toUpperCase(),
                                value: trigger.triggerType,
                            } : null}
                            placeholder="-- Select trigger type --"
                            onChange={(option) => setTriggerType(option.value)}
                        />
                    </FormGroup>
                    {triggerType === 'ON_UPDATE' && (
                        <FormGroup>
                            <Label for="columnsUpdated">Updated Columns</Label>
                            <Select
                                className="bos-custom-select"
                                classNamePrefix="bos-select"
                                isLoading={false}
                                isMulti
                                options={
                                    availableModel ? availableModel.availableModelFields.map((field) => ({
                                        label: field ? field.replaceAll('_', ' ').toUpperCase() : '',
                                        value: field,
                                    })) : []
                                }
                                placeholder="Select columns"
                                onChange={(options) => setColumnsUpdated(options.map((option) => option.value))}
                            />
                        </FormGroup>
                    )}
                    <Button type="submit" className="float-right">Submit</Button>
                </Form>

            </ModalBody>
        </Modal>
    )
}

export default compose(
    withApollo,
    graphql(AllAvailableModelsQuery, {
        options: () => ({
            variables: {},
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-and-network'
        }),
        props({ data: { loading, allAvailableModels } }) {
            return {
                loading,
                allAvailableModels,
            }
        },
    }),
    graphql(TriggerQuery, {
        options: ({ triggerId }) => ({ variables: { id: triggerId } }),
        props({ data: { trigger, loading, refetch, variables } }) {
            return {
                trigger, loading, variables,
                refetchQuery: () => {
                    return refetch({
                        query: TriggerQuery,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { trigger: fetchMoreResult.trigger }
                        },
                    })
                },
            }
        }
    }),
    graphql(TriggerTypes, {
        options: () => ({ variables: {}, notifyOnNetworkStatusChange: true, fetchPolicy: 'cache-and-network' }),
        props({ data: { loading, triggerTypes } }) {
            return { loading, triggerTypes }
        },
    }),
    graphql(AllActionsQuery, {
        options: ({ automationId }) => ({ variables: { automation: automationId }, notifyOnNetworkStatusChange: true, fetchPolicy: 'cache-and-network' }),
        props({ data: { loading, allActions } }) {
            return { loading, allActions }
        },
    }),
    graphql(CreateTriggerMutation, { name: 'createTrigger' }),
    graphql(UpdateTriggerMutation, { name: 'updateTrigger' }),
)(TriggerForm)
