import React, { useState } from 'react'
import { compose, graphql } from 'react-apollo'
import { withApollo } from 'react-apollo/index'
import { Route, Switch, Link } from 'react-router-dom'
import { Button, Popover, PopoverHeader, PopoverBody, Table, UncontrolledTooltip, Badge, Dropdown } from 'reactstrap'
import "react-awesome-lightbox/build/style.css"
import "../Material/ListingHeader.css"
import "./TaskList.css"
import PrimaryListingContainer from "../Material/PrimaryListingContainer"
import { func } from "prop-types"
import StatusDropDown, { TooltipComponent } from "./Components"
import { AllTasksQuery } from "./Queries"
import EditTaskPopOver from "./EditTaskPopOver"

function TaskList({ searchTerm, loadMoreEntries, allTasks, statuses, ...props }) {
    const [loading, setLoading] = useState(false)
    const [tooltipTarget, setTooltipTarget] = useState("")
    const [openModal, setOpenModal] = useState("")
    const [activeTd, setActiveTd] = useState("")
    const [selectAll, setSelectAll] = useState(false)
    const [selectedTask, setSelectedTask] = useState(null)
    const taskHeaders = [
        { title: 'Title', key: 'title' },
        { title: 'Status', key: 'status' },
        { title: 'Assigned To', key: 'assignedTo' },
        { title: 'Due Date', key: 'dueDate' },
        { title: 'Driver', key: 'driver' },
        { title: 'Vehicle', key: 'vehicle' },
    ]


    return (
        <div className="TaskList" >
            {selectedTask && openModal === "titleEdit" && <TooltipComponent tooltipOpen={openModal === "titleEdit"} target={`titleEdit${selectedTask.pk}`} message={selectedTask.title} toggle={() => setOpenModal("")} />}
            {selectedTask && openModal === "AssignedToPopover" &&
                <Popover placement="bottom" isOpen={selectedTask && openModal === "AssignedToPopover"} target={`AssignedToPopover${selectedTask.pk}`} toggle={() => setOpenModal("")}>
                    <PopoverHeader className="text-center">Assigned To</PopoverHeader>
                    <PopoverBody>
                        {selectedTask.assignedTo && selectedTask.assignedTo.edges && selectedTask.assignedTo.edges.map((rep, index) => (
                            <Badge key={index} color="secondary" className="mr-1" pill={true}>{rep.node.username}</Badge>
                        ))}
                    </PopoverBody>
                </Popover>
            }
            <PrimaryListingContainer listLoading={loading} totalFetchedRows={allTasks && allTasks.edges.length} pageInfo={allTasks && allTasks.pageInfo} loadMoreEntries={allTasks && allTasks.pageInfo && allTasks.pageInfo.hasNextPage ? loadMoreEntries : null} refetchListQuery={null}>
                <Table responsive>
                    <thead>
                        <tr>
                            <th className="select-all-checkbox"> {selectAll ? <i className="fa fa-check-square-o" aria-hidden="true"></i> : <i className="fa fa-square-o" aria-hidden="true"></i>}</th>
                            {taskHeaders.map((header, index) => (
                                <th key={index}>{header.title}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {allTasks && allTasks.edges && allTasks.edges.length === 0 && <tr><td colSpan={taskHeaders.length + 1} className="text-center">No tasks found</td></tr>}

                        {allTasks && allTasks.edges && allTasks.edges.map((task, index) => (
                            <tr key={index}>
                                <td scope="row" className="listing-checkbox-wrapper">
                                    {<strong className="listing-counter">{index + 1}</strong>}
                                </td>
                                <td className="shortText task-cell title" id={`titleEdit${task.node.pk}`} onMouseEnter={() => setActiveTd(`title${task.node.pk}`)}>
                                    <i onClick={() => setOpenModal(`titleEdit${task.node.pk}`)} className={"fa fa-pencil-square-o"}></i>&nbsp;&nbsp;
                                    <Link to={`${task.node.id}`}>{task.node.title}</Link>
                                    {openModal === `titleEdit${task.node.pk}` &&
                                        <EditTaskPopOver
                                            isOpen={openModal === `titleEdit${task.node.pk}`}
                                            task={task.node}
                                            closeModal={() => setOpenModal("")}
                                            target={`titleEdit${task.node.pk}`}
                                            title="Edit Task Title"
                                            name="title"
                                            value={task.node.title}
                                            type="string"
                                            maxLength={255}
                                        />
                                    }
                                </td>
                                <td onMouseEnter={() => setActiveTd(`status${task.node.pk}`)}>
                                    <StatusDropDown taskStatus={task.node.status} taskId={task.node.id} client={props.client} />
                                </td>
                                <td className="task-cell" onMouseEnter={() => setActiveTd(`assignedTo${task.node.pk}`)} id={`assignedToEdit${task.node.pk}`} >
                                    {task.node.assignedTo && task.node.assignedTo.edges && task.node.assignedTo.edges.length > 0 && task.node.assignedTo.edges.slice(0, 3).map((rep, index) => (
                                        <Badge key={index} color="secondary" className="mr-1 text-sm" pill={true}>{rep.node.username}</Badge>
                                    ))}
                                    {task.node.assignedTo && task.node.assignedTo.edges && task.node.assignedTo.edges.length > 3 &&
                                        <Badge
                                            id={`AssignedToPopover${task.node.pk}`}
                                            color="secondary" size="md" pill={true} className="mr-1"
                                            onClick={() => { openModal === "AssignedToPopover" ? setOpenModal("") && setSelectedTask(null) : setOpenModal("AssignedToPopover"); setSelectedTask(task.node) }}
                                        >...</Badge>}
                                    {activeTd === `assignedTo${task.node.pk}` && <span>&nbsp;&nbsp;&nbsp;&nbsp;<i onClick={() => setOpenModal(`assignedToEdit${task.node.pk}`)} className={"fa fa-pencil-square-o"}></i></span>}
                                    {openModal === `assignedToEdit${task.node.pk}` &&
                                        <EditTaskPopOver
                                            isOpen={openModal === `assignedToEdit${task.node.pk}`}
                                            task={task.node}
                                            closeModal={() => setOpenModal("")}
                                            target={`assignedToEdit${task.node.pk}`}
                                            title="Edit Assigned To"
                                            name="assignedToIds"
                                            value={task.node.assignedTo && task.node.assignedTo.edges && task.node.assignedTo.edges.length > 0 ?
                                                task.node.assignedTo.edges.map(rep =>
                                                    ({ value: rep.node.id, label: `${rep.node.username}` })
                                                ) : []}
                                            type="string"
                                        />
                                    }


                                </td>
                                <td className="task-cell" onMouseEnter={() => setActiveTd(`dueDate${task.node.pk}`)} id={`dueDateEdit${task.node.pk}`}>
                                    {task.node.dueDate}
                                    {activeTd === `dueDate${task.node.pk}` && <span>&nbsp;&nbsp;&nbsp;&nbsp;<i onClick={() => setOpenModal(`dueDateEdit${task.node.pk}`)} className={"fa fa-pencil-square-o"}></i></span>}
                                    {openModal === `dueDateEdit${task.node.pk}` &&
                                        <EditTaskPopOver
                                            task={task.node}
                                            isOpen={openModal === `dueDateEdit${task.node.pk}`}
                                            closeModal={() => setOpenModal("")}
                                            target={`dueDateEdit${task.node.pk}`}
                                            title="Edit Due Date"
                                            name="dueDate"
                                            value={task.node.dueDate}
                                            type="date"
                                        />
                                    }
                                </td>
                                <td className="task-cell" onMouseEnter={() => setActiveTd(`driver${task.node.pk}`)} id={`driverEdit${task.node.pk}`} >
                                    {task.node.driver ? <span><Link color="primary" to={'/driver/' + task.node.driver.id} target="_blank"><i className={"fa fa-external-link-square"}></i></Link> {task.node.driver.name}</span> : ''}
                                    {activeTd === `driver${task.node.pk}` && <span>&nbsp;&nbsp;<i onClick={() => setOpenModal(`driverEdit${task.node.pk}`)} className={"fa fa-pencil-square-o"}></i></span>}
                                    {openModal === `driverEdit${task.node.pk}` &&
                                        <EditTaskPopOver
                                            task={task.node}
                                            isOpen={openModal === `driverEdit${task.node.pk}`}
                                            closeModal={() => setOpenModal("")}
                                            target={`driverEdit${task.node.pk}`}
                                            title="Edit Driver"
                                            name="driverId"
                                            value={task.node.driver ? { value: task.node.driver.id, label: `${task.node.driver.name}` } : {}}
                                            type="string"
                                        />
                                    }
                                </td>
                                <td className="task-cell" onMouseEnter={() => setActiveTd(`vehicle${task.node.pk}`)} id={`vehicleEdit${task.node.pk}`} >
                                    {task.node.vehicle ? <span><Link color="primary" to={'/car/' + task.node.vehicle.id} target="_blank"><i className={"fa fa-external-link-square"}></i></Link> {task.node.vehicle.year} {task.node.vehicle.make} {task.node.vehicle.model} </span> : ''}
                                    {activeTd === `vehicle${task.node.pk}` && <span>&nbsp;&nbsp;<i onClick={() => setOpenModal(`vehicleEdit${task.node.pk}`)} className={"fa fa-pencil-square-o"}></i></span>}
                                    {openModal === `vehicleEdit${task.node.pk}` &&
                                        <EditTaskPopOver
                                            task={task.node}
                                            isOpen={openModal === `vehicleEdit${task.node.pk}`}
                                            closeModal={() => setOpenModal("")}
                                            target={`vehicleEdit${task.node.pk}`}
                                            title="Edit Vehicle"
                                            name="vehicleId"
                                            value={task.node.vehicle ? { value: task.node.vehicle.id, label: `${task.node.vehicle.vin}` } : {}}
                                            type="string"
                                        />
                                    }
                                </td>
                            </tr>
                        ))}

                    </tbody >
                </Table >
            </PrimaryListingContainer >
        </div >
    )
}

export default compose(
    withApollo,
    graphql(AllTasksQuery, {
        options: ({ match, searchTerm, statuses, orderBy, assignedToIds, isArchived }) => ({
            variables: {
                boardIds: match && match.params.boardId ? [match.params.boardId] : null,
                searchTerm,
                statuses,
                orderBy,
                assignedToIds,
                isArchived,
                first: 30
            },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'cache-and-network'
        }),
        props({ data: { loading, allTasks, fetchMore, searchTerm, variables, refetch } }) {
            return {
                loading,
                allTasks,
                variables,
                refetch,
                refetchDriversQuery: () => {
                    //No apollo function that refetches in place with pagination considered so this function instead
                    let currentLength = allTasks && allTasks.edges ? allTasks.edges.length : 30
                    return fetchMore({
                        query: AllTasksQuery,
                        variables: {
                            ...variables,
                            first: currentLength,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allTasks.edges
                            const pageInfo = fetchMoreResult.allTasks.pageInfo
                            return {
                                allTasks: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.allTasks.__typename
                                },
                            }
                        },
                    })
                },
                loadMoreEntries: () => {
                    return fetchMore({
                        query: AllTasksQuery,
                        variables: {
                            cursor: allTasks.pageInfo.endCursor,
                            ...variables
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.allTasks.edges
                            const pageInfo = fetchMoreResult.allTasks.pageInfo

                            return {
                                // Put the new comments at the end of the list and update `pageInfo`
                                // so we have the new `endCursor` and `hasNextPage` values
                                allTasks: {
                                    edges: [...previousResult.allTasks.edges, ...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.allTasks.__typename
                                },
                            }
                        },
                    })
                },
            }
        },
    })
)(TaskList)

