import React, { Component } from 'react'
import { compose, graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { withApollo } from 'react-apollo/index'
import { Col, Row, Input, Popover, PopoverBody, PopoverHeader, Label, Progress, Button } from 'reactstrap'
import ListingFilters from "../../Material/ListingFilters"
import { debounce } from 'lodash'
import '../../Drivers/DriversNew/DriversListContainer.css'
import renderEnumToInt from '../../Functions/renderEnumToInt'
import toTitleCase from '../../Functions/toTitleCase'
import StripeModal from "../../Stripe/StripeModal"
import NewTransaction from './NewTransaction'
import DownloadTransactions from "./DownloadTransactions"
import NewPaymentPlan from './NewPaymentPlan'
import NewPaymentSchedule from "./NewPaymentSchedule"
import { HasPermissionsQuery } from "../../Functions/querys"
import handleConfigurationChange from "../../Functions/handleConfigurationChange"
import getFilterValuesFromQueryString from "../../Functions/getFilterValuesFromQueryString"
import TablePreferencePicker from "../../Material/TablePreferencePicker"
import PaymentScheduleModal from "./PaymentScheduleModal"


const AllChargeTypes = gql`query AllChargeTypes{
    optionsList: allChargeTypes(isActive:true,orderBy:["name"]){
        edges {
          node {
            id
            name
            category
          }
        }
      }
}`

const AllGroupTypes = gql`query AllGroupTypes{
    optionsList: allGroupTypes(isActive:true,orderBy:["name"]){
      edges {
        node {
          id
          name
        }
      }
    }
}`

class TransactionListFilters extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            configurations: null,
            loading: false
        }
    }
    getFilterConfigurations = () => {
        return [
            { type: "number", name: "minAmount", title: "Min Amount", placeholder: "0", showFilter: true },
            { type: "number", name: "maxAmount", title: "Max Amount", placeholder: "10000", showFilter: true },
            {
                type: "select", name: "chargeTypeIds", title: "Charge Types", optionsQuery: AllChargeTypes, labelSelector: "name", valueSelector: "id", placeholder: "Filter By Charge Types", showFilter: true, isMulti: true,
            },
            {
                type: "select", name: "groupTypeIds", title: "Sub-Charge Types", optionsQuery: AllGroupTypes, labelSelector: "name", valueSelector: "id", placeholder: "Filter by Sub-Charge Type", showFilter: true, isMulti: true,
            },
            {
                type: "boolean", name: "includeUpdates", title: "Show Updates", optionOne: "YES", optionTwo: "NO", placeholder: "Filter By Include Updates", showFilter: true
            },
            {
                type: "boolean", name: "includeDeleted", title: "Include Deleted", optionOne: "YES", optionTwo: "NO", placeholder: "Filter By Include Deleted", showFilter: true
            },
            {
                type: "date", name: "startDate", title: "Start Date", placeholder: "Filter by Start Date", showFilter: true
            },
            {
                type: "date", name: "endDate", title: "End Date", placeholder: "Filter by End Date", showFilter: true
            },
        ]
    }

    toggleModal = (modalName) => {
        this.setState({ openModal: this.state.openModal === modalName ? "" : modalName })
    }
    updateNotes = debounce((notes) => {
        this.updateFilters({ ...this.props.filterValues, notes })
    }, 500)
    removeFilter = (filterName) => {
        let filterValues = this.props.filterValues
        let filterKeys = Object.keys(filterValues)
        if (filterKeys.includes(filterName)) {
            delete filterValues[filterName]
            this.updateFilters({ ...filterValues })
        }
    }
    getValueByFilterType = (filterConfigs, value) => {
        if (filterConfigs.type == "select") {
            let options = filterConfigs["options"]
            if (options && options.length > 0) {
                if (Array.isArray(value)) {
                    value = value.map(item => options.find(option => option.value == item).label)
                } else {
                    if (value === true) {
                        value = 'true'
                    }
                    else if (value === false) {
                        value = 'false'
                    }
                    value = options.find(option => option.value == value) && options.find(option => option.value == value).label
                }
            }
        } else if (filterConfigs.type === "boolean")
            value = toTitleCase(value.toString())
        return Array.isArray(value) ? value.join(", ") : value
    }
    getFilters = () => {
        let conf = this.getFilterConfigurations()
        let configurations = conf.filter(filter => filter['showFilter']).map(filter => {
            if (filter['optionsQuery']) {
                let options = []
                this.props.client.query({
                    query: filter.optionsQuery,
                    variables: { ...filter.variables }
                }).then(result => {
                    if (result && result.data.optionsList && result.data.optionsList.edges && result.data.optionsList.edges.length > 0)
                        options = result.data.optionsList.edges.map(options => options.node && ({ value: options.node[filter.valueSelector], label: options.node[filter.labelSelector] }))
                    else if (result && result.data.optionsList && result.data.optionsList.states && result.data.optionsList.states.length > 0) {
                        options = result.data.optionsList.states.map(options => options.node ? ({ value: options.node[filter.valueSelector], label: options.node[filter.labelSelector] }) : ({ value: filter.extractValue ? renderEnumToInt(options[filter.valueSelector]) : options[filter.valueSelector], label: options[filter.labelSelector] }))
                    }
                    filter['options'] = options
                    delete filter['optionsQuery']
                })
            }
            return filter
        })
        this.setState({ configurations: configurations })
    }
    componentDidMount() {
        if (this.props.activeTab)
            this.getFilters()
    }
    updateFilters = (filters, conf = this.state.configurations, history = this.props.history, location = this.props.location, setFilterValues = this.props.setFilterValues) => {
        let visibleFilters = conf.filter(item => item.showFilter).map(item => item.name)
        let newFilterValues = { tab: "transactions" }
        Object.keys(filters).map(key => {
            if (visibleFilters.includes(key) || key === "searchTerm")
                newFilterValues[key] = filters[key]
        })
        const queryString = new URLSearchParams(newFilterValues).toString()
        history.push({ hash: location.hash, search: queryString })
        setFilterValues({ ...filters })
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.activeTab !== prevProps.activeTab) {
            this.getFilters(this.props.activeTab)
        }

        let hasIncludeUpdates = this.state.configurations && this.state.configurations.find(setting => setting.name == "includeUpdates")
        if (((this.props.filterValues.chargeTypeIds && this.props.filterValues.chargeTypeIds.length > 0) || (this.props.filterValues.groupTypeIds && this.props.filterValues.groupTypeIds.length > 0)) && !hasIncludeUpdates) {
            let filters = this.getFilterConfigurations()
            filters[4].showFilter = true
            this.setState({ configurations: [...this.state.configurations, filters[4]] })
        }

        if (this.state.configurations !== prevState.configurations && window.location.hash === "#billing") {
            let urlFilterValues = getFilterValuesFromQueryString(this.props.location.search, this.state.configurations)
            handleConfigurationChange(prevState.configurations, this.state.configurations, urlFilterValues, this.props.setFilterValues, this.props.history, this.props.location, this.props.defaultFilters, this.updateFilters)
        }
    }
    render() {
        return (
            <Row className="mt-2">
                {this.state.openModal === "viewFilters" &&
                    <ListingFilters open={this.state.openModal === "viewFilters"} handleClose={() => this.toggleModal("")}
                        target="viewFilters" filterValues={this.props.filterValues} setFilterValues={(filters => this.updateFilters(filters))}
                        filters={this.state.configurations}
                    />
                }
                {this.state.openModal === "NewTransaction" &&
                    <NewTransaction handleClose={() => this.toggleModal("")} open={this.state.openModal === "NewTransaction"}
                        refetchQuery={this.props.reftchQuery} driverId={this.props.driverId}
                    />
                }
                {this.state.openModal === "NewPaymentPlan" &&
                    <NewPaymentPlan handleClose={() => this.toggleModal("")} open={this.state.openModal === "NewPaymentPlan"}
                        selectedTransactions={this.props.selectedTransactions} refetchQuery={this.props.refetchQuery} driverId={this.props.driverId}
                    />
                }
                {this.state.openModal === "NewPaymentSchedule" &&
                    <PaymentScheduleModal handleClose={() => this.toggleModal("")} open={this.state.openModal === "NewPaymentSchedule"} refetchQuery={this.props.refetchDriver} driverId={this.props.driverId} action="CREATE" />
                }
                {this.state.openModal === "StripeModal" &&
                    <StripeModal driverId={this.props.driverId} handleClose={() => this.toggleModal("")} isOpen={this.state.openModal === "StripeModal"} refetchQuery={this.props.refetchQuery} />
                }
                {this.state.openModal === "TransactionReportDownload" &&
                    <DownloadTransactions driverId={this.props.driverId} handleClose={() => this.toggleModal("")} isOpen={this.state.openModal === "TransactionReportDownload"} />
                }
                {this.state.openModal === "viewColumnPicker" &&
                    <TablePreferencePicker columnConfig={this.props.columnConfig} currentUser={this.props.currentUser} open={this.state.openModal === "viewColumnPicker"} target="toggleColumnPickerButton" refetchPreferences={this.props.refetchPreferences} preferenceType={this.props.preferenceType} setColumns={this.props.setColumns} />
                }
                <Col xs={3}>
                    <span className="driver-search-filter">
                        <Input type="text" name="notes" placeholder="Search By Notes" onChange={(e) => this.updateNotes(e.target.value)} />
                        <i className="fa fa-lg fa-search search-icon" aria-hidden="true"></i>
                    </span>
                </Col>
                <Col xs={9} className="text-right">
                    {this.props.selectedTransactions && this.props.selectedTransactions.length > 0 && <><a id="NewPaymentPlan" className="driver-list-button" onClick={() => this.toggleModal("NewPaymentPlan")}>
                        NEW PAYMENT PLAN | <i className="fa fa-credit-card" aria-hidden="true"></i>
                    </a>&nbsp;&nbsp;&nbsp;&nbsp;</>}
                    {this.props.hasPermissions && this.props.hasPermissions.includes("add_paymentschedule") &&
                        <>
                            <a id="NewPaymentSchedule" className="driver-list-button" onClick={() => this.toggleModal("NewPaymentSchedule")}>
                                SCHEDULE PAYMENT | <i className="fa fa-clock-o" aria-hidden="true"></i>
                            </a>&nbsp;&nbsp;&nbsp;&nbsp;
                        </>
                    }
                    <a id="TransactionReportDownload" className="driver-list-button" onClick={() => this.toggleModal("TransactionReportDownload")}>
                        DOWNLOAD HISTORY | <i className="fa fa-download" aria-hidden="true"></i>
                    </a>&nbsp;&nbsp;&nbsp;&nbsp;
                    <a id="StripeModal" className="driver-list-button" onClick={() => this.toggleModal("StripeModal")}>
                        STRIPE | <i className="fa fa-cc-stripe" aria-hidden="true"></i>
                    </a>&nbsp;&nbsp;&nbsp;&nbsp;
                    <a id="NewTransaction" className="driver-list-button" onClick={() => this.toggleModal("NewTransaction")}>
                        NEW TRANSACTION | <i className="fa fa-plus" aria-hidden="true"></i>
                    </a>&nbsp;&nbsp;&nbsp;&nbsp;
                    <a id="toggleColumnPickerButton" className="driver-list-button" onClick={() => this.toggleModal("viewColumnPicker")}>
                        PREFERENCE | <i class="fa fa-table" aria-hidden="true"></i>
                    </a>&nbsp;&nbsp;&nbsp;&nbsp;
                    <a id="viewFilters" className="driver-list-button" onClick={() => this.toggleModal("viewFilters")}>
                        APPlY FILTERS | <i className="fa fa-filter" aria-hidden="true"></i> {this.props.filterValues && Object.keys(this.props.filterValues).filter(key => this.props.filterValues[key] !== null && this.state.configurations && this.state.configurations.find(setting => setting.name == key) && this.state.configurations.find(setting => setting.name == key).showFilter).length || ""}
                    </a>
                </Col>
                <Col xs={12} className="mt-2">
                    {Object.keys(this.props.filterValues).filter(key => this.props.filterValues[key] !== null && this.state.configurations && this.state.configurations.find(setting => setting.name == key) && this.state.configurations.find(setting => setting.name == key).showFilter).map(key =>
                        <span className="search-filter-preview">
                            <span>
                                <i className="fa fa-times-circle" onClick={() => this.removeFilter(this.state.configurations.find(setting => setting.name == key).name)}></i>&nbsp;&nbsp;
                                {this.state.configurations.find(setting => setting.name == key).title}
                            </span>
                            {this.getValueByFilterType(this.state.configurations.find(setting => setting.name == key), this.props.filterValues[key])}
                        </span>
                    )}
                    {this.props.selectedTransactions && this.props.selectedTransactions.length > 0 && <span className="search-filter-preview">
                        <span> <i className="fa fa-times-circle" onClick={this.props.resetSelectedTransactions}></i> &nbsp;&nbsp; Selected Transactions</span>
                        {this.props.selectedTransactions.length}
                    </span>}
                </Col>
            </Row>
        )
    }
}

export default compose(
    withApollo,
    graphql(HasPermissionsQuery, {
        options: () => ({ variables: { userPermissions: ["add_paymentschedule"] }, fetchPolicy: "network-only", notifyOnNetworkStatusChange: true }),
        props({ data: { hasPermissions, loading, variables } }) {
            return { hasPermissions, loading, variables }
        }
    })
)(TransactionListFilters)
