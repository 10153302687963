import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import {
    Popover, PopoverBody, PopoverHeader, Button, FormGroup, Label, Input, Progress, Alert
} from 'reactstrap'
import { EnumQuery } from '../Functions/querys'
import Languages from "../Material/Language.json"
import Select from "react-select"
import States from '../Material/States.json'
import Cities from "../Material/Cities.json"
import SearchPlaces from "../Material/SearchPlaces"

const billingDays = [
    { value: "", label: "" },
    { value: 0, label: "Monday" },
    { value: 1, label: "Tuesday" },
    { value: 2, label: "Wednesday" },
    { value: 3, label: "Thursday" },
    { value: 4, label: "Friday" },
    { value: 5, label: "Saturday" },
    { value: 6, label: "Sunday" }
]


const genderList = [
    { value: "M", label: "Male" },
    { value: "F", label: "Female" },
    { value: "O", label: "Other" }
]

const chargeMethods = [
    { value: "", label: "" },
    { value: "Payfare", label: "Payfare" },
    { value: "Card", label: "Card" },
    { value: "Zelle/Quickpay", label: "Zelle/Quickpay" },
    { value: "Cash", label: "Cash" },
    { value: "Remote Cash", label: "Remote Cash" },
    { value: "Link", label: "Link" },
    { value: "Via Direct", label: "Via Direct" }
]

const UpdateDriver = gql`
    mutation updateDriverMutation($input: UpdateDriverMutationInput!){
    updateDriver(input:$input){
        ok
        errors{
            messages
            }
        }
    } `
const ChangeBillingDay = gql`
    mutation changeBillingDayMutation($input: ChangeBillingDayMutationInput!){
        changeBillingDay(input:$input){
            ok errors{ messages field }
        }
    } `
const AllPaymentMethodsQuery = gql`
  query AllPaymentMethods{
    allPaymentMethods(isActive:true){
        edges{
            node{
                name
                id
                pk
            }
        }
    }
  }
`
const AllBranchesQuery = gql`
  query AllBranches($tenantId: ID){
    allBranches(tenantId:$tenantId, orderBy:["name"]){
        edges{
            node{
                id
                name
                lineOfBusiness {
                    id
                    name
                }
            }
        }
    }
  }
`
class UpdateDriverInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            input: {},
            loading: false,
        }
    }

    updateDriver = () => {

        if (this.props.driverId) {
            this.setState({ loading: true })
            let input = this.state.input
            if (this.props.name === "legacySystemIds") {
                input["legacySystemIds"] = this.props.value ? this.props.value : []
                input["legacySystemIds"] = JSON.stringify([...input["legacySystemIds"], { name: input["legacySystemName"] === "Other" ? input["legacySystemOtherName"] : input["legacySystemName"], value: input["legacySystemValue"] }])
                delete input["legacySystemName"]
                delete input["legacySystemValue"]
                delete input["legacySystemOtherName"]
            }
            input["id"] = this.props.driverId
            this.props.client.mutate({
                mutation: UpdateDriver,
                variables: { input },
            }).then((result) => {
                if (result && result.data && result.data.updateDriver && result.data.updateDriver.ok) {
                    this.props.refetchDriverQuery()
                    this.props.handleClose()
                    this.setState({ input: {}, loading: false, error: null })
                }
                else {
                    let error = "An error occurred, could not complete request."
                    if (result && result.data && result.data.updateDriver && result.data.updateDriver.errors[0] && result.data.updateDriver.errors[0].messages) {
                        error = result.data.updateDriver.errors[0].messages.toString()
                    }
                    this.setState({ error: error })
                }
                this.setState({ loading: false })
            }).catch((err) => {
                this.setState({ loading: false, error: "An error has occurred. Please contact admin: " + err })
            })
        } else {
        }
    }

    getLanguageVal(langCode) {
        if (Languages.hasOwnProperty(langCode)) {
            return `${Languages[langCode].name} - ${Languages[langCode].nativeName}`
        } else {
            return null
        }
    }

    updateBillingDay = () => {
        if (this.props.driverId) {
            this.setState({ loading: true })
            let input = { "driver": this.props.driverId, "billingDay": this.state.input["chargeDayofweek"] }
            this.props.client.mutate({
                mutation: ChangeBillingDay,
                variables: { input },
            }).then((result) => {
                if (result && result.data && result.data.changeBillingDay && result.data.changeBillingDay.ok) {
                    this.props.refetchDriverQuery()
                    this.props.handleClose()
                    this.setState({ input: {}, loading: false, error: null })
                } else {
                    this.setState({ loading: false, error: "An error has occurred. Please check your input or contact admin." })
                }
            }).catch((err) => {
                this.setState({ loading: false, error: "An error has occurred. Please contact admin." })
            })
        } else {
        }
    }

    updateInput = (e) => {
        if (this.props.driverId) {
            let input = this.state.input
            let name = e.target.name
            let value = e.target.value
            if (name === "email") {
                const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
                if (emailRegex.test(value))
                    this.setState({ error: null })
                else
                    this.setState({ error: "Please enter a valid email address." })
            }
            if (e.target.type === "number") {
                if (isNaN(parseFloat(value))) {
                    value = 0
                } else {
                    value = parseFloat(value)
                }
            }
            input[name] = value
            this.setState({ input: input })
        }
    }

    componentDidMount() {
        if (this.props.type === "boolean") {
            this.setState({ input: { [this.props.name]: this.props.value } })
        }
    }

    render() {
        return (
            <div>
                <Popover placement={this.props.placement ? this.props.placement : "bottom"} isOpen={this.props.open} target={this.props.target} toggle={this.props.handleClose}>
                    <PopoverHeader onClick={this.props.handleClose}>{this.props.title} <i style={{ float: "right" }} className="fa fa-times-circle" aria-hidden="true"> </i></PopoverHeader>
                    <PopoverBody style={{ "textAlign": "center" }}>
                        {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
                        <div className='pb-2'>
                            {this.props.name === "phone" && <Alert color="secondary">Phone number must start with +1. For example +19171230000</Alert>}
                            {this.props.name === "zipCode" && <Alert color="secondary">Zip code must be 5 characters long</Alert>}
                            {this.props.name === "city" ?
                                <SearchPlaces
                                    onSaveCityCode={(item) => this.setState({ input: { ...this.state.input, city: item.value } })}
                                    initialCityName={this.props.value}
                                    searchType={"city"}
                                />
                                :
                                this.props.name === "state" ?
                                    // <Input type={"select"} maxLength={2} name={this.props.name} id={this.props.name} defaultValue={this.props.value} onChange={this.updateInput}>
                                    //     {usStates.map((state, i) =>
                                    //         <option key={i} value={state.value}>{state.value} - {state.label}</option>
                                    //     )}
                                    // </Input>
                                    <Input type="select" name={this.props.name} id={this.props.name} defaultValue={this.props.value} onChange={this.updateInput} >
                                        {/* <option value={""}>--Choose State--</option> */}
                                        {Object.keys(States).map((state, i) =>
                                            <option key={i} value={state}>{state + " - " + States[state]}</option>
                                        )}
                                    </Input>
                                    :
                                    this.props.name === "preferredLanguage" ?
                                        <Select
                                            className="bos-custom-select" classNamePrefix="bos-select"
                                            options={Object.keys(Languages).map(key => ({ value: key, label: Languages[key].name + " - " + Languages[key].nativeName }))}
                                            defaultValue={[{ value: this.props.value, label: this.getLanguageVal(this.props.value) }]}
                                            onChange={(item) => this.setState({ input: { ...this.state.input, preferredLanguage: item.value, id: this.props.driverId } })}
                                        />
                                        :
                                        this.props.name === "paymentMethod" ?
                                            <>
                                                {this.props.allPaymentMethods && this.props.allPaymentMethods.edges.length > 0 ?
                                                    <Input type="select" name="paymentMethod" id="paymentMethod" onChange={this.updateInput} placeholder="Select Method">
                                                        <option value={""}>--Select Method--</option>
                                                        {this.props.allPaymentMethods.edges.map((method, i) =>
                                                            <option key={i} value={method.node.id}>{method.node.name}</option>
                                                        )}
                                                    </Input> :
                                                    <Alert color="info">You need to add payment method options before updating this info</Alert>
                                                }
                                            </>
                                            :
                                            this.props.name === "chargeDayofweek" ?
                                                <Input type={"select"} name={this.props.name} id={this.props.name} defaultValue={this.props.value} onChange={this.updateInput}>
                                                    {billingDays.map((day, i) =>
                                                        <option key={i} value={day.value}>{day.label}</option>
                                                    )}
                                                </Input>
                                                :
                                                this.props.name === "gender" ?
                                                    <Input type={"select"} name={this.props.name} id={this.props.name} defaultValue={this.props.value} onChange={this.updateInput}>
                                                        {genderList.map((gender, i) =>
                                                            <option key={i} value={gender.value}>{gender.label}</option>
                                                        )}
                                                    </Input>
                                                    :
                                                    this.props.name === "branch" ?
                                                        this.props.allBranches && this.props.allBranches.edges && this.props.allBranches.edges.length > 0 ?
                                                            <Select
                                                                className="bos-custom-select" classNamePrefix="bos-select"
                                                                options={this.props.allBranches && this.props.allBranches.edges && this.props.allBranches.edges.length > 0 && this.props.allBranches.edges.filter(item => item.node.lineOfBusiness && ["FHV NATIONAL", "RIDE HAILING"].includes(item.node.lineOfBusiness.name.toUpperCase())).map(branch => ({ value: branch.node.id, label: branch.node.name }))}
                                                                defaultValue={this.props.allBranches && this.props.allBranches.edges && this.props.allBranches.edges.length > 0 && this.props.allBranches.edges.filter(branch => branch.node.id === this.props.value).map(branch => ({ value: branch.node.id, label: branch.node.name }))}
                                                                onChange={(item) => this.setState({ input: { ...this.state.input, branchId: item.value, id: this.props.driverId } })}
                                                                placeholder={"Edit Driver Branch"}
                                                            />
                                                            :
                                                            <Alert color="info">No existing branch found!</Alert>

                                                        : this.props.name === "legacySystemIds" ?
                                                            <>
                                                                <Input type="select" name="legacySystemName" id="legacySystemName" onChange={this.updateInput} placeholder="Select System Name">
                                                                    <option value={""}>--Select Legacy System--</option>
                                                                    {["FT-CRM", "UCars-CRM", "Other"].map((method, i) =>
                                                                        <option key={i} value={method}>{method}</option>
                                                                    )}
                                                                </Input>
                                                                {this.state.input["legacySystemName"] === "Other" && <><br /><Input type="text" name="legacySystemOtherName" id="legacySystemOtherName" onChange={this.updateInput} placeholder="Type System Name" /></>}
                                                                <br />
                                                                <Input type="text" name="legacySystemValue" id="legacySystemValue" onChange={this.updateInput} placeholder="Type System Id" />
                                                            </> :
                                                            this.props.type === "boolean" ?
                                                                <>
                                                                    <br />
                                                                    <div>
                                                                        <span>Yes</span>
                                                                        <div className="toggle-switch">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="toggle-switch-checkbox"
                                                                                name={this.props.name}
                                                                                id={"branchInfo"}
                                                                                checked={!this.state.input[this.props.name]}
                                                                                onChange={() => this.setState({ input: { [this.props.name]: !this.state.input[this.props.name] } })}
                                                                            />
                                                                            <label className="toggle-switch-label" htmlFor={"branchInfo"}>
                                                                                <span className="toggle-switch-inner" />
                                                                                <span className="toggle-switch-switch" />
                                                                            </label>
                                                                        </div>
                                                                        <span>No</span>
                                                                    </div>
                                                                </> :
                                                                <Input autoComplete={"off"} type={this.props.type} maxLength={this.props.maxLength ? this.props.maxLength : 200}
                                                                    name={this.props.name} id={this.props.name} defaultValue={this.props.value}
                                                                    placeholder={this.props.title} onChange={this.updateInput}
                                                                />
                            }
                            {this.state.loading ? <Progress animated color="info" value={100} /> :
                                <Button className="float-right submit-button  my-2 py-1" type="button" size="sm" onClick={this.props.name === "chargeDayofweek" ? this.updateBillingDay : this.updateDriver} disabled={this.state.error != null}>Submit</Button>
                            }
                        </div>
                    </PopoverBody>
                </Popover>
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(AllPaymentMethodsQuery, {
        options: { fetchPolicy: 'cache-first' },
        props({ data: { loading, allPaymentMethods } }) {
            return { loading, allPaymentMethods }
        },
    }),
    graphql(AllBranchesQuery, {
        options: ({ tenantId }) => ({ variables: { tenantId }, fetchPolicy: 'cache-first' }),
        props({ data: { loading, allBranches } }) {
            return { loading, allBranches }
        },
    })

)(UpdateDriverInfo)
