import React, { Component } from 'react'
import { graphql, compose } from 'react-apollo'
import gql from 'graphql-tag'
import { withApollo } from "react-apollo/index"
import './TransactionListNew.css'
import "../../Drivers/Driver/Detail/DetailsNew/DriverDetailNew.css"
import TransactionListNew from './TransactionListNew'
import TransactionListFilters from "./TransactionListFilters"
import ManagePreferenceHOC from "../../Material/ManagePreferenceHOC"

const tableHeaders = [
    { id: "id", name: "ID", sortable: false, visible: true, showInPicker: false, positonIsFixed: true },
    { id: "agreementCarId", name: "Car ID", sortable: false, visible: true, showInPicker: true, positonIsFixed: false },
    { id: "chargeType", name: "Type", sortable: false, visible: true, showInPicker: true, positonIsFixed: false },
    { id: "amount", name: "Amount($)", sortable: false, visible: true, showInPicker: true, positonIsFixed: false },
    { id: "balance", name: "Balance", sortable: false, visible: true, showInPicker: true, positonIsFixed: false },
    { id: "dateAdded", name: "Date Added", sortable: true, visible: true, showInPicker: true, positonIsFixed: false },
    { id: "dueDate", name: "Due Date", sortable: true, visible: true, showInPicker: true, positonIsFixed: false },
    { id: "createdBy", name: "Created By", sortable: false, visible: true, showInPicker: true, positonIsFixed: false },
]

class TransactionListContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filterValues: {
                includeUpdates: false,
            },
            refetchTransactions: false,
            selectedTransactions: [],
            error: "",
            loading: false
        }
    }

    render() {
        return (
            <div className="tab-container">
                <TransactionListFilters
                    activeTab={this.props.activeTab}
                    filterValues={this.state.filterValues}
                    setFilterValues={(filterValues) => this.setState({ filterValues })}
                    defaultFilters={this.state.filterValues}
                    driverId={this.props.driverId} {...this.props}
                    refetchQuery={() => this.setState({ refetchTransactions: !this.state.refetchTransactions })}
                    refetchDriver={this.props.refetchQuery}
                    tableColConfig={this.state.tableColConfig}
                    setColumns={(tableColConfig) => this.setState({ tableColConfig })}
                    selectedTransactions={this.state.selectedTransactions}
                    resetSelectedTransactions={() => this.setState({ selectedTransactions: [] })}
                    refetchDriver={this.props.refetchQuery}
                    currentUser={this.props.currentUser}
                    columnConfig={this.props.columnConfig}
                    preferenceType={"transaction"}
                    refetchPreferences={this.props.refetchPreferences}
                />
                {this.props.driverId && <TransactionListNew
                    key={String(this.state.refetchTransactions)}
                    driverId={this.props.driverId}
                    columnConfig={this.props.columnConfig}
                    transactionIds={this.props.transactionIds ? this.props.transactionIds : null}
                    setSelectedTransactions={(selectedTransactions) => this.setState({ selectedTransactions })}
                    selectedTransactions={this.state.selectedTransactions}
                    chargeTypeIds={this.state.filterValues && this.state.filterValues.chargeTypeIds ? this.state.filterValues.chargeTypeIds : null}
                    notes={this.state.filterValues && this.state.filterValues.notes ? this.state.filterValues.notes : null}
                    groupTypeIds={this.state.filterValues && this.state.filterValues.groupTypeIds ? this.state.filterValues.groupTypeIds : null}
                    includeUpdates={this.state.filterValues && this.state.filterValues.includeUpdates !== null ? this.state.filterValues.includeUpdates : null}
                    includeDeleted={this.state.filterValues && this.state.filterValues.includeDeleted !== null ? this.state.filterValues.includeDeleted : null}
                    minAmount={this.state.filterValues && this.state.filterValues.minAmount ? this.state.filterValues.minAmount : null}
                    maxAmount={this.state.filterValues && this.state.filterValues.maxAmount ? this.state.filterValues.maxAmount : null}
                    startDate={this.state.filterValues && this.state.filterValues.startDate ? this.state.filterValues.startDate : null}
                    endDate={this.state.filterValues && this.state.filterValues.endDate ? this.state.filterValues.endDate : null}
                    refetchQuery={() => { this.props.refetchQuery() }}
                    currentUser={this.props.currentUser}
                />}
            </div>
        )
    }
}

export default compose(
    withApollo,
    ManagePreferenceHOC("transaction", tableHeaders)
)(TransactionListContainer)
