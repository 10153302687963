import gql from "graphql-tag"

export const AllPaymentSchedulesQuery = gql`
query allPaymentSchedules($driverId: ID, $statuses: [String], $searchTerm: String, $orderBy: [String]){
    allPaymentSchedules(driverId: $driverId, statuses: $statuses, searchTerm: $searchTerm, orderBy: $orderBy){
        edges{
            node{            
                id
                amountToCharge
                driver{
                    id
                    firstName
                    lastName
                    balance
                    name
                    dmvLicense
                }
                scheduleDatetime
                maxRetries
                notes
                status
            }
        }
        pageInfo{
            hasNextPage
            endCursor
        }
    }
}`


export const PaymentsStatusesQuery = gql`query PaymentsStatusesQuery{
    optionsList: __type(name:"PaymentScheduleStatus") {
        states: enumValues {
            name
            description
        }
    }
  }`


