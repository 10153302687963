import React, { useState, useEffect } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import { Col, Label, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import Row from 'reactstrap/lib/Row'
import Container from "reactstrap/lib/Container"
import Loader from "../Material/Loader"
import "../Organization/BranchMangement/ObjectDetail.css"
import RestrictedSection from "../Components/RestrictedSection"
import OverviewTab from "./OverviewTab"
import { AutomationQuery } from "./Queries"
import { set } from "lodash"

const BRANCH_DETAIL_TABS = [
    { id: "#overview", name: "OVERVIEW" },
]

const AutomationDetail = ({ automation, loading, refetchAutomationQuery, ...props }) => {
    const [activeTab, setActiveTab] = useState("overview")
    const [rendererdTabPanes, setRendererdTabPanes] = useState([])

    const toggleTab = (tabId) => {
        if (!rendererdTabPanes.includes(tabId)) {
            setRendererdTabPanes([...rendererdTabPanes, tabId])
        }
        setActiveTab(tabId)
    }

    useEffect(() => {
        let defaultTab = BRANCH_DETAIL_TABS[0].id
        if (window.location.hash && BRANCH_DETAIL_TABS.map(item => item.id).includes(window.location.hash)) {
            setActiveTab(window.location.hash)
            setRendererdTabPanes([window.location.hash])
        } else {
            window.location.hash = defaultTab
            setActiveTab(defaultTab)
            setRendererdTabPanes([defaultTab])
        }
    }, [])

    useEffect(() => {
        window.location.hash = activeTab
    }, [activeTab])

    return (
        <Container fluid className="bos-object-detail-container">
            {loading ? <Loader /> : automation && <>
                <Row className="bos-object-detail-header">
                    <Col xs="1">
                        <span className="box-object-icon">
                            <i className="fa fa-2x fa-code-fork" aria-hidden="true"></i>
                        </span>
                    </Col>
                    <Col xs={5} className="bos-object-title-wrapper">
                        <Label>
                            {automation.name}
                        </Label>
                        &nbsp;&nbsp;&nbsp;{automation.isActive ? <i className="fa fa-check-circle fa-2x text-success" aria-hidden="true" /> : <i className="fa fa-times-circle fa-2x text-danger" aria-hidden="true" />}
                    </Col>
                    <Col xs={3} className="bos-object-property-wrapper">
                        <Label>
                            {automation.customId}
                        </Label>
                    </Col>
                    <Col xs={3} className="bos-object-property-wrapper text-right">
                        <Label>
                            {automation.city}, {automation.stateCode}, {automation.country} {automation.postalCode}
                        </Label>
                    </Col>
                </Row>
                <Row className="bos-object-detail-body">
                    <Nav tabs>
                        {BRANCH_DETAIL_TABS.map(item =>
                            <NavItem key={item.id} className={activeTab === item.id ? "active" : "inactive"}>
                                <NavLink className={activeTab === item.id ? "active" : "inactive"} onClick={() => toggleTab(item.id)}>
                                    {item.name}
                                </NavLink>
                            </NavItem>
                        )}
                    </Nav>
                    <div className="bos-object-nav-content">
                        <TabContent activeTab={activeTab}>
                            {rendererdTabPanes && rendererdTabPanes.includes("#overview") && <TabPane tabId="#overview">
                                <RestrictedSection requiredPermission="view_branch">
                                    <OverviewTab automationId={automation.id} />
                                </RestrictedSection>
                            </TabPane>}
                        </TabContent>
                    </div>
                </Row>
            </>
            }
        </Container>
    )
}

export default compose(
    withApollo,
    graphql(AutomationQuery, {
        options: ({ match }) => ({ variables: { id: match.params.id }, fetchPolicy: 'network-only' }),
        props({ data: { automation, loading, refetch, variables } }) {
            return {
                automation, loading, variables,
                refetchAutomationQuery: () => {
                    //No apollo function that refetches in place with pagination considered so this function instead
                    return refetch({
                        query: AutomationQuery,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { automation: fetchMoreResult.automation }
                        },
                    })
                },
            }
        }
    })
)(AutomationDetail)
