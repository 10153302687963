import React, { Component, Fragment } from "react"
import { Popover, PopoverHeader, PopoverBody, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Label, Input, FormGroup, CardBody, Button, Card, CardText, UncontrolledTooltip, Progress, Alert, Badge, CardTitle, FormText, CardFooter, CardHeader } from "reactstrap"
import "./NewReservationModal.css"
import { AllCarModelsQuery, AllAgreementTypes, AllCarLocations, AllPricingTemplates, ReservationQuery, ReservationPickupTypes, DriverQuery, CarQuery, AgreementTypeDepositSchemes } from "./Queries"
import toTitleCase from "../../Functions/toTitleCase"
import { compose, graphql, withApollo } from "react-apollo"
import Select from "react-select"
import { Link } from "react-router-dom"
import { Swiper, SwiperSlide } from "swiper/react"
import moment from "moment"
import DriverSearch from "../../Material/DriverSearch"
import CarSearch from "../../Material/CarSearch"
import UpdateReservationInfo from "../UpdateReservationInfo"
import { UpdateReservation, CreateReservationPrice, RemoveReservationPrice, CreateReservationMutation, CreateReservationPromo, RemoveReservationPromo } from "./Mutations"
import AddToDidntTakeCar from "../../Material/AddToDidntTakeCar"
import UploadDocumentModal from "../../Material/DocumentModal/UploadDocumentModal"
import PickupModal from "./PickupModal"
import DriverNotes from "../../ApplicationsNew/DriverNotes"
import DatetimeRenderer from "../../Material/DatetimeRenderer"
import { HasPermissionsQuery } from "../../Functions/querys"
import { AllPromotionsTemplates } from "./Queries"
import { ReactComponent as AgreementPrice } from '../../assets/images/icons/agreement-price.svg'
import { ReactComponent as Promo } from '../../assets/images/icons/promo.svg'
import UpdateReservationPromotion from "../UpdateReservationPromo"
import ConfirmationPopup from "../../Material/ConfirmationPopup"
import { debounce } from "lodash"

const pickupTypes = ["Accident Switch", "Mechanical Switch", "Personal Switch",
    "Insurance/TLC Switch", "Split Switch"]

const VALIDATIONS_TO_SKIP_OPTIONS = ["ARGYLE"]

class ReservationModalNew extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedDriver: null,
            selectedCar: null,
            agreementType: null,
            pickupDate: null,
            pickUpLocation: null,
            pickupType: null,
            notes: "",
            preferredCar: null,
            priceRange: "",
            deposit: 399,
            depositScheme: "Fixed",
            sendMessage: true,
            isSplit: false,
            disabledMessage: null,
            msgSent: false,
            linkSent: false,
            driverId: null,
            carId: null,
            formStep: 1,
            allPricingTemplates: [],
            allPromotionsTemplates: [],
            selectedPricingTemplates: [],
            selectedPromotionsTemplates: [],
            loading: false,
            ignoreMissingCarDocs: false,
            validationsToSkip: [],
        }
    }

    toggleModal = (openModal) => {
        if (this.state.openModal === openModal) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: openModal })
        }
    }

    updateSelection = (name, value) => {
        this.setState({ [name]: value })
        this.toggleModal("")
    }

    handleClose = () => {
        this.toggleModal("")
        this.props.handleClose()
    }

    handleStep = (formStep) => {
        if (this.state.formStep == 1) {
            if (this.state.selectedDriver && this.state.selectedDriver.currentAgreement && this.state.selectedDriver.currentAgreement.id && (!this.state.pickupType || this.state.pickupType == "")) {
                this.setState({ error: "An agreement exist for this driver. Please add switch in the pickup type!" })
                return
            }
        } else if (this.state.formStep == 2 && formStep > 2) {
            if (!this.props.isUpdate && this.state.selectedPricingTemplates.length == 0) {
                this.setState({ error: "Please select atleast one pricing template to create discount configuration!" })
                return
            } else if (this.props.reservation && (!this.props.reservation.reservationPrice || !this.props.reservation.reservationPrice.edges || this.props.reservation.reservationPrice.edges.length === 0)) {
                this.setState({ error: "Please select atleast one pricing template to create discount configuration!" })
                return
            }
            let valueArr = this.props.isUpdate ? this.props.reservation.reservationPrice.edges.map(function (item) { return item.node.startDate }) : this.state.selectedPricingTemplates.map(function (item) { return item.startDate })
            let containsDuplicate = valueArr.some(function (item, idx) {
                return valueArr.indexOf(item) != idx
            })
            let noDate = valueArr.some(function (item, idx) {
                return item == null
            })
            if (containsDuplicate) {
                this.setState({ error: "Start date can not be same for different plans! Please contact admin for more info." })
                return
            }
            if (noDate) {
                this.setState({ error: "Please add date for the selected price templates." })
                return
            }
            if (!this.props.isUpdate) {
                let isLessAmount = this.state.selectedPricingTemplates.filter(item => Number(item.price) < Number(item.minimumPrice))

                if (isLessAmount && isLessAmount[0]) {
                    this.setState({ error: Number(isLessAmount[0].price) + " Price should be greater than " + Number(isLessAmount[0].minimumPrice) })
                    return
                }
            }
        }
        this.setState({ error: "", formStep })
    }

    updateReservationPlan = (type, plan, e) => {
        let input
        if (type === "add") {
            if (this.props.isUpdate) {
                plan.startDate = moment().tz("America/New_York").format(moment.HTML5_FMT.DATE)
                input = {
                    reservation: this.props.reservation.id,
                    price: plan.price,
                    minimumPrice: plan.minimumPrice,
                    maximumPrice: plan.maximumPrice,
                    interval: plan.interval,
                    intervalUnit: plan.intervalUnit.charAt(0).toUpperCase() + plan.intervalUnit.substr(1).toLowerCase(),
                    startDate: plan.startDate,
                    tierType: plan.tierType && plan.tiers.edges.length > 0 ? plan.tierType.charAt(0).toUpperCase() + plan.tierType.substr(1).toLowerCase() : null,
                    tiers: plan.tierType && plan.tiers.edges.length > 0 && plan.tiers.edges.map(tier => { return tier.node.id })
                }
            } else {
                this.setState({ selectedPricingTemplates: [...this.state.selectedPricingTemplates, plan] })
            }
        } else if (type === "remove") {
            if (this.props.isUpdate)
                input = { reservationPrice: plan.id }
            else {
                let priceTemplates = this.state.selectedPricingTemplates
                priceTemplates.splice(priceTemplates.findIndex(template => template === plan), 1)
                this.setState({ selectedPricingTemplates: [...priceTemplates] })
            }
        } else if (type === "edit") {
            let { name, value } = e.target
            let selectedPricingTemplates = [...this.state.selectedPricingTemplates]

            if (name.includes("selectedPlanStartDate")) {
                selectedPricingTemplates[plan] = { ...selectedPricingTemplates[plan], startDate: value }
                selectedPricingTemplates.sort((a, b) => (a.startDate > b.startDate) ? 1 : ((b.startDate > a.startDate) ? -1 : 0))
            }
            else if (name.includes("selectedPlanBasePrice")) {
                selectedPricingTemplates[plan] = { ...selectedPricingTemplates[plan], price: value }
            }
            this.setState({ selectedPricingTemplates })
        }
        if (this.props.isUpdate) {
            this.props.client.mutate({
                mutation: type == "add" ? CreateReservationPrice : "remove" ? RemoveReservationPrice : null,
                variables: { input },
            }).then((result) => {
                if (result && result.data && ((type === 'add' && result.data.createReservationPrice && result.data.createReservationPrice.ok) || (type === 'remove' && result.data.removeReservationPrice && result.data.removeReservationPrice.ok))) {
                    // this.fetchData()
                    this.props.refetchReservationQuery()
                    this.setState({ loading: false, errorMessage: "" })
                } else {
                    let errorMessage = ""
                    if (result && result.data && type === 'add' && result.data.createReservationPrice && result.data.createReservationPrice.errors && result.data.createReservationPrice.errors) {
                        result.data.createReservationPrice.errors.map((item, i) => item.messages ? errorMessage += item.messages.join(". ") : "")
                    } else if (result && result.data && type === 'remove' && result.data.removeReservationPrice && result.data.removeReservationPrice.errors && result.data.removeReservationPrice.errors) {
                        result.data.removeReservationPrice.errors.map((item, i) => item.messages ? errorMessage += item.messages.join(". ") : "")
                    } else {
                        errorMessage = "An error has occured. Please contact admin."
                    }
                    this.setState({ loading: false, errorMessage: errorMessage })
                }
            }).catch((err) => {
                this.setState({ loading: false, errorMessage: "An error has occured. Please contact admin." })
            })
        }
    }

    updateReservationPromoPlan = (type, plan, e) => {
        let input
        if (type === "add") {
            if (this.props.isUpdate) {
                input = {
                    reservation: this.props.reservation.id,
                    title: plan.title,
                    promoPrice: plan.promoPrice,
                    minimumPromoPrice: plan.minimumPromoPrice,
                    maximumPromoPrice: plan.maximumPromoPrice,
                    cycle: plan.cycle,
                    minCycle: plan.minCycle,
                    maxCycle: plan.maxCycle,
                    interval: plan.interval,
                    intervalUnit: plan.intervalUnit.charAt(0).toUpperCase() + plan.intervalUnit.substr(1).toLowerCase(),
                    startDate: moment().tz("America/New_York").format(moment.HTML5_FMT.DATE),
                    expirationDate: plan.expirationDate,
                    isPercentage: plan.isPercentage,
                }
            } else {
                if (this.state.selectedPromotionsTemplates.length > 0)
                    this.setState({ error: "Only one promotion is allowed at the time of reservation creation" })
                else
                    this.setState({ selectedPromotionsTemplates: [...this.state.selectedPromotionsTemplates, { ...plan, startDate: moment().tz("America/New_York").format(moment.HTML5_FMT.DATE) }] })
                // this.setState({ selectedPromotionsTemplates: [...this.state.selectedPromotionsTemplates, plan] })
            }
        } else if (type === "remove") {
            if (this.props.isUpdate)
                input = { reservationPromo: plan.id }
            else {
                let promoTemplete = this.state.selectedPromotionsTemplates
                promoTemplete.splice(promoTemplete.findIndex(template => template === plan), 1)
                this.setState({ selectedPromotionsTemplates: [...promoTemplete] })
            }
        } else if (type === "edit") {
            let { name, value } = e.target
            let selectedPromotionsTemplates = [...this.state.selectedPromotionsTemplates]

            if (name.includes("selectedPromoStartDate")) {
                selectedPromotionsTemplates[plan] = { ...selectedPromotionsTemplates[plan], startDate: value }
                selectedPromotionsTemplates.sort((a, b) => (a.startDate > b.startDate) ? 1 : ((b.startDate > a.startDate) ? -1 : 0))
            }
            else if (name.includes("selectedPromoExpirationDate")) {
                selectedPromotionsTemplates[plan] = { ...selectedPromotionsTemplates[plan], expirationDate: value }
                selectedPromotionsTemplates.sort((a, b) => (a.expirationDate > b.expirationDate) ? 1 : ((b.expirationDate > a.expirationDate) ? -1 : 0))
            }
            else if (name.includes("selectedPromoCycle")) {
                selectedPromotionsTemplates[plan] = { ...selectedPromotionsTemplates[plan], cycle: value }
            }
            else if (name.includes("selectedPromoPrice")) {
                selectedPromotionsTemplates[plan] = { ...selectedPromotionsTemplates[plan], promoPrice: value }
            }
            this.setState({ selectedPromotionsTemplates })
        }
        if (this.props.isUpdate) {
            this.props.client.mutate({
                mutation: type == "add" ? CreateReservationPromo : "remove" ? RemoveReservationPromo : null,
                variables: { input },
            }).then((result) => {
                if (result && result.data && ((type === 'add' && result.data.createReservationPromo && result.data.createReservationPromo.ok) || (type === 'remove' && result.data.removeReservationPromo && result.data.removeReservationPromo.ok))) {
                    this.props.refetchReservationQuery()
                    this.setState({ loading: false, errorMessage: "" })
                } else {
                    let errorMessage = ""
                    if (result && result.data && type === 'add' && result.data.createReservationPromo && result.data.createReservationPromo.errors && result.data.createReservationPromo.errors) {
                        result.data.createReservationPromo.errors.map((item, i) => item.messages ? errorMessage += item.messages.join(". ") : "")
                    } else if (result && result.data && type === 'remove' && result.data.removeReservationPromo && result.data.removeReservationPromo.errors && result.data.removeReservationPromo.errors) {
                        result.data.removeReservationPrice.errors.map((item, i) => item.messages ? errorMessage += item.messages.join(". ") : "")
                    } else {
                        errorMessage = "An error has occured. Please contact admin."
                    }
                    this.setState({ loading: false, errorMessage: errorMessage })
                }
            }).catch((err) => {
                this.setState({ loading: false, errorMessage: "An error has occured. Please contact admin." })
            })
        }
    }

    sendPickupMessage = () => {
        if (this.props.reservation) {
            try {
                this.setState({ loading: true })
                if (this.props.reservation.car && this.props.reservation.car.id) {
                    let input = { "action": "send_pickup_message", "reservationId": this.props.reservation.id }
                    this.props.client.mutate({
                        mutation: UpdateReservation,
                        variables: { input }
                    }).then((result) => {
                        if (result) {
                            if (result.data && result.data.updateReservation && result.data.updateReservation.ok) {
                                this.setState({ loading: false, msgSent: true, errorMessage: null })
                            } else {
                                let errorMessage = "An error occured. Please retry or contact admin."
                                if (result.data && result.data.updateReservation && result.data.updateReservation.errors) {
                                    errorMessage = result.data.updateReservation.errors[0].messages
                                    this.setState({ loading: false, errorMessage: errorMessage, msgSent: false })
                                }
                            }
                        }
                    })
                } else {
                    this.setState({ loading: false, errorMessage: "Please select a car before sending the pickup message!", msgSent: false })
                }
            } catch (error) {
                this.setState({ loading: false, errorMessage: "An error occured. Please retry or contact admin.", msgSent: false })
            }
        }
    }
    sendLink = () => {
        try {
            this.setState({ loading: true })
            let input = { "action": "send_link", "reservationId": this.props.reservation.id }
            this.props.client.mutate({
                mutation: UpdateReservation,
                variables: { input }
            }).then((result) => {
                if (result) {
                    if (result.data && result.data.updateReservation && result.data.updateReservation.ok) {
                        this.setState({ loading: false, linkSent: true, errorMessage: null })
                    } else {
                        let errorMessage = "An error occured. Please retry or contact admin."
                        if (result.data && result.data.updateReservation && result.data.updateReservation.errors) {
                            errorMessage = result.data.updateReservation.errors[0].message
                            this.setState({ loading: false, errorMessage: errorMessage, linkSent: false })
                        }
                    }
                }
            })
        } catch (error) {
            this.setState({ loading: false, errorMessage: "An error occured. Please retry or contact admin.", linkSent: false })
        }
    }

    fetchPricingTemplates = debounce((car) => {
        let agreementTypeId = this.props.allAgreementTypes && this.props.allAgreementTypes.edges && this.props.allAgreementTypes.edges.find(agreementType => agreementType.node && agreementType.node.name === (this.props.isUpdate && this.state.agreementType ? this.state.agreementType.name : this.state.agreementType)) ? this.props.allAgreementTypes.edges.find(agreementType => agreementType.node && agreementType.node.name === (this.props.isUpdate && this.state.agreementType ? this.state.agreementType.name : this.state.agreementType)).node.id : ""
        this.props.client.query({
            query: AllPricingTemplates,
            variables: {
                carId: car ? car.id : null,
                agreementTypeId: agreementTypeId
            }
        }).then((result) => {
            if (result && result.data && result.data.allPricingTemplates && result.data.allPricingTemplates.edges)
                this.setState({ loading: false, allPricingTemplates: result.data.allPricingTemplates.edges, selectedPricingTemplates: [] })
            else
                this.setState({ loading: false, allPricingTemplates: null, selectedPricingTemplates: [] })
        }).catch((err) => {
            this.setState({ loading: false, error: "Error fetching pricing. Please contact admin." })
        })
    }, 500)

    fetchPromoTemplates = debounce((car) => {
        let agreementTypeId = this.props.allAgreementTypes && this.props.allAgreementTypes.edges && this.props.allAgreementTypes.edges.find(agreementType => agreementType.node && agreementType.node.name === (this.props.isUpdate && this.state.agreementType ? this.state.agreementType.name : this.state.agreementType)) ? this.props.allAgreementTypes.edges.find(agreementType => agreementType.node && agreementType.node.name === (this.props.isUpdate && this.state.agreementType ? this.state.agreementType.name : this.state.agreementType)).node.id : ""
        this.props.client.query({
            query: AllPromotionsTemplates,
            variables: {
                carId: car ? car.id : null,
                agreementTypeId: agreementTypeId,
            }
        }).then((response) => {
            if (response && response.data && response.data.allPromotionsTemplates && response.data.allPromotionsTemplates.edges)
                this.setState({ loading: false, allPromotionsTemplates: response.data.allPromotionsTemplates.edges, selectedPromotionsTemplates: [] })
            else
                this.setState({ loading: false, allPromotionsTemplates: null, selectedPromotionsTemplates: [] })
        }).catch((err) => {
            this.setState({ loading: false, error: "Error fetching promos. Please contact admin." })
        })
    }, 500)


    fetchDriver = debounce((id) => {
        this.props.client.query({
            query: DriverQuery,
            variables: { id }
        }).then(result => {
            if (result && result.data && result.data.driver) {
                this.setState({ selectedDriver: result.data.driver })
            }
        })
    }, 500)

    fetchCar = debounce((id) => {
        this.props.client.query({
            query: CarQuery,
            variables: { id }
        }).then(result => {
            if (result && result.data && result.data.car) {
                this.setState({ selectedCar: result.data.car })
            }
        })
    }, 500)

    componentDidMount() {
        if (this.props.driverId) {
            this.fetchDriver(this.props.driverId)
        }
        if (this.props.carId) {
            this.fetchCar(this.props.carId)
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            (!prevProps.reservation && this.props.reservation) ||
            (this.props.reservation && this.props.reservation.car && !prevProps.reservation.car) ||
            (this.props.reservation && !this.props.reservation.car && prevProps.reservation.car) ||
            (this.props.reservation && this.props.reservation.car && this.props.reservation.car.id !== prevProps.reservation.car.id) ||
            (this.props.reservation && this.props.reservation.car && this.props.reservation.car !== this.state.selectedCar)
        ) {
            this.setState({ selectedCar: this.props.reservation.car })
        }
        if (
            (!prevProps.reservation && this.props.reservation) ||
            (this.props.reservation && this.props.reservation.agreementType && !prevProps.reservation.agreementType) ||
            (this.props.reservation && !this.props.reservation.agreementType && prevProps.reservation.agreementType) ||
            (this.props.reservation && this.props.reservation.agreementType && this.props.reservation.agreementType.id !== prevProps.reservation.agreementType.id) ||
            (this.props.reservation && this.props.reservation.agreementType && this.props.reservation.agreementType !== this.state.agreementType)
        ) {
            this.setState({ agreementType: this.props.reservation.agreementType })
        }
        if ((!prevProps.reservation && this.props.reservation) || (this.props.reservation && this.props.reservation.isSplit !== prevProps.reservation.isSplit)) {
            this.setState({ isSplit: this.props.reservation.isSplit })
        }
        if (
            (!prevProps.reservation && this.props.reservation) ||
            (this.props.reservation && this.props.reservation.driver && !prevProps.reservation.driver) ||
            (this.props.reservation && !this.props.reservation.driver && prevProps.reservation.driver) ||
            (this.props.reservation && this.props.reservation.driver.id !== prevProps.reservation.driver.id) ||
            (this.props.reservation && this.props.reservation.driver && this.props.reservation.driver !== this.state.selectedDriver)
        ) {
            this.setState({ selectedDriver: this.props.reservation.driver })
        }
        if (this.state.selectedCar && this.state.agreementType && (prevState.selectedCar !== this.state.selectedCar || prevState.agreementType !== this.state.agreementType)) {
            this.fetchPricingTemplates(this.state.selectedCar)
            this.fetchPromoTemplates(this.state.selectedCar)
        }
        if (prevState.driverId !== this.state.driverId) {
            this.fetchDriver(this.state.driverId)
        }
        if (prevState.carId !== this.state.carId) {
            this.fetchCar(this.state.carId)
            if (this.props.isUpdate)
                this.updateReservation("carId", this.state.carId)
        }
        if (!this.props.isUpdate && this.state.agreementType && prevState.agreementType !== this.state.agreementType) {
            let agreementTypeNode = this.props.allAgreementTypes && this.props.allAgreementTypes.edges && this.props.allAgreementTypes.edges.length > 0 && this.props.allAgreementTypes.edges.find(agreementType => this.state.agreementType === agreementType.node.name)
            this.setState({ deposit: agreementTypeNode ? agreementTypeNode.node.requiredDeposit : 399, depositScheme: agreementTypeNode ? toTitleCase(agreementTypeNode.node.depositScheme) : "Fixed" })
        }
    }

    updateInput = (e) => {
        let { name, value } = e.target
        this.setState({ [name]: value })
    }

    updateReservation = (name, newValue) => {
        if (this.props.reservation && this.props.reservation.id) {
            this.setState({ loading: true })
            let input = { reservationId: this.props.reservation.id, [name]: newValue }
            this.props.client.mutate({
                mutation: UpdateReservation,
                variables: { input },
            }).then((result) => {
                if (result && result.data && result.data.updateReservation && result.data.updateReservation.ok) {
                    if (this.props.refetchQuery)
                        this.props.refetchQuery()
                    this.props.refetchReservationQuery()
                    this.setState({ loading: false, errorMessage: "" })
                } else {
                    let errorMessage = ""
                    if (result && result.data && result.data.updateReservation && result.data.updateReservation.errors && result.data.updateReservation.errors) {
                        result.data.updateReservation.errors.map((item, i) => item.messages ? errorMessage += item.messages.join(". ") : "")
                    } else {
                        errorMessage = "An error has occured. Please contact admin."
                    }
                    this.setState({ loading: false, errorMessage: errorMessage })
                }
            }).catch((err) => {
                this.setState({ loading: false, errorMessage: "An error has occured. Please contact admin." })
            })
        }
    }

    createReservation = () => {
        try {
            if (this.state.selectedDriver) {
                this.setState({ loading: true })
                const input = {
                    driverId: this.state.selectedDriver.id,
                    carId: this.state.selectedCar ? this.state.selectedCar.id : null,
                    agreementType: this.state.agreementType,
                    depositScheme: this.state.depositScheme,
                    deposit: this.state.deposit,
                    pickupDate: this.state.pickupDate,
                    pickUpLocation: this.state.pickUpLocation,
                    priceRange: this.state.priceRange,
                    preferredCar: this.state.preferredCar,
                    notes: this.state.pickupNotes,
                    sendMessage: this.state.sendMessage,
                    isSplit: this.state.isSplit,
                    pickupType: this.state.pickupType,
                    ignoreMissingCarDocs: this.state.ignoreMissingCarDocs,
                    validationsToSkip: this.state.validationsToSkip
                }
                if (this.state.selectedPricingTemplates && this.state.selectedPricingTemplates.length > 0) {
                    input["reservationPrices"] = this.state.selectedPricingTemplates.map(selectedPricing => {
                        return {
                            price: selectedPricing.price,
                            minimumPrice: parseFloat(selectedPricing.minimumPrice),
                            maximumPrice: selectedPricing.maximumPrice,
                            interval: selectedPricing.interval,
                            intervalUnit: selectedPricing.intervalUnit.charAt(0).toUpperCase() + selectedPricing.intervalUnit.substr(1).toLowerCase(),
                            startDate: selectedPricing.startDate,
                            tierType: selectedPricing.tierType && selectedPricing.tiers.edges.length > 0 ? selectedPricing.tierType.charAt(0).toUpperCase() + selectedPricing.tierType.substr(1).toLowerCase() : null,
                            tiers: selectedPricing.tierType && selectedPricing.tiers.edges.length > 0 && selectedPricing.tiers.edges.map(tier => { return tier.node.id })
                        }
                    })
                }
                if (this.state.selectedPromotionsTemplates && this.state.selectedPromotionsTemplates.length > 0) {
                    input["reservationPromos"] = this.state.selectedPromotionsTemplates.map(selectedPromo => {
                        return {
                            title: selectedPromo.title,
                            promoPrice: selectedPromo.promoPrice,
                            minimumPromoPrice: selectedPromo.minimumPromoPrice,
                            maximumPromoPrice: selectedPromo.maximumPromoPrice,
                            cycle: selectedPromo.cycle,
                            minCycle: selectedPromo.minCycle,
                            maxCycle: selectedPromo.maxCycle,
                            interval: selectedPromo.interval,
                            intervalUnit: selectedPromo.intervalUnit.charAt(0).toUpperCase() + selectedPromo.intervalUnit.substr(1).toLowerCase(),
                            startDate: selectedPromo.startDate,
                            expirationDate: selectedPromo.expirationDate,
                            isPercentage: selectedPromo.isPercentage,
                        }
                    })
                }
                this.props.client.mutate({
                    mutation: CreateReservationMutation,
                    variables: { input }
                }).then((response) => {
                    if (response && response.data && response.data.createReservation && response.data.createReservation.ok) {
                        this.setState({ loading: false, error: null })
                        this.props.refetchQuery()
                        this.props.handleClose()
                    } else {
                        let errorMessage = "An error has occured. Please contact admin."
                        if (response.data.createReservation && response.data.createReservation.errors && response.data.createReservation.errors.length > 0) {
                            errorMessage = response.data.createReservation.errors[0].messages[0]
                        }
                        this.setState({ loading: false, error: errorMessage })
                    }
                }).catch((err) => {
                    this.setState({ loading: false, error: "Error creating reservation. Please contact admin." })
                })
            } else {
                this.setState({ loading: false, error: !this.state.selectedDriver ? "Please choose a driver" : "Please choose a pickup date." })
            }
        } catch (error) {
            this.setState({ loading: false, error: "Error creating reservation. Please contact admin." })
        }
    }

    removeDateTime = (name) => {
        this.setState({ loading: true })
        this.props.client.mutate({
            mutation: UpdateReservation,
            variables: { input: { reservationId: this.props.reservation.id, [name]: null } }
        }).then((result) => {
            if (result && result.data && result.data.updateReservation && result.data.updateReservation.ok) {
                this.props.refetchReservationQuery()
                this.setState({ loading: false, dateTimeRemoved: true })
            } else {
                let errorMessage = result.data && result.data.updateReservation && result.data.updateReservation.errors && result.data.updateReservation.errors[0] && result.data.updateReservation.errors[0].messages ?
                    result.data.updateReservation.errors[0].messages.join(". ") : "An error has occured. Please try again or contact admin."
                this.setState({ loading: false, errorMessage: errorMessage, dateTimeRemoved: false })
            }
        })
    }

    render() {
        const allTemplates = this.state.allPricingTemplates !== null && this.state.allPromotionsTemplates !== null && [...this.state.allPricingTemplates, ...this.state.allPromotionsTemplates]
        let filteredAgreementTypes = this.props.allAgreementTypes && this.props.allAgreementTypes.edges && this.props.allAgreementTypes.edges.length > 0 ? this.props.allAgreementTypes.edges : []
        let filteredCarLocation = this.props.allCarLocations && this.props.allCarLocations.edges && this.props.allCarLocations.edges.length > 0 ? this.props.allCarLocations.edges : []
        if (this.state.selectedDriver && filteredAgreementTypes.length > 0)
            filteredAgreementTypes = filteredAgreementTypes.filter(agreementType => this.state.selectedDriver.branch.id == (agreementType.node && agreementType.node.branch ? agreementType.node.branch.id : null))
        if (this.state.selectedDriver && filteredCarLocation.length > 0)
            filteredCarLocation = filteredCarLocation.filter(pickupLocation => this.state.selectedDriver.branch.id == pickupLocation.node.branch.id)
        return (
            < Modal isOpen={this.props.open} className="NewReservationModal" >
                <ModalHeader>
                    <span>{this.props.title}</span>
                </ModalHeader>
                <ModalBody>
                    {this.state.formStep === 1 ? <Fragment>
                        <Row className="my-2">
                            <Col className="selection-box">
                                <Row>
                                    {!this.props.reservationLoading && !this.state.selectedDriver && this.state.openModal !== "selectDriver" &&
                                        <Col onClick={!this.props.reservationLoading ? () => this.toggleModal("selectDriver") : null}>
                                            <span className="selection-box-icon" style={{ fontSize: "20px" }}><i className="fa fa-plus" ></i>&nbsp;&nbsp;Select Driver</span>
                                        </Col>
                                    }
                                    {this.state.selectedDriver && this.state.openModal !== "selectDriver" && <>
                                        <Col>
                                            <h5>{this.state.selectedDriver.name}&nbsp;&nbsp;<small><Link to={`/driver/${this.state.selectedDriver.id}`} target="_blank"><i className="fa fa-external-link grey-link" style={{ color: "#1B4D7E" }}></i></Link></small></h5>
                                            <p className="selection-box-text">{this.state.selectedDriver.phone} | {this.state.selectedDriver.tlcLicense}</p>
                                        </Col>
                                        {!(this.props.isUpdate || this.props.path === "driverDetail") &&
                                            <Col className="text-right" xs={2}>
                                                <span className="selection-box-icon" onClick={() => this.setState({ selectedDriver: null })}><i className="fa fa-times"></i></span>
                                            </Col>
                                        }
                                    </>}
                                    {this.state.openModal === "selectDriver" && !this.state.selectedDriver && <Col><DriverSearch setDriver={(id) => this.updateSelection("driverId", id)} /></Col>}
                                </Row>
                            </Col>
                            <Col className="selection-box">
                                <Row>
                                    {!this.props.reservationLoading && !this.state.selectedCar && this.state.openModal !== "selectCar" &&
                                        <Col onClick={!this.props.reservationLoading ? () => this.toggleModal("selectCar") : null}>
                                            <span className="selection-box-icon" style={{ fontSize: "20px" }}><i className="fa fa-plus" ></i>&nbsp;&nbsp;Select Car</span>
                                        </Col>
                                    }
                                    {this.state.selectedCar && this.state.openModal !== "selectCar" && <>
                                        <Col>
                                            <h5>{this.state.selectedCar.pk} - {this.state.selectedCar.year}  {toTitleCase(this.state.selectedCar.carModel.make)} {toTitleCase(this.state.selectedCar.carModel.name)} <small>{this.state.selectedCar.carModel.series ? `(${this.state.selectedCar.carModel.series})` : ''} &nbsp;&nbsp;<Link to={"/car/" + this.state.selectedCar.pk} target="_blank"><i className="fa fa-external-link grey-link" style={{ color: "#1B4D7E" }}></i></Link></small></h5>
                                            <p className="selection-box-text">{this.state.selectedCar.color} {this.state.selectedCar.carModel.groupType ? "| " + this.state.selectedCar.carModel.groupType.name : ""} | {this.state.selectedCar.vin}</p>
                                        </Col>
                                        <Col className="text-right" xs={2}>
                                            <span className="selection-box-icon" onClick={() => this.setState({ openModal: "selectCar" })}><i className="fa fa-edit"></i></span>
                                        </Col>
                                    </>
                                    }
                                    {this.state.openModal === "selectCar" &&
                                        <Col>
                                            <span className="selection-box-icon close-selection-box-icon" onClick={!this.props.reservationLoading ? () => this.toggleModal("") : null}><i className="fa fa-times" ></i></span>
                                            <CarSearch driverId={this.state.selectedDriver ? this.state.selectedDriver.id : null} setCar={(id) => this.updateSelection("carId", id)} />
                                        </Col>
                                    }
                                </Row>
                            </Col>
                        </Row>
                        <br />
                        <Row className="mb-2">
                            <Col>
                                <div className="d-flex justify-content-between">
                                    <Label>AGREEMENT TYPE</Label>
                                    {this.props.isUpdate && this.props.reservation &&
                                        <i
                                            id={"agreementTypeEdit"}
                                            className="fa fa-pencil-square edit-icon"
                                            aria-hidden="true"
                                            onClick={() => this.toggleModal("agreementTypeEdit")}
                                        >
                                        </i>
                                    }
                                </div>
                                {this.state.openModal === "agreementTypeEdit" &&
                                    <UpdateReservationInfo reservation={this.props.reservation} title="Edit Agreement Type" name="agreementType" value={this.props.reservation.agreementType ? this.props.reservation.agreementType.name : ""} target={"agreementTypeEdit"} id="agreementType" type="text" open={this.state.openModal === "agreementTypeEdit"} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchReservationQuery} />
                                }
                                {this.props.isUpdate ?
                                    <>
                                        <div className="edit-item">
                                            <Input
                                                type="text"
                                                name="agreementType"
                                                value={this.props.reservation && this.props.reservation.agreementType ? this.props.reservation.agreementType.name : ""}
                                                disabled
                                            />
                                        </div>
                                    </> :
                                    <Select
                                        className="bos-custom-select"
                                        classNamePrefix="bos-select"
                                        placeholder="Select Agreement Type"
                                        value={this.state.agreementType && { value: this.state.agreementType, label: this.state.agreementType }}
                                        options={filteredAgreementTypes && filteredAgreementTypes.map((type) => ({ label: type.node.name, value: type.node.name }))}
                                        isClearable loading={this.props.allAgreementTypesLoading}
                                        onChange={(item) => this.setState({ agreementType: item && item.value ? item.value : null })}
                                    />
                                }
                            </Col>
                            <Col>
                                <div className="d-flex justify-content-between">
                                    <Label>PICKUP DATE TIME</Label>
                                    {this.props.isUpdate && this.props.reservation &&
                                        <span>
                                            {this.props.reservation.pickupDate &&
                                                <>
                                                    {this.state.openModal === "removePickupDate" && <ConfirmationPopup
                                                        open={this.state.openModal === "removePickupDate"}
                                                        message={"Are you sure you want to remove the pickup date?"}
                                                        loading={this.state.loading}
                                                        handleClose={() => this.setState({ openModal: "", errorMessage: "", dateTimeRemoved: false })}
                                                        action={"Yes"}
                                                        confirmAction={() => this.removeDateTime("pickupDate")}
                                                        color="danger"
                                                        error={this.state.errorMessage ? this.state.errorMessage : ""}
                                                        success={this.state.dateTimeRemoved ? "Date Time Removed Successfully" : ""}
                                                    />}
                                                    <UncontrolledTooltip placement="top" target={"pickupDateRemove"}>Remove Pickup Date</UncontrolledTooltip>
                                                    <i
                                                        id={"pickupDateRemove"}
                                                        className="fa fa-times-circle edit-icon"
                                                        aria-hidden="true"
                                                        onClick={() => this.toggleModal("removePickupDate")}
                                                    >
                                                    </i>
                                                </>
                                            } &nbsp;
                                            <i
                                                id={"pickupDateEdit"}
                                                className="fa fa-pencil-square edit-icon"
                                                aria-hidden="true"
                                                onClick={() => this.toggleModal("pickupDateEdit")}
                                            >
                                            </i>
                                        </span>
                                    }
                                </div>
                                {this.state.openModal === "pickupDateEdit" &&
                                    <UpdateReservationInfo reservation={this.props.reservation} title="Edit Pickup Date" name="pickupDate" value={this.props.reservation.pickupDate} min={moment().tz("America/New_York").format(moment.HTML5_FMT.DATE)} target={"pickupDateEdit"} id="pickupDate" type="datetime-local" open={this.state.openModal === "pickupDateEdit"} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchReservationQuery} />
                                }
                                {this.props.isUpdate ?
                                    <>
                                        <div className="edit-item">
                                            {this.props.reservation && this.props.reservation.pickupDate ? <DatetimeRenderer datetime={this.props.reservation.pickupDate} /> : "No Pickup Date Seletced"}
                                        </div>
                                    </> :
                                    <Input type="datetime-local" name="pickupDate" value={this.state.pickupDate} min={new Date().toISOString().slice(0, new Date().toISOString().lastIndexOf(":"))} onChange={this.updateInput} />
                                }
                            </Col>
                            <Col>
                                <div className="d-flex justify-content-between">
                                    <Label>PICKUP LOCATION</Label>
                                    {this.props.isUpdate && this.props.reservation &&
                                        <i
                                            id={"pickUpLocationEdit"}
                                            className="fa fa-pencil-square edit-icon"
                                            aria-hidden="true"
                                            onClick={() => this.toggleModal("pickUpLocationEdit")}
                                        >
                                        </i>
                                    }
                                </div>
                                {this.state.openModal === "pickUpLocationEdit" &&
                                    <UpdateReservationInfo reservation={this.props.reservation} title="Edit Pickup Location" name="pickUpLocation" value={this.props.reservation.pickUpLocation ? this.props.reservation.pickUpLocation.name : ""} target={"pickUpLocationEdit"} id="pickUpLocation" type="select" open={this.state.openModal === "pickUpLocationEdit"} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchReservationQuery} />
                                }
                                {this.props.isUpdate ?
                                    <>
                                        <div className="edit-item">
                                            <Input
                                                type="text"
                                                name="pickUpLocation"
                                                value={this.props.reservation && this.props.reservation.pickUpLocation ? this.props.reservation.pickUpLocation.name : ""}
                                                disabled
                                            />

                                        </div>
                                    </> :
                                    <Select
                                        className="bos-custom-select"
                                        classNamePrefix="bos-select"
                                        placeholder="SELECT PICKUP LOCATION"
                                        value={this.state.pickUpLocation && { value: this.state.pickUpLocation, label: this.state.pickUpLocation }}
                                        options={filteredCarLocation && filteredCarLocation.map((location) => ({ label: location.node.name, value: location.node.name }))}
                                        isClearable loading={this.props.allCarLocationsLoading}
                                        onChange={(item) => this.setState({ pickUpLocation: item && item.value ? item.value : null })}
                                    />
                                }
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <div className="d-flex justify-content-between">
                                    <Label>Deposit</Label>
                                    {this.props.isUpdate && this.props.reservation && this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_update_deposit_amount") &&

                                        <i
                                            id={"depositSchemeEdit"}
                                            className="fa fa-pencil-square edit-icon"
                                            aria-hidden="true"
                                            onClick={() => this.toggleModal("depositSchemeEdit")}
                                        >
                                        </i>
                                    }
                                    {this.props.isUpdate && this.props.reservation && this.props.reservation.depositScheme === "FIXED" && this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_update_deposit_amount") &&
                                        <i
                                            id={"depositEdit"}
                                            className="fa fa-pencil-square edit-icon"
                                            aria-hidden="true"
                                            onClick={() => this.toggleModal("depositEdit")}
                                        >
                                        </i>
                                    }
                                </div>
                                {this.state.openModal === "depositEdit" &&
                                    <UpdateReservationInfo reservation={this.props.reservation} title="Edit Deposit" name="deposit" value={this.props.reservation.depositAmount} target={"depositEdit"} id="deposit" type="text" open={this.state.openModal === "depositEdit"} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchReservationQuery} />
                                }
                                {this.state.openModal === "depositSchemeEdit" &&
                                    <UpdateReservationInfo reservation={this.props.reservation} title="Edit Deposit Scheme" name="depositScheme" value={toTitleCase(this.props.reservation.depositScheme)} target={"depositSchemeEdit"} id="depositScheme" type="text" open={this.state.openModal === "depositSchemeEdit"} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchReservationQuery} />
                                }
                                {this.props.isUpdate ?
                                    <Row>
                                        <Col className="edit-item">
                                            <Input
                                                type="text"
                                                name="depositSchemeEdit"
                                                value={this.props.reservation && this.props.reservation.depositScheme ? toTitleCase(this.props.reservation.depositScheme) : "--"}
                                                disabled
                                            />
                                        </Col>
                                        {this.props.reservation && <Col className="edit-item">
                                            <Input
                                                type="text"
                                                name="depositEdit"
                                                value={this.props.reservation && this.props.reservation.depositAmount ? this.props.reservation.depositAmount : ""}
                                                disabled
                                            />
                                        </Col>}
                                    </Row> :
                                    <Row>
                                        <Col>
                                            <Select
                                                className="bos-custom-select"
                                                classNamePrefix="bos-select"
                                                placeholder="SELECT DEPOSIT SCHEME"
                                                isDisabled={!(this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_update_deposit_amount") && this.state.agreementType)}
                                                value={{ value: this.state.depositScheme, label: this.state.depositScheme }}
                                                options={this.props.agreementTypeDepositSchemes && this.props.agreementTypeDepositSchemes.enumValues && this.props.agreementTypeDepositSchemes.enumValues.map((type) => {
                                                    return { value: type.description, label: type.description }
                                                })}
                                                onChange={(item) => this.setState({ depositScheme: item && item.value ? item.value : null })}
                                            />
                                        </Col>
                                        {this.state.depositScheme === "Fixed" && <Col>
                                            <Input type="number" name="deposit" value={this.state.deposit}
                                                disabled={!(this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_update_deposit_amount") && this.state.agreementType)} onChange={this.updateInput} invalid={this.state.deposit === null || this.state.deposit === ""}
                                            />
                                        </Col>}
                                    </Row>
                                }
                            </Col>
                            <Col>
                                <div className="d-flex justify-content-between">
                                    <Label>DESIRED PRICE RANGE</Label>
                                    {this.props.isUpdate && this.props.reservation &&
                                        <i
                                            id={"priceRangeEdit"}
                                            className="fa fa-pencil-square edit-icon"
                                            aria-hidden="true"
                                            onClick={() => this.toggleModal("priceRangeEdit")}
                                        >
                                        </i>
                                    }
                                </div>
                                {this.state.openModal === "priceRangeEdit" &&
                                    <UpdateReservationInfo reservation={this.props.reservation} title="Edit Price Range" name="priceRange" value={this.props.reservation.priceRange ? this.props.reservation.priceRange : ""} target={"priceRangeEdit"} id="priceRange" type="text" open={this.state.openModal === "priceRangeEdit"} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchReservationQuery} />
                                }
                                {this.props.isUpdate ?
                                    <>
                                        <div className="edit-item">
                                            <Input
                                                type="text"
                                                name="priceRange"
                                                value={this.props.reservation && this.props.reservation.priceRange ? this.props.reservation.priceRange : ""}
                                                disabled
                                            />
                                        </div>
                                    </> :
                                    <Input type="text" name="priceRange" value={this.state.priceRange} onChange={this.updateInput} placeholder="Enter Desired Price Range" />
                                }
                            </Col>
                            <Col>
                                <div className="d-flex justify-content-between">
                                    <Label>DESIRED CAR MODEL</Label>
                                    {this.props.isUpdate && this.props.reservation &&
                                        <i
                                            id={"preferredCarEdit"}
                                            className="fa fa-pencil-square edit-icon"
                                            aria-hidden="true"
                                            onClick={() => this.toggleModal("preferredCarEdit")}
                                        >
                                        </i>
                                    }
                                </div>
                                {this.state.openModal === "preferredCarEdit" &&
                                    <UpdateReservationInfo reservation={this.props.reservation} title="Edit Preferred Car" name="preferredCar" value={this.props.reservation.preferredCar || ""} target={"preferredCarEdit"} id="preferredCar" type="select" open={this.state.openModal === "preferredCarEdit"} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchReservationQuery} />
                                }
                                {this.props.isUpdate ?
                                    <>
                                        <div className="edit-item">
                                            <Input
                                                type="text"
                                                name="preferredCar"
                                                value={this.props.reservation && this.props.reservation.preferredCar ? this.props.reservation.preferredCar : ""}
                                                disabled
                                            />

                                        </div>
                                    </> :
                                    <Select
                                        className="bos-custom-select"
                                        classNamePrefix="bos-select"
                                        placeholder="SELECT DESIRED CAR MODEL"
                                        value={this.state.preferredCar && { value: this.state.preferredCar, label: this.state.preferredCar }}
                                        options={this.props.allCarModels && this.props.allCarModels.edges && this.props.allCarModels.edges.map((carModel) => {
                                            let modelValue = toTitleCase(carModel.node.make) + " " + carModel.node.name + (carModel.node.series ? " | " + carModel.node.series : "") + (carModel.node.groupType ? " | " + carModel.node.groupType.name : " | No Group Selected")
                                            return { value: modelValue, label: modelValue }
                                        })}
                                        isClearable loading={this.props.allCarModelsLoading}
                                        onChange={(item) => this.setState({ preferredCar: item && item.value ? item.value : null })}
                                    />
                                }
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <div className="d-flex justify-content-between">
                                    <Label>NOTES</Label>
                                    {this.props.isUpdate && this.props.reservation &&
                                        <div className="d-flex flex-row">
                                            <i id={"notesHistory"} onClick={() => this.toggleModal("notesHistory")} className={"fa fa-history float-right pr-3"} aria-hidden="true"></i>
                                            <i
                                                id={"notesEdit"}
                                                className="fa fa-pencil-square edit-icon"
                                                aria-hidden="true"
                                                onClick={() => this.toggleModal("notesEdit")}
                                            >
                                            </i>
                                        </div>
                                    }
                                </div>
                                {this.state.openModal === "notesHistory" &&
                                    <DriverNotes driver={this.props.reservation && this.props.reservation.driver} noteType="RESERVATION" title="Reservation Notes History" objectId={this.props.reservation && this.props.reservation.id} target={"notesHistory"} open={this.state.openModal === "notesHistory"} handleClose={() => this.toggleModal("")} placement={"left"} />}
                                {this.state.openModal === "notesEdit" &&
                                    <UpdateReservationInfo reservation={this.props.reservation} title="Edit Notes" name="notes" value={this.props.reservation && this.props.reservation.notes ? this.props.reservation.notes : ""} target={"notesEdit"} id="notes" type="text" open={this.state.openModal === "notesEdit"} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchReservationQuery} />
                                }
                                {this.props.isUpdate ?
                                    <>
                                        <div className="edit-item">
                                            <Input
                                                type="text"
                                                name="notesEdit"
                                                value={this.props.reservation && this.props.reservation.notes ? this.props.reservation.notes : ""}
                                                disabled
                                            />
                                        </div>
                                    </> :
                                    <Input type="textarea" name="notes" onChange={this.updateInput} placeholder="Reservations Notes" />
                                }
                            </Col>
                        </Row>
                        <Row>
                            {this.state.selectedDriver && this.state.selectedDriver.currentAgreement && this.state.selectedDriver.currentAgreement.id && <Col>
                                <div className="d-flex justify-content-between">
                                    <Label for="pickupType">Pickup Type</Label>
                                    {this.props.isUpdate && this.props.reservation &&
                                        <i
                                            id={"pickupTypeEdit"}
                                            className="fa fa-pencil-square edit-icon"
                                            aria-hidden="true"
                                            onClick={() => this.toggleModal("pickupTypeEdit")}
                                        >
                                        </i>
                                    }
                                </div>
                                {this.state.openModal === "pickupTypeEdit" &&
                                    <UpdateReservationInfo reservation={this.props.reservation} title="Edit Preferred Car" name="pickupType" value={this.props.reservationPickupTypes && this.props.reservationPickupTypes.enumValues && this.props.reservationPickupTypes.enumValues.length > 0 ? this.props.reservationPickupTypes.enumValues.filter(type => type.name === this.props.reservation.pickupType).length > 0 && this.props.reservationPickupTypes.enumValues.filter(type => type.name === this.props.reservation.pickupType)[0].description : ""} target={"preferredCarEdit"} id="preferredCar" type="select" open={this.state.openModal === "preferredCarEdit"} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchReservationQuery} />
                                }
                                {this.props.isUpdate ?
                                    <>
                                        <div className="edit-item">
                                            <Input
                                                type="text"
                                                name="preferredCar"
                                                value={this.props.reservation && this.props.reservationPickupTypes && this.props.reservationPickupTypes.enumValues && this.props.reservationPickupTypes.enumValues.length > 0 && this.props.reservationPickupTypes.enumValues.filter(type => type.name === this.props.reservation.pickupType).length > 0 ? this.props.reservationPickupTypes.enumValues.filter(type => type.name === this.props.reservation.pickupType)[0].description : ""}
                                                disabled
                                            />

                                        </div>
                                    </> :
                                    <Select
                                        className="bos-custom-select"
                                        classNamePrefix="bos-select"
                                        placeholder="SELECT PICKUP TYPE"
                                        options={pickupTypes.map((type) => {
                                            return { value: type, label: type }
                                        })}
                                        isClearable
                                        onChange={(item) => this.setState({ pickupType: item && item.value ? item.value : null })}
                                    />
                                }
                            </Col>}
                            {!this.props.isUpdate && <Col>
                                <Label>Send Message To Driver</Label>
                                <div>
                                    <span>YES</span>
                                    <div className="toggle-switch">
                                        <input
                                            type="checkbox"
                                            className="toggle-switch-checkbox"
                                            name="sendMessage"
                                            id="sendMessage"
                                            checked={!this.state.sendMessage}
                                            onChange={() => this.setState({ sendMessage: !this.state.sendMessage })}
                                        />
                                        <label className="toggle-switch-label" htmlFor="sendMessage">
                                            <span className="toggle-switch-inner" />
                                            <span className="toggle-switch-switch" />
                                        </label>
                                    </div>
                                    <span>No</span>
                                </div>
                            </Col>}
                            {this.props.isUpdate && this.state.selectedCar && <Col>
                                <Label>Send Message To Driver</Label>
                                <CardText>
                                    <Button
                                        disabled={this.state.msgSent}
                                        color="primary"
                                        style={{ padding: "0px 5px", fontSize: "14px" }}
                                        onClick={this.sendPickupMessage}>
                                        <i className={this.state.msgSent === true ? "fa fa-paper-plane" : "fa fa-paper-plane-o"} aria-hidden="true"></i>
                                        &nbsp;&nbsp;{this.state.msgSent ? "Message Sent" : "Send Now"}
                                    </Button>
                                </CardText>
                            </Col>}
                            <Col>
                                <Label>IS SPLIT</Label>
                                <div>
                                    <span>YES</span>
                                    <div className="toggle-switch">
                                        <input
                                            type="checkbox"
                                            className="toggle-switch-checkbox"
                                            name="isSplit"
                                            id="isSplit"
                                            checked={!this.state.isSplit}
                                            onChange={this.props.isUpdate ? () => this.updateReservation("isSplit", !this.state.isSplit) : () => this.setState({ isSplit: !this.state.isSplit })}
                                        />
                                        <label className="toggle-switch-label" htmlFor="isSplit">
                                            <span className="toggle-switch-inner" />
                                            <span className="toggle-switch-switch" />
                                        </label>
                                    </div>
                                    <span>No</span>
                                </div>
                            </Col>
                            <Col>
                                <Label>Representative</Label>
                                <div className="edit-item">
                                    <Input
                                        type="text"
                                        name="notesEdit"
                                        value={this.props.reservation && this.props.reservation.rep ? this.props.reservation.rep.username : "---"}
                                        disabled
                                    />
                                </div>
                            </Col>
                        </Row>
                        {!this.props.isUpdate && this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_skip_validations") &&
                            <Row className="mt-2">
                                <Col>
                                    <FormGroup>
                                        <Label>Validations to skip</Label>
                                        <br />
                                        <Select className="bos-custom-select"
                                            classNamePrefix="bos-select" isLoading={false}
                                            options={
                                                VALIDATIONS_TO_SKIP_OPTIONS && VALIDATIONS_TO_SKIP_OPTIONS && VALIDATIONS_TO_SKIP_OPTIONS.map(
                                                    (validation) => ({ value: validation, label: validation }))
                                            }
                                            placeholder="Select validations to skip"
                                            isMulti
                                            onChange={(option) => this.setState({ validationsToSkip: option.map(item => item.value) })}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        }
                    </Fragment> :
                        this.state.formStep === 2 ?
                            <Fragment>
                                {allTemplates && allTemplates.length > 0 ?
                                    <Swiper spaceBetween={0} slidesPerView={3} navigation>
                                        {allTemplates.map((pricingTemplate, i) => (
                                            pricingTemplate && pricingTemplate.node &&
                                            <SwiperSlide>
                                                <Card className={pricingTemplate.__typename == 'PricingTemplateNodeEdge' ? "price-card" : "promo-card"}>
                                                    <CardHeader>{pricingTemplate.node.title}</CardHeader>
                                                    <CardBody>
                                                        {pricingTemplate.__typename == 'PromotionTemplateNodeEdge' &&
                                                            <CardText>
                                                                <b><span>{pricingTemplate.node.isPercentage ? pricingTemplate.node.promoPrice + ' %' : '$' + pricingTemplate.node.promoPrice}</span></b> per {pricingTemplate.node.interval > 1 ? pricingTemplate.node.interval : ""}{pricingTemplate.node.intervalUnit}
                                                            </CardText>
                                                        }
                                                        {pricingTemplate.__typename == 'PricingTemplateNodeEdge' &&
                                                            <CardText>
                                                                <b>$<span>{pricingTemplate.node.price}</span></b> per {pricingTemplate.node.interval > 1 ? pricingTemplate.node.interval : ""}{pricingTemplate.node.intervalUnit}  {pricingTemplate.node.tiers && pricingTemplate.node.tiers.edges && pricingTemplate.node.tiers.edges.length > 0 && <i className={this.state.openModal === "TierDetails" + i ? "fa fa-chevron-circle-up" : "fa fa-chevron-circle-down"} aria-hidden="true" onClick={() => this.toggleModal("TierDetails" + i)} id={"tier" + i}></i>}
                                                            </CardText>
                                                        }
                                                        {this.state.openModal === "TierDetails" + i &&
                                                            <Popover isOpen={this.state.openModal === "TierDetails" + i} placement="right" target={"tier" + i}>
                                                                <PopoverHeader onClick={() => this.setState({ openModal: "" })}>Tier Details <i style={{ float: "right" }} className="fa fa-times-circle" aria-hidden="true"> </i></PopoverHeader>
                                                                <PopoverBody style={{ minWidth: "200px" }}>
                                                                    <Label><b>TIERS - {pricingTemplate.node.tierType}</b></Label><br />
                                                                    <ul>
                                                                        {pricingTemplate.node.tiers && pricingTemplate.node.tiers.edges && pricingTemplate.node.tiers.edges.length > 0 ? pricingTemplate.node.tiers.edges.map((tier, j) =>
                                                                            <li>
                                                                                <span>{i + 1} ${tier.node.price}/{tier.node.interval > 1 ? tier.node.interval : ""}{tier.node.intervalUnit}: {tier.node.upperLimit} {tier.node.upperLimitUnit}</span><br />
                                                                            </li>
                                                                        ) : "No Tier Found"
                                                                        }
                                                                    </ul>
                                                                </PopoverBody>
                                                            </Popover>
                                                        }
                                                    </CardBody>
                                                    <CardFooter>
                                                        {pricingTemplate.__typename == 'PricingTemplateNodeEdge' ?
                                                            <Button disabled={this.state.loading || this.props.loading} onClick={(e) => this.updateReservationPlan("add", pricingTemplate.node, e)}>Add Plan</Button> :
                                                            pricingTemplate.__typename == 'PromotionTemplateNodeEdge' && <Button disabled={this.state.loading || this.props.loading} onClick={(e) => this.updateReservationPromoPlan("add", pricingTemplate.node, e)}>Add Promo</Button>
                                                        }
                                                    </CardFooter>
                                                </Card>
                                            </SwiperSlide>
                                        )
                                        )}
                                    </Swiper>
                                    : <Col className="p-5 text-center text-danger" xs={12}><p>No plan has been found for this car and agreement type. Please ask admin to create a pricing template for this selection.</p></Col>}
                                {this.props.isUpdate ?
                                    this.props.reservation && this.props.reservation.reservationPrice && this.props.reservation.reservationPrice.edges.length > 0 && this.props.reservation.reservationPrice.edges.map((reservationPlan, i) =>
                                        <Col xs={12}>
                                            <Card className="plan-detail-card">
                                                <CardHeader><AgreementPrice width={30} fill={"#fffff"} />&nbsp;&nbsp;Agreement Plan</CardHeader>
                                                <CardBody><br />
                                                    <Row>
                                                        <Col xs={5}>
                                                            <div className="d-flex justify-content-between">
                                                                <Label>Starting Date</Label><br />
                                                                {this.props.isUpdate &&
                                                                    <i
                                                                        id={"selectedPlanStartDate" + i}
                                                                        className="fa fa-pencil-square edit-icon"
                                                                        aria-hidden="true"
                                                                        onClick={() => this.toggleModal("selectedPlanStartDate" + i)}
                                                                    >
                                                                    </i>
                                                                }
                                                            </div>
                                                            <Input
                                                                type="text"
                                                                name="selectedPlanStartDate"
                                                                value={reservationPlan.node.startDate ? reservationPlan.node.startDate : ""}
                                                                disabled
                                                            />
                                                            {this.state.openModal === "selectedPlanStartDate" + i &&
                                                                <UpdateReservationInfo reservationPlan={reservationPlan.node} title="Edit Start Date" name={"selectedPlanStartDate"} value={reservationPlan.node.startDate ? reservationPlan.node.startDate : ""} min={moment().tz("America/New_York").format(moment.HTML5_FMT.DATE)} target={"selectedPlanStartDate" + i} id={"selectedPlanStartDate" + i} type="date" open={this.state.openModal === "selectedPlanStartDate" + i} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchReservationQuery} />
                                                            }
                                                        </Col>
                                                        <Col xs={5}>
                                                            <div className="d-flex justify-content-between">
                                                                <Label>Base Price <small>$/Per {reservationPlan.node.interval && reservationPlan.node.interval > 1 ? reservationPlan.node.interval : ""}{reservationPlan.node.intervalUnit ? reservationPlan.node.intervalUnit : ""}</small></Label><br />
                                                                <i
                                                                    id={"selectedPlanBasePrice" + i}
                                                                    className="fa fa-pencil-square edit-icon"
                                                                    aria-hidden="true"
                                                                    onClick={() => this.toggleModal("selectedPlanBasePrice" + i)}
                                                                />
                                                            </div>
                                                            <Input
                                                                type="text"
                                                                name="selectedPlanBasePrice"
                                                                value={reservationPlan.node.price ? reservationPlan.node.price : ""}
                                                                disabled
                                                            />
                                                            {this.state.openModal === "selectedPlanBasePrice" + i &&
                                                                <UpdateReservationInfo reservationPlan={reservationPlan.node} title="Edit Base Price" name={"selectedPlanBasePrice"} value={reservationPlan.node.price ? reservationPlan.node.price : ""} min={reservationPlan.node.minimumPrice} max={reservationPlan.node.maximumPrice} target={"selectedPlanBasePrice" + i} id={"selectedPlanBasePrice" + i} type="number" open={this.state.openModal === "selectedPlanBasePrice" + i} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchReservationQuery} />
                                                            }
                                                        </Col>
                                                        <Col xs={2} className="text-center">
                                                            <Label>Action</Label>
                                                            <p>
                                                                <UncontrolledTooltip target={"plan" + i}>Remove</UncontrolledTooltip>
                                                                <i name="delete" id={"plan" + i} className="fa fa-trash fa-lg text-danger align-self-center" onClick={(e) => this.updateReservationPlan("remove", reservationPlan.node, e)}></i>
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ) || <Card className="plan-detail-card"><CardBody style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>No plan has been selected for this reservation!</CardBody></Card> :
                                    this.state.selectedPricingTemplates && this.state.selectedPricingTemplates.length > 0 && this.state.selectedPricingTemplates.map((reservationPlan, i) =>
                                        <Col xs={12}>
                                            <Card className="plan-detail-card">
                                                <CardHeader><AgreementPrice width={30} fill={"#fffff"} />&nbsp;&nbsp;{reservationPlan.title}</CardHeader>
                                                <CardBody><br />
                                                    <Row>
                                                        <Col xs={3}>
                                                            <Label>Agreement Type</Label>
                                                            <p>{reservationPlan.agreementType.name}</p>
                                                        </Col>
                                                        <Col xs={4}>
                                                            <Label>Starting Date</Label><br />
                                                            <Input name={"selectedPlanStartDate" + i} value={reservationPlan.startDate ? reservationPlan.startDate : ""} onChange={(e) => this.updateReservationPlan("edit", i, e)} id={reservationPlan.id} min={moment().tz("America/New_York").format(moment.HTML5_FMT.DATE)} type="date" />
                                                        </Col>
                                                        <Col>
                                                            <Label>Price&nbsp;&nbsp;<i className="fa fa-edit" onClick={() => this.toggleModal("editBasePrice" + i)}></i></Label>
                                                            {this.state.openModal !== "editBasePrice" + i ?
                                                                <p>{"$" + reservationPlan.price + " "} <small> Per {reservationPlan.interval && reservationPlan.interval > 1 ? reservationPlan.interval : ""}{reservationPlan.intervalUnit ? reservationPlan.intervalUnit : ""}</small></p> :
                                                                <div className="d-flex flex-row">
                                                                    <Input
                                                                        type="number"
                                                                        className="planintervalprice"
                                                                        onChange={(e) => this.updateReservationPlan("edit", i, e)}
                                                                        name={"selectedPlanBasePrice" + i}
                                                                        id={reservationPlan.id}
                                                                        value={reservationPlan.price}
                                                                        min={reservationPlan.minimumPrice ? reservationPlan.minimumPrice : reservationPlan.price ? reservationPlan.price : null}
                                                                        placeholder="Plan Base Price"
                                                                    />
                                                                    <i className="fa fa-check" style={{ position: "absolute", bottom: "10px", right: "-5px" }} onClick={() => this.toggleModal("")}></i></div>
                                                            }
                                                        </Col>
                                                        <Col xs={2} className="text-center align-self-center">
                                                            <Label>Action</Label>
                                                            <p>
                                                                <UncontrolledTooltip target={"plan" + i}>Remove</UncontrolledTooltip>
                                                                <i name="delete" id={"plan" + i} className="fa fa-trash fa-lg text-danger" onClick={(e) => this.updateReservationPlan("remove", reservationPlan, e)}></i>
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ) || <Card className="plan-detail-card"><CardBody style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>No plan has been selected for this reservation!</CardBody></Card>}

                                {this.props.isUpdate ?
                                    this.props.reservation && this.props.reservation.reservationpromoSet && this.props.reservation.reservationpromoSet.edges.length > 0 && this.props.reservation.reservationpromoSet.edges.map((promotionPlan, i) =>
                                        <Col xs={12}>
                                            <Card className="promo-detail-card">
                                                <CardHeader><Promo width={30} fill={"#fffff"} />&nbsp;&nbsp;{promotionPlan.node.title}</CardHeader>
                                                <CardBody><br />
                                                    <Row>
                                                        <Col xs={2}>
                                                            <FormGroup>
                                                                <div className="d-flex justify-content-between">
                                                                    <Label for={"selectedPromoPrice" + i}>Discount {promotionPlan.node.isPercentage ? "%" : "$"}</Label>
                                                                    <i
                                                                        id={"selectedPromoPrice" + i}
                                                                        className="fa fa-pencil-square edit-icon"
                                                                        aria-hidden="true"
                                                                        onClick={() => this.toggleModal("selectedPromoPrice" + i)}
                                                                    />
                                                                </div>
                                                                <Input
                                                                    type="text"
                                                                    name="selectedPromoPrice"
                                                                    value={promotionPlan.node.promoPrice ? promotionPlan.node.promoPrice : ""}
                                                                    disabled
                                                                />
                                                                {this.state.openModal === "selectedPromoPrice" + i &&
                                                                    <UpdateReservationPromotion reservationPlan={promotionPlan.node} title="Edit Promo Price" name={"selectedPromoPrice"} value={promotionPlan.node.promoPrice ? promotionPlan.node.promoPrice : ""} min={promotionPlan.node.minimumPromoPrice} max={promotionPlan.node.maximumPromoPrice} target={"selectedPromoPrice" + i} id={"selectedPromoPrice" + i} type="number" open={this.state.openModal === "selectedPromoPrice" + i} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchReservationQuery} />
                                                                }
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs={2}>
                                                            <FormGroup>
                                                                <div className="d-flex justify-content-between">
                                                                    <Label for={"selectedPromoCycle" + i}>Cycle</Label>
                                                                    <i
                                                                        id={"selectedPromoCycle" + i}
                                                                        className="fa fa-pencil-square edit-icon"
                                                                        aria-hidden="true"
                                                                        onClick={() => this.toggleModal("selectedPromoCycle" + i)}
                                                                    />
                                                                </div>
                                                                <Input
                                                                    type="number"
                                                                    name="selectedPromoCycle"
                                                                    value={promotionPlan.node.cycle ? promotionPlan.node.cycle : ""}
                                                                    disabled
                                                                />
                                                                {this.state.openModal === "selectedPromoCycle" + i &&
                                                                    <UpdateReservationPromotion reservationPlan={promotionPlan.node} title="Edit Cycle" name={"selectedPromoCycle"} value={promotionPlan.node.cycle ? promotionPlan.node.cycle : ""} min={promotionPlan.node.minCycle} max={promotionPlan.node.maxCycle} target={"selectedPromoCycle" + i} id={"selectedPromoCycle" + i} type="number" open={this.state.openModal === "selectedPromoCycle" + i} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchReservationQuery} />
                                                                }
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs={3}>
                                                            <FormGroup>
                                                                <div className="d-flex justify-content-between">
                                                                    <Label for={"selectedPromoStartDate" + i}>Start Date</Label>
                                                                    <i
                                                                        id={"selectedPromoStartDate" + i}
                                                                        className="fa fa-pencil-square edit-icon"
                                                                        aria-hidden="true"
                                                                        onClick={() => this.toggleModal("selectedPromoStartDate" + i)}
                                                                    />
                                                                </div>
                                                                <Input
                                                                    type="date"
                                                                    name="selectedPromoStartDate"
                                                                    value={promotionPlan.node.startDate ? promotionPlan.node.startDate : ""}
                                                                    disabled
                                                                />
                                                                {this.state.openModal === "selectedPromoStartDate" + i &&
                                                                    <UpdateReservationPromotion reservationPlan={promotionPlan.node} title="Edit Cycle" name={"selectedPromoStartDate"} value={promotionPlan.node.startDate ? promotionPlan.node.startDate : ""} min={moment().tz("America/New_York").format(moment.HTML5_FMT.DATE)} max={moment(promotionPlan.node.expirationDate).format(moment.HTML5_FMT.DATE)} target={"selectedPromoStartDate" + i} id={"selectedPromoStartDate" + i} type="date" open={this.state.openModal === "selectedPromoStartDate" + i} handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchReservationQuery} />
                                                                }
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs={3}>
                                                            <FormGroup>
                                                                <Label for={"selectedPromoExpirationDate" + i}>Expiration Date</Label>
                                                                <Input name={"selectedPromoExpirationDate" + i} value={promotionPlan.node.expirationDate ? promotionPlan.node.expirationDate : ""} disabled={true} id={promotionPlan.node.id} type="date" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col className="text-center" xs={2}>
                                                            <Label>Action</Label><br />
                                                            <UncontrolledTooltip target={"promo" + i}>Remove</UncontrolledTooltip>
                                                            <i name="delete" id={"promo" + i} className="fa fa-trash fa-lg text-danger align-self-center" onClick={(e) => this.updateReservationPromoPlan("remove", promotionPlan.node, e)}></i>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ) || <Card className="promo-detail-card"><CardBody style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>No promo has been selected for this reservation!</CardBody></Card> :
                                    this.state.selectedPromotionsTemplates && this.state.selectedPromotionsTemplates.length > 0 && this.state.selectedPromotionsTemplates.map((promotionPlan, i) =>
                                        <Col xs={12}>
                                            <Card className="promo-detail-card">
                                                <CardHeader><Promo width={30} fill={"#fffff"} />&nbsp;&nbsp;{promotionPlan.title}</CardHeader>
                                                <CardBody><br />
                                                    <Row>
                                                        <Col xs={2}>
                                                            <FormGroup>
                                                                <Label for={"selectedPromoPrice" + i}>Discount {promotionPlan.isPercentage ? "%" : "$"}</Label>
                                                                <Input type="number" className="planintervalprice" onChange={(e) => this.updateReservationPromoPlan("edit", i, e)} name={"selectedPromoPrice" + i} id={promotionPlan.id} value={promotionPlan.promoPrice} min={promotionPlan.minimumPromoPrice ? promotionPlan.minimumPromoPrice : promotionPlan.promoPrice ? promotionPlan.promoPrice : null} max={promotionPlan.maximumPromoPrice ? promotionPlan.maximumPromoPrice : promotionPlan.promoPrice ? promotionPlan.promoPrice : null} placeholder="Discount" />
                                                                <FormText>
                                                                    <span style={{ color: "white" }}>{promotionPlan.isPercentage ? "%" : promotionPlan.currency ? promotionPlan.currency.code.toUpperCase() : ''} per {promotionPlan.interval && promotionPlan.interval > 1 ? promotionPlan.interval : ""}{promotionPlan.intervalUnit ? promotionPlan.intervalUnit : ""}
                                                                        {promotionPlan.agreementType && promotionPlan.agreementType.initialChargeInterval == promotionPlan.agreementType.subsequentChargeInterval ? ` every ${promotionPlan.agreementType.chargeFrequency} ${promotionPlan.agreementType.chargeFrequencyUnit}`
                                                                            : promotionPlan.agreementType && promotionPlan.agreementType.initialChargeInterval != promotionPlan.agreementType.subsequentChargeInterval ? ` after first ${promotionPlan.agreementType.initialChargeInterval} ${promotionPlan.agreementType.chargeFrequencyUnit}, afterwards each ${promotionPlan.agreementType.chargeFrequency > 1 ? promotionPlan.agreementType.chargeFrequency : ""}${promotionPlan.agreementType.chargeFrequencyUnit}`
                                                                                : '--'}</span>
                                                                </FormText>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs={2}>
                                                            <FormGroup>
                                                                <Label for={"selectedPromoCycle" + i}>Cycle</Label>
                                                                <Input type="number" className="planintervalprice" onChange={(e) => this.updateReservationPromoPlan("edit", i, e)} name={"selectedPromoCycle" + i} id={promotionPlan.id} value={promotionPlan.cycle} min={promotionPlan.minCycle} max={promotionPlan.maxCycle} placeholder="Plan Cycle" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs={3}>
                                                            <FormGroup>
                                                                <Label for={"selectedPromoStartDate" + i}>Start Date</Label>
                                                                <Input name={"selectedPromoStartDate" + i} defaultValue={moment().tz("America/New_York").format(moment.HTML5_FMT.DATE)} onChange={(e) => this.updateReservationPromoPlan("edit", i, e)} id={promotionPlan.id} min={moment().tz("America/New_York").format(moment.HTML5_FMT.DATE)} max={moment(promotionPlan.expirationDate).format(moment.HTML5_FMT.DATE)} type="date" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xs={3}>
                                                            <FormGroup>
                                                                <Label for={"selectedPromoExpirationDate" + i}>Expiration Date</Label>
                                                                <Input name={"selectedPromoExpirationDate" + i} value={promotionPlan.expirationDate ? promotionPlan.expirationDate : ""} disabled={true} id={promotionPlan.id} type="date" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col className="text-center" xs={2}>
                                                            <Label>Action</Label><br />
                                                            <UncontrolledTooltip target={"promo" + i}>Remove</UncontrolledTooltip>
                                                            <i name="delete" id={"promo" + i} className="fa fa-trash fa-lg text-danger align-self-center" onClick={(e) => this.updateReservationPromoPlan("remove", promotionPlan, e)}></i>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ) || <Col className="p-5 text-center text-danger" xs={12}><p></p></Col>}

                            </Fragment>
                            : this.state.formStep == 3 ?
                                <>
                                    <Row>
                                        <Col className="selection-box">
                                            <Row>
                                                <Col>
                                                    <h5>{this.state.selectedDriver.name}</h5>
                                                    <p className="selection-box-text">{this.state.selectedDriver.tlcLicense} <Link to={`/driver/${this.state.selectedDriver.id}`} target="_blank"><i className="fa fa-external-link grey-link" style={{ color: "#1B4D7E" }}></i></Link></p>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="selection-box">
                                            <Row>
                                                <Col>
                                                    <h5>{this.state.selectedCar.pk} - {this.state.selectedCar.year}  {toTitleCase(this.state.selectedCar.carModel.make)} {toTitleCase(this.state.selectedCar.carModel.name)} <small>{this.state.selectedCar.carModel.series ? `(${this.state.selectedCar.carModel.series})` : ''}</small></h5>
                                                    <p className="selection-box-text">{this.state.selectedCar.color} {this.state.selectedCar.carModel.groupType ? "| " + this.state.selectedCar.carModel.groupType.name : ""} | {this.state.selectedCar.vin} &nbsp;&nbsp;<Link to={"/car/" + this.state.selectedCar.pk} target="_blank"><i className="fa fa-external-link grey-link" style={{ color: "#1B4D7E" }}></i></Link></p>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="m-2">
                                        <Col>
                                            <FormGroup>
                                                <Label>Rental Type</Label>
                                                <p>{this.props.isUpdate ? this.props.reservation && this.props.reservation.agreementType ? this.props.reservation.agreementType.name : "--" : this.state.agreementType ? this.state.agreementType : 'Not Found'}</p>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label>Pickup date</Label>
                                                <p><DatetimeRenderer datetime={this.props.isUpdate ? this.props.reservation.pickupDate : this.state.pickupDate} format={"MMM, D, 'YY h:mm A"} /></p>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label>Deposit Amount</Label>
                                                <p>{this.props.isUpdate ? this.props.reservation && this.props.reservation.depositAmount ? ("$" + this.props.reservation.depositAmount) : "--" : this.state.deposit ? ("$" + this.state.deposit) : '--'}</p>
                                            </FormGroup >
                                        </Col >
                                    </Row >
                                    {this.props.isUpdate ?
                                        this.props.reservation && this.props.reservation.reservationPrice && this.props.reservation.reservationPrice.edges.length > 0 && this.props.reservation.reservationPrice.edges.map((reservationPlan, i) =>
                                            <Row className="plan-detail-card m-2" >
                                                <Col>
                                                    <Label>{i > 0 ? i + " - Future" : "Current"} Plan</Label>
                                                    <p>{reservationPlan.node.title ? reservationPlan.node.title : '--'}</p>
                                                </Col>
                                                <Col >
                                                    <Label>Base Price</Label>
                                                    <p>{reservationPlan.node.price ? ("$" + reservationPlan.node.price) : '--'}<small> Per {reservationPlan.node.interval && reservationPlan.node.interval > 1 ? reservationPlan.node.interval : ""}{reservationPlan.node.intervalUnit ? reservationPlan.node.intervalUnit : ""}</small></p>
                                                </Col>
                                                <Col>
                                                    <Label>Plan Start Date</Label>
                                                    <p><DatetimeRenderer datetime={reservationPlan.node.startDate ? reservationPlan.node.startDate : "--"} /></p>
                                                </Col>
                                            </Row>) :
                                        this.state.selectedPricingTemplates && this.state.selectedPricingTemplates && this.state.selectedPricingTemplates.length > 0
                                        && this.state.selectedPricingTemplates.map((selectedPlans, i) =>
                                            <Row className="plan-detail-card m-2">
                                                <Col>
                                                    <Label>{i > 0 ? i + " - Future" : "Current"} Plan</Label>
                                                    <p>{selectedPlans.title ? selectedPlans.title : '--'}</p>
                                                </Col>
                                                <Col>
                                                    <Label>Base Price</Label>
                                                    <p>{selectedPlans.price ? ("$" + selectedPlans.price) : '--'}<small> Per {selectedPlans.interval && selectedPlans.interval > 1 ? selectedPlans.interval : ""}{selectedPlans.intervalUnit ? selectedPlans.intervalUnit : ""}</small></p>
                                                </Col>
                                                <Col>
                                                    <Label>Plan Start Date</Label>
                                                    <p><DatetimeRenderer datetime={selectedPlans.startDate ? selectedPlans.startDate : "--"} /></p>
                                                </Col>
                                            </Row>
                                        )
                                    }
                                    {this.props.isUpdate ?
                                        this.props.reservation && this.props.reservation.reservationpromoSet && this.props.reservation.reservationpromoSet.edges.length > 0 && this.props.reservation.reservationpromoSet.edges.map((promotionPlan, i) =>
                                            <Row className="promo-detail-card m-2" >
                                                <Col>
                                                    <Label>Promo Plan</Label>
                                                    <p>{promotionPlan.node.title ? promotionPlan.node.title : '--'}</p>
                                                </Col>
                                                <Col >
                                                    <Label>Discount</Label>
                                                    <p>{promotionPlan.node.promoPrice ? ((!promotionPlan.node.isPercentage ? "$" : "") + promotionPlan.node.promoPrice + (promotionPlan.node.isPercentage ? "%" : "")) : '--'}<small> Per {promotionPlan.node.interval && promotionPlan.node.interval > 1 ? promotionPlan.node.interval : ""}{promotionPlan.node.intervalUnit ? promotionPlan.node.intervalUnit : ""}</small></p>
                                                </Col>
                                                <Col>
                                                    <Label>Promo Start Date</Label>
                                                    <p><DatetimeRenderer datetime={promotionPlan.node.startDate ? promotionPlan.node.startDate : "--"} /></p>
                                                </Col>
                                            </Row>) :
                                        this.state.selectedPromotionsTemplates && this.state.selectedPromotionsTemplates && this.state.selectedPromotionsTemplates.length > 0
                                        && this.state.selectedPromotionsTemplates.map((selectedPromotion, i) =>
                                            <Row className="promo-detail-card m-2">
                                                <Col>
                                                    <Label>Promo Plan</Label>
                                                    <p>{selectedPromotion.title ? selectedPromotion.title : '--'}</p>
                                                </Col>
                                                <Col>
                                                    <Label>Discount</Label>
                                                    <p>{selectedPromotion.promoPrice ? ((!selectedPromotion.isPercentage ? "$" : "") + selectedPromotion.promoPrice + (selectedPromotion.isPercentage ? "%" : "")) : '--'}<small> Per {selectedPromotion.interval && selectedPromotion.interval > 1 ? selectedPromotion.interval : ""}{selectedPromotion.intervalUnit ? selectedPromotion.intervalUnit : ""}</small></p>
                                                </Col>
                                                <Col>
                                                    <Label>Promo Start Date</Label>
                                                    <p><DatetimeRenderer datetime={selectedPromotion.startDate ? selectedPromotion.startDate : "--"} /></p>
                                                </Col>
                                            </Row>
                                        )
                                    }
                                </>
                                : ""

                    }
                </ModalBody>
                <ModalFooter>
                    {(this.state.error || this.state.errorMessage) && <Row><Col xs={12}><Alert color="danger">{this.state.error || this.state.errorMessage}</Alert></Col></Row>}
                    {(this.props.reservationLoading || this.props.loading || this.state.loading) && <Progress animated color="info" value="100" />}
                    <Row>
                        {this.props.isUpdate && (this.state.formStep && this.state.formStep == 1 && this.props.reservation ?
                            <>
                                <Col xs={6} className="text-left">
                                    <AddToDidntTakeCar open={this.state.openModal === "AddToDidntTakeCar"} handleClose={() => this.setState({ openModal: "" })} driverId={this.props.reservation.driver.id} refetchQuery={this.props.refetchReservationQuery} />
                                    <Button color="danger" className="reservation-modal-btn-secondary" onClick={() => this.setState({ openModal: "AddToDidntTakeCar" })}> Remove</Button>
                                </Col>
                                <Col xs={6} className="text-right">
                                    <Button outline color="secondary" className="reservation-modal-btn-secondary" onClick={this.handleClose}>Close</Button>
                                    {this.props.reservation.car && this.props.reservation.car.missingRentalDocuments && this.props.reservation.car.missingRentalDocuments.length > 0 ?
                                        <>
                                            {this.state.openModal === "uploadCarDocumentModal" && <UploadDocumentModal handleClose={() => this.toggleModal("")} open={this.state.openModal === "uploadCarDocumentModal"} id={this.props.reservation.car.id} refetchQuery={this.props.refetchQuery} objectType="car" />}
                                            <Badge className="float-right" color="danger" style={{ whiteSpace: "pre-wrap" }} > Unable to run pickup. Following car documents are missing {this.props.reservation.car.missingRentalDocuments.map(item => item.typeName).toString()}.</Badge>
                                            <Button className="reservation-modal-btn-primary float-right" onClick={() => this.toggleModal("uploadCarDocumentModal")}>Upload Car Docs</Button>
                                        </>
                                        : this.props.reservation.driver && this.props.reservation.driver.missingRentalDocuments && this.props.reservation.driver.missingRentalDocuments.length > 0 ?
                                            <>
                                                {this.state.openModal === "uploadDriverDocumentModal" && <UploadDocumentModal handleClose={() => this.toggleModal("")} open={this.state.openModal === "uploadDriverDocumentModal"} id={this.props.reservation.driver.id} refetchQuery={this.props.refetchQuery} objectType="driver" />}
                                                <Badge className="float-right" color="danger" style={{ whiteSpace: "pre-wrap" }} > Unable to run pickup. Following driver documents are missing {this.props.reservation.driver.missingRentalDocuments.map(item => item.typeName).toString()}.</Badge>
                                                <Button className="reservation-modal-btn-primary float-right" onClick={() => this.toggleModal("uploadDriverDocumentModal")}>Upload Driver Docs</Button>
                                            </>
                                            : <Button className="float-right reservation-modal-btn-primary" onClick={() => this.handleStep(2)} disabled={!this.state.selectedCar}>Next: Price Config</Button>
                                    }&nbsp;&nbsp;
                                </Col>
                            </> :
                            !this.props.reservationLoading && this.state.formStep && this.state.formStep == 2 && this.props.reservation ?
                                <>
                                    <Col xs={6}>
                                        <Button className="float-left reservation-modal-btn-secondary" outline color="secondary" onClick={() => this.handleStep(1)}>Back: Basic Config</Button>
                                    </Col>
                                    <Col xs={6} className="text-right">
                                        <Button outline color="secondary" className="reservation-modal-btn-secondary" onClick={this.handleClose}>Close</Button>&nbsp;&nbsp;
                                        <Button className="reservation-modal-btn-primary" onClick={() => this.handleStep(3)}>Next: Review Config</Button>
                                    </Col>
                                </> :
                                !this.props.reservationLoading && this.state.formStep && this.state.formStep == 3 && this.props.reservation ?
                                    <>
                                        {!this.props.reservation.isSplit && this.props.reservation.car && this.props.reservation.car.currentAgreement && <Col xs={12}> <Alert color="danger"> Unable to run pickup for this car. An active agreement exist for this car.</Alert> </Col>}
                                        {!this.props.reservation.isSplit && this.props.reservation.driver && this.props.reservation.driver.currentAgreement && this.props.reservation.driver.currentAgreement.id && <Col xs={12}> <Alert color="danger"> Unable to run pickup for this driver. An active agreement exist for this driver.</Alert> </Col>}
                                        {this.props.reservation.car && this.props.reservation.car.unresolvedCarIssues && this.props.reservation.car.unresolvedCarIssues.length > 0 && this.props.reservation.car.unresolvedCarIssues.filter(issue => !issue.canPickup || issue.canPickup == false).length > 0
                                            && <Col xs={12}> <Alert color="danger">Unable to run the pickup for this car. Open issues found including {this.props.reservation.car.unresolvedCarIssues.filter(issue => !issue.canPickup || issue.canPickup == false).map(({ issueCategory }) => issueCategory.category).join(", ")}. Please resolve these issues. </Alert> </Col>}
                                        <Col xs={6}>
                                            <Button className="float-left reservation-modal-btn-secondary" outline color="secondary" onClick={() => this.handleStep(2)}>Back: Price Config </Button>
                                        </Col>
                                        <Col xs={6} className="text-right">
                                            <Button outline color="secondary" className="reservation-modal-btn-secondary" onClick={this.handleClose}>Close</Button>&nbsp;&nbsp;
                                            <Button className="reservation-modal-btn-primary" onClick={() => this.setState({ openModal: "RunNewPickupModal" })} disabled={
                                                (this.props.reservation.driver && this.props.reservation.driver.missingRentalDocuments && this.props.reservation.driver.missingRentalDocuments.length > 0)
                                                || (!this.props.reservation.isSplit && (this.props.reservation.car.currentAgreement || (this.props.reservation.driver && this.props.reservation.driver.currentAgreement && this.props.reservation.driver.currentAgreement.id)))
                                                || (this.props.reservation.car.unresolvedCarIssues && this.props.reservation.car.unresolvedCarIssues.length > 0 && this.props.reservation.car.unresolvedCarIssues.filter(issue => !issue.canPickup || issue.canPickup == false).length > 0)
                                            }>
                                                Run Pickup
                                            </Button>&nbsp;&nbsp;
                                            {this.state.openModal === "RunNewPickupModal" && <PickupModal reservationId={this.props.reservation.id} driverId={this.props.reservation && this.props.reservation.driver ? this.props.reservation.driver.id : null} agreementTypeId={this.props.reservation && this.props.reservation.agreementType ? this.props.reservation.agreementType.id : null} open={this.state.openModal === "RunNewPickupModal"} handleClose={this.handleClose} refetchQuery={this.props.refetchQuery} />}
                                        </Col>
                                    </> : "")
                        }
                        {!this.props.isUpdate && (this.state.formStep == 1 ?
                            <>
                                <Col xs={6}>
                                    {
                                        this.state.selectedDriver && this.state.agreementType && !this.state.disabledMessage && !this.state.selectedCar ?
                                            <Button className="reservation-modal-btn-primary" onClick={this.createReservation}>{this.state.disabledMessage ? this.state.disabledMessage : "Reserve Without Car"}</Button> :
                                            <Button className="reservation-modal-btn-primary" title="Make sure the driver & agreement type are selected " disabled>{this.state.disabledMessage ? this.state.disabledMessage : "Reserve Without Car"}</Button>
                                    }
                                </Col>
                                <Col className="text-right">
                                    <Button className="float-right reservation-modal-btn-secondary" outline color="secondary" onClick={this.handleClose}>Close</Button>&nbsp;&nbsp;
                                    <Button className="reservation-modal-btn-primary" onClick={() => this.handleStep(2)} disabled={this.state.loading || !this.state.selectedDriver || !this.state.agreementType || this.state.disabledMessage || !this.state.selectedCar || (this.state.deposit === null || this.state.deposit === "")}>Next: Price Config</Button>&nbsp;&nbsp;
                                </Col>
                            </>
                            :
                            !this.props.reservationLoading && this.state.formStep && this.state.formStep == 2 ?
                                <>
                                    <Col>
                                        <Button className="float-left reservation-modal-btn-secondary" outline color="secondary" onClick={() => this.handleStep(1)}>Back: Basic Config</Button>&nbsp;&nbsp;
                                        {!this.props.isUpdate && this.props.hasPermissions && this.props.hasPermissions.includes("custom_can_ignore_missing_car_docs") &&
                                            <span>
                                                <Label className="pt-2 pl-2" for="ignoreMissingCarDocs" >ignore missing car docs</Label>
                                                <Input
                                                    type="checkbox"
                                                    id="ignoreMissingCarDocs"
                                                    checked={this.state.ignoreMissingCarDocs}
                                                    onChange={() => this.setState({ ignoreMissingCarDocs: !this.state.ignoreMissingCarDocs })}
                                                    style={{ transform: 'scale(1.2)', marginLeft: "7px", marginTop: "10px" }}
                                                />
                                            </span>
                                        }
                                    </Col>
                                    <Col xs={6} className="text-right">
                                        <Button className="float-right reservation-modal-btn-secondary" outline color="secondary" onClick={this.handleClose}>Close</Button>&nbsp;&nbsp;
                                        <Button className="reservation-modal-btn-primary" onClick={() => this.handleStep(3)}>Next: Review Config</Button>&nbsp;&nbsp;
                                    </Col>
                                </>
                                :
                                !this.props.reservationLoading && this.state.formStep && this.state.formStep == 3 ?
                                    <>
                                        <Col xs={6}>
                                            <Button className="float-left reservation-modal-btn-secondary" outline color="secondary" onClick={() => this.handleStep(2)}>Back: Price Config </Button>
                                        </Col>
                                        <Col xs={6} className="text-right">
                                            <Button className="float-right reservation-modal-btn-secondary" outline color="secondary" onClick={this.handleClose}>Close</Button>&nbsp;&nbsp;
                                            <Button disabled={this.state.loading} className="reservation-modal-btn-primary" onClick={this.createReservation}>
                                                {this.state.disabledMessage ? this.state.disabledMessage : this.state.selectedCar ? "Create Reservation" : "Send Link"}
                                            </Button>&nbsp;&nbsp;
                                        </Col>
                                    </> : "")
                        }
                    </Row>
                </ModalFooter>
            </Modal >
        )
    }
}

export default compose(
    withApollo,
    graphql(AllCarLocations, { props({ data: { allCarLocations, loading } }) { return { allCarLocationsLoading: loading, allCarLocations } } }),
    graphql(AllCarModelsQuery, { options: () => ({ variables: { carStages: [8, 9, 10, 11], hasReturnDate: true, hasReservation: false, orderBy: ["-make"] } }), props({ data: { loading, allCarModels } }) { return { allCarModelsLoading: loading, allCarModels } } }),
    graphql(AllAgreementTypes, { props({ data: { loading, allAgreementTypes } }) { return { allAgreementTypesLoading: loading, allAgreementTypes } } }),
    graphql(ReservationPickupTypes, { props({ data: { __type: reservationPickupTypes } }) { return { reservationPickupTypes } } }),
    graphql(AgreementTypeDepositSchemes, { props({ data: { __type: agreementTypeDepositSchemes } }) { return { agreementTypeDepositSchemes } } }),
    graphql(ReservationQuery, {
        skip: ({ id }) => id ? false : true,
        options: ({ id }) => ({
            variables: { id },
            fetchPolicy: "network-only",
        }),
        props({ data: { reservation, loading, fetchMore, variables } }) {
            return {
                reservation, reservationLoading: loading,
                refetchReservationQuery: () => {
                    //No apollo function that refetches in place with pagination considered so this function instead
                    return fetchMore({
                        query: ReservationQuery,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return {
                                reservation: fetchMoreResult.reservation
                            }
                        },
                    })
                },
            }
        }
    }),
    graphql(HasPermissionsQuery, {
        options: () => ({
            variables: {
                userPermissions: [
                    "custom_can_update_deposit_amount",
                    "custom_can_ignore_missing_car_docs",
                    "custom_can_skip_validations"
                ]
            }
        }),
        props({ data: { hasPermissions, loading, variables } }) {
            return { hasPermissions, loading, variables }
        }
    })
)(ReservationModalNew)

