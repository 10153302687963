import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import './PaymentPlan.css'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, Row, Progress, Alert, UncontrolledTooltip } from 'reactstrap'
import UpdatePaymentPlanInfo from './UpdatePaymentPlanInfo'
import moment from 'moment'
import DatetimeRenderer from "../../Material/DatetimeRenderer"
import Label from "reactstrap/lib/Label"
import Badge from "reactstrap/lib/Badge"
import ConfirmationPopup from "../../Material/ConfirmationPopup"
import { HasPermissionsQuery } from "../../Functions/querys"
import toTitleCase from '../../Functions/toTitleCase'


const PaymentPlanQuery = gql`query PaymentPlanQuery($id: ID!,) {
    paymentPlan(id:$id){
        id
        dateAdded
        paydownAmount
        paydownOriginalBalance
        paydownStartDate
        paydownNotes
        reason
        status
        history {
            date
            user
            comment
        }
        addedBy{
            id
            username
        }
        inputTransactions {
            edges {
                node {
                    id
                    pk
                    dueDate
                    amount
                    updateFor {
                        id
                        updateForPK: pk
                    }
                  	chargeType {
                  	  id
                      name
                  	}
                    agreement{
                        id
                        startDate
                        endDate
                        car {
                            id
                            carPK: pk
                        } 
                    }
                    groupType{
                        id
                        name
                    }
                }
            }
        }
        resultantTransactions {
            edges {
                node {
                    id
                    pk
                    dueDate
                    amount
                    updateFor {
                        id
                        updateForPK: pk
                    }
                  	chargeType {
                  	  id
                      name
                  	}
                    agreement{
                        id
                        startDate
                        endDate
                        car {
                            id
                            carPK: pk
                        } 
                    }
                    groupType{
                        id
                        name
                    }
                }
            }
        }
    } 
}`

const RemovePaymentPlan = gql`
    mutation removePaymentPlan($input: RemovePaymentPlanMutationInput!){
    removePaymentPlan(input:$input){
        ok
        errors{
          messages
        }
    }
} `
class PaymentPlanInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            loading: false,
            openSection: [],
            openModal: "",
            deactivatedPaymentPlan: false,
        }
    }

    handleClose = (e) => {
        this.props.handleClose()
    };

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    };

    toggleSection = (section) => {
        if (this.state.openSection.includes(section)) {
            this.setState({ openSection: this.state.openSection.filter((sec) => sec !== section) })
        } else {
            this.setState({ openSection: [...this.state.openSection, section] })
        }
    }

    deactivatePaydown = (paymentPlanId) => {
        if (paymentPlanId) {
            this.setState({ loading: true })
            let input = { "id": paymentPlanId }
            this.props.client.mutate({
                mutation: RemovePaymentPlan,
                variables: { input },
            }).then((result) => {
                if (result && result.data && result.data.removePaymentPlan && result.data.removePaymentPlan.ok) {
                    this.props.refetchQuery()
                    this.setState({ input: {}, loading: false, error: null, deactivatedPaymentPlan: true })
                } else if (result.data && result.data.removePaymentPlan.errors && result.data.removePaymentPlan.errors.length > 0) {
                    this.setState({ loading: false, error: result.data.removePaymentPlan.errors[0].messages[0], deactivatedPaymentPlan: false })
                }
            }).catch((err) => {
                this.setState({ loading: false, error: err, deactivatedPaymentPlan: false })
            })
        } else {
            this.setState({ error: "Missing Required Field ID" })
        }
    }

    render() {
        return (
            <Modal isOpen={this.props.open} className="payment-plan">
                <ModalHeader toggle={this.toggle}>Update Payment Plan</ModalHeader>
                <ModalBody>
                    {this.state.error && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                    {this.state.loading ?
                        <Row><Col xs={12}><Progress animated color="info" value="100" /></Col></Row> :
                        <>
                            <Row>
                                <Col xs="4">
                                    <Label>Date Added</Label>
                                    <p>{this.props.paymentPlan && this.props.paymentPlan.dateAdded ? <DatetimeRenderer datetime={this.props.paymentPlan.dateAdded} format={moment.HTML5_FMT.DATE} /> : ""}</p>
                                </Col>
                                <Col xs="4">
                                    <Label>Created By</Label>
                                    <p>{this.props.paymentPlan && this.props.paymentPlan.addedBy ? this.props.paymentPlan.addedBy.username : "--"}</p>
                                </Col>
                                <Col xs="4">
                                    <Label>Status</Label><br />
                                    <Badge style={{ background: this.props.paymentPlan && this.props.paymentPlan.status && this.props.paymentPlan.status === "A_1" ? "green" : "red", padding: "5px" }}>{this.props.paymentPlan && this.props.paymentPlan.status && this.props.paymentPlan.status === "A_1" ? "Active" : "Inactive"}</Badge>
                                </Col>
                            </Row><br />
                            <Row>
                                <Col xs="4">
                                    <Label>Balance Left</Label>
                                    <p>${this.props.paymentPlan ? this.props.paymentPlan.paydownOriginalBalance : ""}</p>
                                </Col>

                                <Col xs="4">
                                    <Label>Total Payment</Label>
                                    <p>${this.props.paymentPlan ? this.props.paymentPlan.paydownOriginalBalance : ""}</p>
                                </Col>
                                <Col xs="4">
                                    <Label>Weekly Amount</Label>
                                    <p>${this.props.paymentPlan ? this.props.paymentPlan.paydownAmount : ""}</p>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col xs="4">
                                    <Label>Start Date</Label>
                                    <p>{this.props.paymentPlan && this.props.paymentPlan.paydownStartDate ? <DatetimeRenderer datetime={this.props.paymentPlan.paydownStartDate} format={moment.HTML5_FMT.DATE} /> : ""}</p>
                                </Col>
                                <Col xs="4">
                                    <Label id={"reasonEdit"} onClick={() => this.toggleModal("reasonEdit")}>Reason <i className="fa fa-pencil-square" aria-hidden="true"> </i></Label>
                                    <p>{this.props.paymentPlan && this.props.paymentPlan.reason ? toTitleCase(this.props.paymentPlan.reason) : "--"}</p>
                                    {this.state.openModal === "reasonEdit" &&
                                        <UpdatePaymentPlanInfo paymentPlan={this.props.paymentPlan} title="Edit Reason" name="reason" value={this.props.paymentPlan ? toTitleCase(this.props.paymentPlan.reason) : ""}
                                            target={"reasonEdit"} id="reason" type="text" open={this.state.openModal === "reasonEdit"}
                                            handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchQuery}
                                        />
                                    }
                                </Col>
                                <Col xs="4">
                                    <Label id={"paydownNotesEdit"} onClick={() => this.toggleModal("paydownNotesEdit")}>Notes <i className="fa fa-pencil-square" aria-hidden="true"> </i></Label>
                                    <p>{this.props.paymentPlan && this.props.paymentPlan.paydownNotes ? this.props.paymentPlan.paydownNotes : "--"}</p>
                                    {this.state.openModal === "paydownNotesEdit" &&
                                        <UpdatePaymentPlanInfo paymentPlan={this.props.paymentPlan} title="Edit Notes" name="paydownNotes" value={this.props.paymentPlan ? this.props.paymentPlan.paydownNotes : ""}
                                            target={"paydownNotesEdit"} id="paydownNotes" type="text" open={this.state.openModal === "paydownNotesEdit"}
                                            handleClose={() => this.toggleModal("")} refetchQuery={this.props.refetchQuery}
                                        />
                                    }
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    <Label className={`toggle-section-heading ${this.state.openSection.includes("inputTransactions") ? "active" : ""}`} onClick={() => this.toggleSection("inputTransactions")}>Input Transactions {this.state.openSection.includes("inputTransactions") ? <i className="fa fa-minus" aria-hidden="true"></i> : <i className="fa fa-plus" aria-hidden="true"></i>}</Label>
                                    {this.state.openSection.includes("inputTransactions") && <>
                                        <Row>
                                            <Col><Label>ID</Label></Col>
                                            <Col><Label>Car ID</Label></Col>
                                            <Col><Label>Type</Label></Col>
                                            <Col><Label>Amount</Label></Col>
                                            <Col><Label>Due Date</Label></Col>
                                        </Row>
                                        {this.props.paymentPlan && this.props.paymentPlan.inputTransactions && this.props.paymentPlan.inputTransactions.edges && this.props.paymentPlan.inputTransactions.edges.length > 0 ? this.props.paymentPlan.inputTransactions.edges.map(inputTransaction =>
                                            <>
                                                <Row>
                                                    <Col><p>
                                                        {inputTransaction.node.pk}
                                                        {inputTransaction.node.updateFor &&
                                                            <><br /><small style={{ opacity: 0.9, fontSize: "0.9rem", textDecoration: "line-through" }}>{inputTransaction.node.updateFor.updateForPK}</small></>
                                                        }
                                                    </p></Col>
                                                    <Col>
                                                        <p>
                                                            {inputTransaction.agreement && inputTransaction.agreement.car && inputTransaction.agreement.car.carPK ? inputTransaction.agreement.car.carPK : "--"}
                                                            {inputTransaction.node.agreement && (inputTransaction.node.agreement.startDate || inputTransaction.node.agreement.endDate) &&
                                                                <><br /><small style={{ opacity: 0.9, fontSize: "0.9rem", cursor: "pointer" }}>{inputTransaction.node.agreement.startDate ? <DatetimeRenderer datetime={inputTransaction.node.agreement.startDate} /> : "--"} - {inputTransaction.node.agreement.endDate ? <DatetimeRenderer datetime={inputTransaction.node.agreement.endDate} /> : "--"}</small></>
                                                            }
                                                        </p>
                                                    </Col>
                                                    <Col>
                                                        <p>
                                                            {inputTransaction.node.chargeType ? inputTransaction.node.chargeType.name : "--"}
                                                            <small>{inputTransaction.node.groupType ? " (" + inputTransaction.node.groupType.name + ")" : ''}</small>
                                                        </p>
                                                    </Col>
                                                    <Col><p>${inputTransaction.node.amount}</p>
                                                    </Col>
                                                    <Col><p><DatetimeRenderer datetime={inputTransaction.node.dueDate} format="ddd, MMM D, YYYY" /></p></Col>
                                                </Row><hr />
                                            </>
                                        ) : <Row><Col><p>No Input Transactions Found!</p></Col></Row>
                                        }
                                    </>}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label className={`toggle-section-heading ${this.state.openSection.includes("resultantTransactions") ? "active" : ""}`} onClick={() => this.toggleSection("resultantTransactions")}>Resultant Transactions {this.state.openSection.includes("resultantTransactions") ? <i className="fa fa-minus" aria-hidden="true"></i> : <i className="fa fa-plus" aria-hidden="true"></i>}</Label>
                                    {this.state.openSection.includes("resultantTransactions") && <>
                                        <Row>
                                            <Col><Label>ID</Label></Col>
                                            <Col><Label>Car ID</Label></Col>
                                            <Col><Label>Type</Label></Col>
                                            <Col><Label>Amount</Label></Col>
                                            <Col><Label>Due Date</Label></Col>
                                        </Row>
                                        {this.props.paymentPlan && this.props.paymentPlan.resultantTransactions && this.props.paymentPlan.resultantTransactions.edges && this.props.paymentPlan.resultantTransactions.edges.length > 0 ? this.props.paymentPlan.resultantTransactions.edges.map(resultantTransaction =>
                                            <>
                                                <Row>
                                                    <Col><p>
                                                        {resultantTransaction.node.pk}
                                                        {resultantTransaction.node.updateFor &&
                                                            <><br /><small style={{ opacity: 0.9, fontSize: "0.9rem", textDecoration: "line-through" }}>{resultantTransaction.node.updateFor.updateForPK}</small></>
                                                        }
                                                    </p></Col>
                                                    <Col>
                                                        <p>
                                                            {resultantTransaction.agreement && resultantTransaction.agreement.car && resultantTransaction.agreement.car.carPK ? resultantTransaction.agreement.car.carPK : "--"}
                                                            {resultantTransaction.node.agreement && (resultantTransaction.node.agreement.startDate || resultantTransaction.node.agreement.endDate) &&
                                                                <><br /><small style={{ opacity: 0.9, fontSize: "0.9rem", cursor: "pointer" }}>{resultantTransaction.node.agreement.startDate ? <DatetimeRenderer datetime={resultantTransaction.node.agreement.startDate} /> : "--"} - {resultantTransaction.node.agreement.endDate ? <DatetimeRenderer datetime={resultantTransaction.node.agreement.endDate} /> : "--"}</small></>
                                                            }
                                                        </p>
                                                    </Col>
                                                    <Col>
                                                        <p>
                                                            {resultantTransaction.node.chargeType ? resultantTransaction.node.chargeType.name : "--"}
                                                            <small>{resultantTransaction.node.groupType ? " (" + resultantTransaction.node.groupType.name + ")" : ''}</small>
                                                        </p>
                                                    </Col>
                                                    <Col><p>${resultantTransaction.node.amount}</p>
                                                    </Col>
                                                    <Col><p><DatetimeRenderer datetime={resultantTransaction.node.dueDate} format="ddd, MMM D, YYYY" /></p></Col>
                                                </Row><hr />
                                            </>
                                        ) : <Row><Col><p>No Resultant Transactions Found!</p></Col></Row>
                                        }
                                    </>}
                                </Col>
                            </Row>
                            <Row style={{ maxHeight: "300px", overflow: "auto" }}>
                                <Col>
                                    <Label className={`toggle-section-heading ${this.state.openSection.includes("paymentPlanHistory") ? "active" : ""}`} onClick={() => this.toggleSection("paymentPlanHistory")}>Payment Plan Update History{this.state.openSection.includes("paymentPlanHistory") ? <i className="fa fa-minus" aria-hidden="true"></i> : <i className="fa fa-plus" aria-hidden="true"></i>}</Label>
                                    {this.state.openSection.includes("paymentPlanHistory") && <>
                                        <Row>
                                            <Col xs={4}><Label>Date Modified</Label></Col>
                                            <Col xs={3}><Label>Username</Label></Col>
                                            <Col xs={5}><Label>Comment</Label></Col>
                                        </Row>
                                        {this.props.paymentPlan && this.props.paymentPlan.history ? this.props.paymentPlan.history.map(item =>
                                            <>
                                                <Row>
                                                    <Col xs={4}><p><DatetimeRenderer datetime={item.date} /></p></Col>
                                                    <Col xs={3}><p>{item.user}</p></Col>
                                                    <Col xs={5}><p>{item.comment}</p></Col>
                                                </Row><hr />
                                            </>
                                        ) : <Row><Col><p>No History Found!</p></Col></Row>
                                        }
                                    </>}
                                </Col>
                            </Row>
                        </>
                    }
                </ModalBody>
                <ModalFooter>
                    <Col xs={2} className="p-0 text-left">
                        {this.props.paymentPlan && this.props.paymentPlan.status && this.props.paymentPlan.status === "A_1" &&
                            <>
                                <Button className="payment-plan-modal-danger-btn" onClick={() => this.toggleModal("deactivatePaydown")} id={"deletePaymentPlan"} disabled={!(this.props.hasPermissions && this.props.hasPermissions.includes("delete_paymentplan"))}>
                                    Deactivate
                                </Button>
                                {!(this.props.hasPermissions && this.props.hasPermissions.includes("delete_paymentplan")) && <UncontrolledTooltip target={"deletePaymentPlan"}>Don't have enough permission to perform this action</UncontrolledTooltip>}
                            </>
                        }
                    </Col>
                    <Col xs={10} className="p-0 text-right">
                        <Button className="payment-plan-modal-secondary-btn" onClick={this.handleClose}>Cancel</Button>
                    </Col>
                    {this.state.openModal === "deactivatePaydown" &&
                        <ConfirmationPopup
                            open={this.state.openModal === "deactivatePaydown"}
                            message={"Are you sure you want to delete this payment plan?"}
                            loading={this.state.loading}
                            handleClose={() => this.toggleModal("")}
                            action={"Yes"}
                            confirmAction={() => this.deactivatePaydown(this.props.paymentPlan ? this.props.paymentPlan.id : null)}
                            color="danger"
                            error={this.state.error ? this.state.error : ""}
                            success={this.state.deactivatedPaymentPlan ? "Payment plan deleted successfully" : ""}
                        />
                    }
                </ModalFooter>
            </Modal>
        )
    }
}

export default compose(
    withApollo,
    graphql(PaymentPlanQuery, {
        options: ({ id }) => ({ variables: { id: id }, fetchPolicy: "cache-and-network", notifyOnNetworkStatusChange: true }),
        props({ data: { paymentPlan, loading, refetch, variables } }) {
            return {
                paymentPlan, loading, variables,
                refetchQuery: () => {
                    return refetch({
                        query: PaymentPlanQuery,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { paymentPlan: fetchMoreResult.paymentPlan }
                        },
                    })
                },
            }
        }
    }),
    graphql(HasPermissionsQuery, {
        options: () => ({ variables: { userPermissions: ["delete_paymentplan"] }, fetchPolicy: "cache-and-network", notifyOnNetworkStatusChange: true }),
        props({ data: { hasPermissions, variables } }) {
            return { hasPermissions, variables }
        }
    })
)(PaymentPlanInfo)