import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown'
import { compose, graphql, withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import {
    Row, Col, Alert, Button,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'
import './NotificationsList.css'
import StyledToast from '../Components/StyledToast'
import { UserQuery } from "../Functions/querys"

const MarkNotificationReadBulk = gql`
mutation markNotificationReadBulk($notifications:[ID]!){
    markNotificationReadBulk(input:{notifications:$notifications,notificationType:"UserNotification"}){
    ok
    errors{
        messages
        field
      }
  }
}`

const NotificationsQuery = gql`query NotificationsQuery($cursor: String,$first:Int!,$orderBy: [String],$userId:ID!){
    notifications: allUserNotifications(first:$first,orderBy:$orderBy, after: $cursor,userId:$userId){
      edges{
        cursor
        node{
            id
            message
            title
            isRead
            dateAdded
        }
      }
      pageInfo{
          endCursor
          hasNextPage
      }
    } }`


class NotificationsList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            markedSuccessfully: false
        }
    }
    handleClose = (e) => {
        //Reset state
        let state = {
        }
        this.setState({ ...state })
        this.props.handleClose()
    };
    markAllAsRead = () => {
        try {
            this.setState({ loading: true })
            let input = { notifications: this.props.notifications && this.props.notifications.edges && this.props.notifications.edges.map(n => n.node.id) }
            this.props.client.mutate({
                mutation: MarkNotificationReadBulk,
                variables: input,
                refetchQueries: [{ query: UserQuery }],
            }).then((result) => {
                this.setState({ loading: false })
                if (result.data && result.data.markNotificationReadBulk && result.data.markNotificationReadBulk.ok) {
                    this.setState({ markedSuccessfully: true })
                    this.handleClose && this.handleClose()
                } else {
                    this.setState({ loading: false, markedSuccessfully: false, error: "An error has occured. Please contact system admin" })
                }
            }).catch((error) => {
                let errorMessage = "An error has occured"
                this.setState({ error: errorMessage, loading: false, markedSuccessfully: false })
            })
        } catch (err) {
            let errorMessage = "An error has occured"
            this.setState({ error: errorMessage, loading: false, markedSuccessfully: false })
        }
    };

    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        // To change string inputs to boolean because only strings can be passed to html options
        if (e.target.dataset.type && e.target.dataset.type === "boolean") {
            if (value === "false") {
                value = false
            } else {
                value = true
            }
        }
        this.setState({ [name]: value })
    };

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    render() {
        return (
            <Modal className="NotificationsListModal" isOpen={this.props.open} toggle={this.props.handleClose}>
                <ModalHeader>Hi, you have {this.props.currentUser && this.props.currentUser.unreadNotificationsCount ? this.props.currentUser.unreadNotificationsCount : "no"} unread notifications <i onClick={() => { this.markAllAsRead(); this.handleClose() }} className="fa fa-times-circle" aria-hidden="true"> </i></ModalHeader>
                <ModalBody>
                    {this.state.error && !this.state.markedSuccessfully && <Row><Col xs={12}><Alert color="danger">{this.state.error}</Alert></Col></Row>}
                    <Row>
                        {this.props.notifications && this.props.notifications.edges && this.props.notifications.edges.map((notification, i) =>
                            <Col xs={12}>
                                <StyledToast className={`notificationContent ${!notification.node.isRead && "unRead"}`} title={notification.node.title ? notification.node.title : "--"}><ReactMarkdown source={notification.node.message ? notification.node.message : "--"} /></StyledToast>
                            </Col>
                        )}
                    </Row>
                </ModalBody>
                <ModalFooter>
                    {!this.state.loading &&
                        <Row>

                            <Col xs={12}>
                                {this.props.notifications && this.props.notifications.edges && this.props.notifications.pageInfo && this.props.notifications.pageInfo.hasNextPage &&
                                    <><Button size="sm" onClick={this.props.loadMoreEntries} outline color="secondary" >Show More...</Button>&nbsp;&nbsp;</>
                                }
                                <Button size="sm" outline color="secondary" onClick={this.markAllAsRead}>Mark as read</Button>&nbsp;&nbsp;
                                <Button size="sm" outline color="secondary" onClick={() => { this.markAllAsRead(); this.handleClose() }}>Close</Button>&nbsp;&nbsp;
                            </Col>
                        </Row>
                    }
                </ModalFooter>
            </Modal>
        )
    }
}

export default compose(
    withApollo,
    graphql(NotificationsQuery, {
        options: ({ currentUser }) => ({
            variables: {
                orderBy: ["is_read", "-date_added"],
                first: 10,
                userId: currentUser ? currentUser.id : null,
            }
        }),
        skip: ({ currentUser }) => !currentUser,
        // This function re-runs every time `data` changes, including after `updateQuery`,
        // meaning our loadMoreEntries function will always have the right cursor
        props({ data: { loading, notifications, fetchMore, variables } }) {
            return {
                loading,
                notifications,
                variables,
                refetchNotificationsQuery: () => {
                    //No apollo function that refetches in place with pagination considered so this function instead
                    let currentLength = notifications && notifications.edges ? notifications.edges.length : 30
                    return fetchMore({
                        query: NotificationsQuery,
                        variables: {
                            ...variables,
                            first: currentLength,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.notifications.edges
                            const pageInfo = fetchMoreResult.notifications.pageInfo
                            return {
                                notifications: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    __typename: previousResult.notifications.__typename
                                },
                            }
                        },
                    })
                },
                loadMoreEntries: () => {
                    return fetchMore({
                        query: NotificationsQuery,
                        variables: {
                            cursor: notifications.pageInfo.endCursor,
                            ...variables
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.notifications.edges
                            const pageInfo = fetchMoreResult.notifications.pageInfo

                            return {
                                // Put the new comments at the end of the list and update `pageInfo`
                                // so we have the new `endCursor` and `hasNextPage` values
                                notifications: {
                                    edges: [...previousResult.notifications.edges, ...newEdges],
                                    pageInfo,
                                    searchTerm: previousResult.searchTerm,
                                    __typename: previousResult.notifications.__typename
                                },
                            }
                        },
                    })
                },
            }
        },
    }),
)(NotificationsList)