import React, { Component } from 'react'
import { compose, graphql } from 'react-apollo'
import { withApollo } from 'react-apollo/index'
import { Container, Row, Col, Button, Nav, NavItem } from 'reactstrap'
import CarsList from '../CarsList'
import ReturnsList from '../ReturnsList'
import '../CarsList.css'
import moment from 'moment'
import { TagsQuery } from "../../Functions/querys"
import AvailableCarsHeader from "./AvailableCarsHeader"

const tabs = [
    { id: "#avaialble", name: "Available Cars", filters: { isReady: true, orderBy: ["-id"] } }, { id: "#scheduled", name: "Scheduled Returns", filters: { returnType: "Confirmed Returns", orderBy: ["-id"] } },
]

class AvailableCarsContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeTab: null,
            selectedIds: [],
            refetchCars: false,
            filterValues: {
                isReady: true,
                orderBy: ["-id"]
            }
        }
    }

    updateOrder = (string) => {
        let order = this.state.filterValues && this.state.filterValues.orderBy ? [...this.state.filterValues.orderBy] : []
        let index = order.indexOf(string)
        if (index > -1) {
            order[index] = "-" + string
        } else {
            index = order.indexOf("-" + string)
            index > -1 ? order.splice(index, 1) : order.unshift(string)
        }
        this.setState({ filterValues: { ...this.state.filterValues, orderBy: order } })
    };

    updateSelection = (selection) => {
        if (Array.isArray(selection)) {
            this.setState({ selectedIds: selection })
        } else {
            if (this.state.selectedIds.includes(selection))
                this.setState({ selectedIds: this.state.selectedIds.filter(item => item != selection) })
            else
                this.setState({ selectedIds: [...this.state.selectedIds, selection] })
        }
    }
    componentDidMount() {
        if (window.location.hash && tabs.map(item => item.id).includes(window.location.hash)) {
            let tabIndex = tabs.findIndex(tab => tab.id === window.location.hash)
            this.setState({ filterValues: { ...tabs[tabIndex].filters }, activeTab: tabs[tabIndex].name })
        } else {
            window.location.hash = tabs[0].id
            this.setState({ filterValues: { ...tabs[0].filters }, activeTab: tabs[0].name })
        }
    }
    changeTab = (e) => {
        let name = e.target.name
        let value = e.target.value
        let tabIndex = tabs.findIndex(tab => tab.name === value)
        window.location.hash = tabs[tabIndex].id
        this.setState({ [name]: value, filterValues: { ...tabs[tabIndex].filters } })
    }

    render() {
        return (
            <Container fluid className="AvailableCarsContainer CarListContainer">
                <Nav pills className="available-car-wrap">
                    {tabs && tabs.length > 0 && tabs.map(tab =>
                        <NavItem key={tab.id}>
                            <Button color="primary" name={"activeTab"} value={tab.name} className={this.state.activeTab === tab.name ? "active" : "inactive"} onClick={this.changeTab}>
                                {tab.name && tab.name === "Available Cars" ? <i className="fa fa-car pr-3" aria-hidden="true"></i> : <i className="fa fa-calendar-o pr-3" aria-hidden="true"></i>}
                                {tab.name}
                            </Button>
                        </NavItem>
                    )}
                </Nav>
                <AvailableCarsHeader
                    activeTab={this.state.activeTab}
                    filterValues={this.state.filterValues}
                    setFilterValues={(filterValues) => this.setState({ filterValues })}
                    selectedIds={this.state.selectedIds}
                    location={this.props.location} history={this.props.history}
                    defaultFilters={this.state.activeTab && tabs.find(tab => tab.name === this.state.activeTab).filters}
                    resetSelectedIds={() => this.setState({ selectedIds: [] })}
                    refetchQuery={() => this.setState({ refetchCars: !this.state.refetchCars })}
                />
                <Row>
                    <Col sm="12">
                        {this.state.activeTab && this.state.activeTab === "Available Cars" &&
                            <CarsList
                                currentUser={this.props.currentUser}
                                isReady={this.state.filterValues && this.state.filterValues.isReady !== null ? this.state.filterValues.isReady : null}
                                searchTerm={this.state.filterValues && this.state.filterValues.searchTerm !== null ? this.state.filterValues.searchTerm : null}
                                orderBy={this.state.filterValues && this.state.filterValues.orderBy ? this.state.filterValues.orderBy : null}
                                updateOrder={this.updateOrder}
                                carTags={this.state.filterValues && this.state.filterValues.carTags ? this.state.filterValues.carTags : null}
                            />
                        }
                        {this.state.activeTab && this.state.activeTab === "Scheduled Returns" &&
                            <ReturnsList
                                currentUser={this.props.currentUser}
                                searchTerm={this.state.filterValues && this.state.filterValues.searchTerm !== null ? this.state.filterValues.searchTerm : null}
                                activeTab="Available Cars"
                                selectedDrivers={this.state.selectedIds}
                                updateDriversSelection={this.updateSelection}
                                orderBy={this.state.filterValues && this.state.filterValues.orderBy ? this.state.filterValues.orderBy : null}
                                agreementTypes={this.state.filterValues && this.state.filterValues.agreementTypes ? this.state.filterValues.agreementTypes : null}
                                agreementStage={10}
                                returnStage={this.state.filterValues && this.state.filterValues.returnType === "Confirmed Returns" ? [2] : [1]}
                                updateOrder={this.updateOrder}
                                startDate={this.state.filterValues && this.state.filterValues.selectionRange && this.state.filterValues.selectionRange[0].startDate && this.state.filterValues.selectionRange[0].endDate ? moment(this.state.filterValues.selectionRange[0].startDate).tz("America/New_York").format("YYYY-MM-DD") : null}
                                endDate={this.state.filterValues && this.state.filterValues.selectionRange && this.state.filterValues.selectionRange[0].startDate && this.state.filterValues.selectionRange[0].endDate ? moment(this.state.filterValues.selectionRange[0].endDate).tz("America/New_York").format("YYYY-MM-DD") : null}
                            />
                        }
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default compose(
    withApollo,
    graphql(TagsQuery, {
        props({ data: { loading, carTags } }) {
            return { carTags }
        }
    }),
)(AvailableCarsContainer)
