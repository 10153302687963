import React, { Component } from 'react'
import { compose, withApollo } from 'react-apollo'
import { graphql } from 'react-apollo'
import { Badge, Button, UncontrolledTooltip } from 'reactstrap'
import './CarDetail.css'
import { CarInfoQuery } from './Queries'
import ReservationModalNew from "../../Reservations/Modals/ReservationModalNew"
import toTitleCase from '../../Functions/toTitleCase'
import { Nav, NavItem, NavLink, } from 'reactstrap'
import OverviewTab from './OverviewTab'
import InsuranceTab from './InsuranceTab/InsuranceTab'
import RentalTab from './RentalTab/RentalTab'
import PricingTab from "./PricingTab/PricingTab"
import EquipmentsTab from "./EquipmentTab/EquipmentTab"
import DocumentsTab from "./DocumentTab/DocumentsTab"
import TrackersTab from "./TrackersTab/TrackersTab"
import CarIssuesTabContainer from "./CarIssuesTab/CarIssuesTabContainer"
import renderEnumToInt from '../../Functions/renderEnumToInt'
import DeleteCarModal from "./DeleteCarModal"
import moment from "moment"
import CopyToClipboard from "../../Material/CopyToClipboard"
import InspectionTab from "./InspectionTab/InspectionTab"
import { cleanDocumentUrl } from "../../Material/DocumentModal/DocumentsListNew"
import DocumentModalNew from "../../Material/DocumentModal/DocumentModalNew"
import { HasPermissionsQuery } from "../../Functions/querys"

const CAR_DETAIL_PAGE_TABS = [
    { id: "#overview", name: "OVERVIEW" },
    { id: "#rentals", name: "RENTALS" },
    { id: "#carinsurance", name: "INSURANCE" },
    { id: "#documents", name: "DOCUMENTS" },
    { id: "#carpricing", name: "PRICING" },
    { id: "#carequipments", name: "EQUIPMENTS" },
    { id: "#cartrackers", name: "TRACKERS" },
    { id: "#issues", name: "ISSUES" },
    { id: "#inspections", name: "INSPECTIONS" },
]

class CarDetailContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeIndex: 0,
            newView: true,
            activeReservation: this.props.car &&
                this.props.car.carReservation &&
                this.props.car.carReservation.edges &&
                this.props.car.carReservation.edges.length > 0 ?
                this.props.car.carReservation.edges.find(reservation => reservation.node.status === "Open") : null,
            tab: "overview"
        }
    }
    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.car !== this.props.car) {
            let activeReservation = this.props.car && this.props.car.carReservation && this.props.car.carReservation.edges.length > 0 ? this.props.car.carReservation.edges.find(reservation => reservation.node.status === "Open") : null
            this.setState({ activeReservation })
        }
        if (this.state.tab !== prevState.tab) {
            window.location.hash = this.state.tab
        }
    }
    componentDidMount() {
        let defaultTab = CAR_DETAIL_PAGE_TABS[0].id
        if (window.location.hash && CAR_DETAIL_PAGE_TABS.map(item => item.id).includes(window.location.hash)) {
            this.setState({ tab: window.location.hash })
        } else {
            window.location.hash = defaultTab
            this.setState({ tab: defaultTab })
        }
    }
    viewImages(picturesObject) {
        let tmpArr = []
        if (picturesObject && picturesObject.length > 0) {
            const toBeAdded = picturesObject.map((pic, i) => ({ id: pic.node.id, documentUrl: cleanDocumentUrl(pic.node.pictureUrl), description: pic.node.description, dateAdded: pic.node.dateAdded, name: pic.node.pictureType }))
            tmpArr = [...tmpArr, ...toBeAdded]
        }
        this.setState({ checkoutPictures: tmpArr, selectedImage: tmpArr[0] })
        this.toggleModal("checkoutPicturesModal")
    }
    next = () => {
        const checkoutPictures = this.state.checkoutPictures
        const currentIndex = checkoutPictures.findIndex(item => item.id === this.state.selectedImage.id)
        if (currentIndex === checkoutPictures.length - 1) return
        this.setState({ selectedImage: checkoutPictures[currentIndex + 1] })
    }

    prev = () => {
        const checkoutPictures = this.state.checkoutPictures
        const currentIndex = checkoutPictures.findIndex(item => item.id === this.state.selectedImage.id)
        if (currentIndex === 0) return
        this.setState({ selectedImage: checkoutPictures[currentIndex - 1] })
    }
    render() {
        const { currentUser } = this.props
        return (
            <div className="car-detail-container">

                {this.state.openModal === "checkoutPicturesModal" &&
                    <DocumentModalNew
                        document={this.state.selectedImage}
                        handleClose={() => this.setState({ selectedImage: null, openModal: "" })}
                        next={this.next}
                        prev={this.prev}
                        objectType={"car"}
                        isPicture={true}
                        currentUser={this.props.currentUser}
                    />
                }
                <div>
                    <div>
                        {this.props.car && <>
                            <div className="car-header">
                                <span className="car-id-wrapper">
                                    #{this.props.car.pk}
                                </span>
                                <div className="car-image">
                                    <i className="fa fa-2x fa-car" aria-hidden="true"></i>
                                </div>
                                <div>
                                    <p className="car-name">
                                        <label>
                                            {this.props.car && this.props.car.carModel ?
                                                this.props.car.year + " " + toTitleCase(this.props.car.carModel.make) + " " + toTitleCase(this.props.car.carModel.name) : ""
                                            }
                                            &nbsp;
                                            <small>
                                                {this.props.car && this.props.car.carModel && this.props.car.carModel.groupType ? this.props.car.carModel.groupType.name : ""}
                                            </small>
                                        </label>
                                        &nbsp;&nbsp;
                                        {this.props.car && this.props.car.hasTracker ?
                                            (this.props.car.trackerStatus === "Disabled" ? <Badge className="danger">Disabled</Badge> :
                                                this.props.car.trackerStatus === "Repo" && <Badge className="warning">Repo</Badge>)
                                            : <Badge className="black">No tracker</Badge>
                                        }
                                    </p>
                                </div>
                                <div>
                                    <p className="header-detail">{this.props.car && this.props.car.vin ? this.props.car.vin : "--"}&nbsp;&nbsp;<CopyToClipboard textToCopy={this.props.car && this.props.car.vin ? this.props.car.vin : "--"} displayText={"vin"} /></p>
                                </div>
                                <div>
                                    <p className="header-detail">{this.props.car && this.props.car.dmvPlate ? this.props.car.dmvPlate : "--"}&nbsp;&nbsp;<CopyToClipboard textToCopy={this.props.car && this.props.car.dmvPlate ? this.props.car.dmvPlate : "--"} displayText={"dmv plate"} /></p>
                                </div>
                                <div>
                                    <Badge className='bos-badge-blue'>{this.props.car && this.props.car.branch ? this.props.car.branch.name : "--"}</Badge>
                                    <Badge className="black">STAGE {this.props.car && this.props.car.stage ? renderEnumToInt(this.props.car.stage) : "--"}</Badge>
                                </div>
                                <div className="text-success header-detail reserve-car">
                                    {this.state.openModal === "NewReservationModal" &&
                                        <ReservationModalNew
                                            handleClose={() => this.toggleModal("")}
                                            open={this.state.openModal === "NewReservationModal"}
                                            title={"Create New Reservation"}
                                            refetchQuery={this.props.refetchCarQuery}
                                            skip={true}
                                            carId={this.props.car.id}
                                        />
                                    }
                                    {this.props.car && !this.props.car.currentAgreement && !this.state.activeReservation &&
                                        <Button className="ghost"><a onClick={() => this.toggleModal("NewReservationModal")}>RESERVE NOW&nbsp;&nbsp;<i className="fa fa-lg fa-calendar-o" aria-hidden="true"></i></a></Button>
                                    }
                                </div>
                                {this.props.car && this.props.car.lastCheckout && this.props.car.lastCheckout.carpicturesSet && this.props.car.lastCheckout.carpicturesSet.edges && this.props.car.lastCheckout.carpicturesSet.edges.length > 0 && <>
                                    <i className="fa fa-file-image-o fa-lg mt-auto mb-2" id={"viewCheckoutImages"} aria-hidden="true" onClick={() => this.viewImages(this.props.car.lastCheckout.carpicturesSet.edges)}></i>
                                    <UncontrolledTooltip placement="top" target={"viewCheckoutImages"}>Checkout Pictures @ {moment(this.props.car.lastCheckout.dateAdded).tz("America/New_York").format("lll")}</UncontrolledTooltip>
                                </>}
                                {this.props.car && this.props.car.canDelete && this.props.hasPermissions && this.props.hasPermissions.includes("delete_car") &&
                                    <div className="float-right">
                                        <Button color="danger" id="delete-car" style={{ cursor: "pointer" }} onClick={() => this.toggleModal("deleteCarModal")} ><i className="fa fa-lg fa-trash" aria-hidden="true"></i></Button>
                                        {this.state.openModal === "deleteCarModal" &&
                                            <DeleteCarModal
                                                handleClose={() => this.toggleModal("")}
                                                open={this.state.openModal === "deleteCarModal"}
                                                car={this.props.car}

                                            />
                                        }
                                        <UncontrolledTooltip placement="top" target="delete-car">Delete Car</UncontrolledTooltip>
                                    </div>
                                }
                            </div>
                        </>
                        }
                    </div>
                    <div className="car-detail">
                        <Nav tabs>
                            {CAR_DETAIL_PAGE_TABS.map(item =>
                                <NavItem key={item.id} className={this.state.tab === item.id ? "active" : "inactive"}>
                                    <NavLink className={this.state.tab === item.id ? "active" : "inactive"} onClick={() => this.setState({ tab: item.id })}>
                                        {item.name}
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                        {this.state.tab === "#overview" && <OverviewTab {...this.props} currentUser={this.props.currentUser} />}
                        {this.state.tab === "#documents" && <DocumentsTab currentUser={this.props.currentUser} id={this.props.car && this.props.car.id} />}
                        {this.state.tab === "#carinsurance" && <InsuranceTab id={this.props.car && this.props.car.id}  {...this.props} currentUser={this.props.currentUser} />}
                        {this.state.tab === "#rentals" && <RentalTab pageType={"car"}  {...this.props} currentUser={this.props.currentUser} />}
                        {this.state.tab === "#carpricing" && <PricingTab id={this.props.car && this.props.car.id} currentUser={this.props.currentUser} />}
                        {this.state.tab === "#carequipments" && <EquipmentsTab {...this.props} currentUser={this.props.currentUser} />}
                        {this.state.tab === "#cartrackers" && <TrackersTab {...this.props} currentUser={this.props.currentUser} />}
                        {this.state.tab === "#issues" && <CarIssuesTabContainer id={this.props.car && this.props.car.id} {...this.props} currentUser={this.props.currentUser} />}
                        {this.state.tab === "#inspections" && <InspectionTab id={this.props.car && this.props.car.id} {...this.props} currentUser={this.props.currentUser} />}
                    </div>
                </div >
            </div >
        )
    }
}

export default compose(
    withApollo,
    graphql(HasPermissionsQuery, {
        options: () => ({ variables: { userPermissions: ["delete_car"] }, fetchPolicy: "network-only", notifyOnNetworkStatusChange: true }),
        props({ data: { hasPermissions, loading, variables } }) {
            return { hasPermissions, loading, variables }
        }
    }),
    graphql(CarInfoQuery, {
        options: ({ match }) => ({ variables: { pk: match.params.pk } }),
        props({ data: { car, loading, refetch, variables } }) {
            return {
                car, loading, variables,
                refetchCarQuery: () => {
                    return refetch({
                        query: CarInfoQuery,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { car: fetchMoreResult.car }
                        },
                    })
                },
            }
        }
    }),
)(CarDetailContainer)
