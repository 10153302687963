import React, { Component } from 'react';
import { ApolloProvider } from 'react-apollo';
import client from './GraphQL.js';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import {BrowserRouter as Router} from 'react-router-dom'
import RoutingInfoNew from './RoutingInfoNew.js';


class App extends Component {
  render() {
    return (
      <ApolloProvider client={client}>
          <Router>
            <RoutingInfoNew/>
          </Router>
      </ApolloProvider>
    );
  }
}
export default App;
