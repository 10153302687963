import React, { Component, Fragment } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import { Col, Row, Label, Nav, NavItem, NavLink, Card } from 'reactstrap'
import Loader from "../../../Material/Loader"
import "react-awesome-lightbox/build/style.css"
import DatetimeRenderer from "../../../Material/DatetimeRenderer"
import gql from 'graphql-tag'
import DocumentModalNew from "../../../Material/DocumentModal/DocumentModalNew"
import CarIssueModal from "../../../Maintenance/CarIssueModal"
import { cleanDocumentUrl } from "../../../Material/DocumentModal/DocumentsListNew"
import Table from "reactstrap/lib/Table"

const IncidentDamageSeverityLevelQuery = gql`{
  damageSeverityLevels: __type(name: "IncidentDamageSeverity") {
    states: enumValues {
      name
      description
    }
  }
}
`
const CarConditionDetailQuery = gql`query carCondition($id: ID!){
    carCondition(carConditionId:$id){
        id
        dateAdded
        dateModified
        formData
        gas
        mileage
        user{
          id
          username
        }
          incidentSet {
            edges {
                node {
                    id
                    damageTransaction {
                        id
                        pk
                        dateAdded
                        dynamicAmount
                        notes
                        chargeType{
                            id
                            name
                        }
                    }
                    incidentdamageSet {
                        edges {
                            node {
                                id
                                projectedCost
                                severity
                                pictures{
                                    edges{
                                        node{
                                            id
                                            dateAdded
                                            pictureUrl
                                            pictureType
                                            description
                                        }
                                    }
                                }
                                damage {
                                    id
                                    car{
                                        id
                                        pk
                                    }
                                    dateAdded
                                    description: notes
                                    status
                                    reportedBy
                                    canPickup
                                    issueCategory {
                                        id
                                        name: category
                                    }
                                    vendor{
                                        id
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        carpicturesSet {
          edges {
            node {
              id
              dateAdded
              pictureUrl
              pictureType
              description
            }
          }
        }
      
    }     
}`

class CarInspectionDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            picturesSetSubsection: "walkaroundPictures",
            carInspectionSubsection: "inspectionDetails",
        }
    }

    toggleModal = (modalName) => {
        if (this.state.openModal === modalName) {
            this.setState({ openModal: "" })
        } else {
            this.setState({ openModal: modalName })
        }
    }

    toggleSectionNav = (sectionName, value) => {
        this.setState({ [sectionName]: value })
    }

    viewPictures = (carPictures, index = 0) => {
        let pictures = carPictures.map(picture => ({ documentUrl: cleanDocumentUrl(picture.node.pictureUrl), id: picture.node.id, name: picture.node.pictureType, description: picture.node.description, dateAdded: picture.node.dateAdded }))
        this.setState({ carPictures: pictures, selectedImage: pictures[index] })
    }

    pObj = (jsonString) => {
        let obj = JSON.parse(jsonString)
        return obj
    }
    next = () => {
        const carPictures = this.state.carPictures
        const currentIndex = carPictures.findIndex(item => item.id === this.state.selectedImage.id)
        if (currentIndex === carPictures.length - 1) return
        this.setState({ selectedImage: carPictures[currentIndex + 1] })
    }

    prev = () => {
        const carPictures = this.state.carPictures
        const currentIndex = carPictures.findIndex(item => item.id === this.state.selectedImage.id)
        if (currentIndex === 0) return
        this.setState({ selectedImage: carPictures[currentIndex - 1] })
    }

    render() {
        const IssuesTable = [
            { id: "date_addded", name: "DATE ADDED", sortable: true },
            { id: "issueCategory", name: "Category", sortable: false },
            { id: "status", name: "Status", sortable: false },
            { id: "projectedCost", name: "Projected Cost", sortable: false },
            { id: "severity", name: "Severity Level", sortable: false },
            { id: "reportedBy", name: "Reported By", sortable: false },
            { id: "canPickup", name: "Can Pickup", sortable: false },
            { id: "pictures", name: "Pictures", sortable: false },
        ]
        return (
            <>
                <Col className="zero-padding">
                    {this.state.selectedImage &&
                        <DocumentModalNew
                            document={this.state.selectedImage}
                            handleClose={() => this.setState({ selectedImage: null })}
                            next={this.next}
                            prev={this.prev}
                            objectType={"car"}
                            isPicture={true}
                            refetch={this.props.refetch}
                            currentUser={this.props.currentUser}
                        />
                    }
                    {this.props.loading ? <Loader /> : <>
                        <div className="inspection-detail">
                            <Nav tabs>
                                <NavItem className={this.state.tab === "inspectionDetails" ? "active" : "inactive"}>
                                    <NavLink className={this.state.carInspectionSubsection === "inspectionDetails" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("carInspectionSubsection", "inspectionDetails")}>
                                        INSPECTION DETAILS
                                    </NavLink>
                                </NavItem>
                                <NavItem className={this.state.tab === "carIssue" ? "active" : "inactive"}>
                                    <NavLink className={this.state.carInspectionSubsection === "carIssue" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("carInspectionSubsection", "carIssue")}>
                                        CAR ISSUES
                                    </NavLink>
                                </NavItem>
                                <NavItem className={this.state.tab === "damageTransactions" ? "active" : "inactive"}>
                                    <NavLink className={this.state.carInspectionSubsection === "damageTransactions" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("carInspectionSubsection", "damageTransactions")}>
                                        DAMAGE TRANSACTION
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <div className="info-container" style={{ margin: "0px", borderTopLeftRadius: "0px" }}>
                                <Row>
                                    <Col>
                                        {this.props.carCondition ?
                                            this.state.carInspectionSubsection === "inspectionDetails" ?
                                                <div>
                                                    <Row>
                                                        <Col>
                                                            <Label>Date Added</Label>
                                                            <p>{this.props.carCondition.dateAdded ? <DatetimeRenderer datetime={this.props.carCondition.dateAdded} /> : "--"}</p>
                                                        </Col>
                                                        <Col>
                                                            <Label>Date Modified</Label>
                                                            <p>{this.props.carCondition.dateModified ? <DatetimeRenderer datetime={this.props.carCondition.dateModified} /> : "--"}</p>
                                                        </Col>
                                                        <Col>
                                                            <Label>Inspection Type</Label>
                                                            <p>
                                                                {this.props.carCondition.formData && this.pObj(this.props.carCondition.formData) && "form_type" in this.pObj(this.props.carCondition.formData) ? this.pObj(this.props.carCondition.formData)["form_type"] : "---"}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Label>User</Label>
                                                            <p className="columnContent">
                                                                {this.props.carCondition.user ? this.props.carCondition.user.username : "---"}
                                                            </p>
                                                        </Col>
                                                        <Col>
                                                            <Label>Gas</Label>
                                                            <p className="columnContent">{this.props.carCondition.gas}</p>
                                                        </Col>
                                                        <Col>
                                                            <Label>Mileage</Label>
                                                            <p className="columnContent">{this.props.carCondition.mileage}</p>
                                                        </Col>
                                                    </Row>
                                                </div> :
                                                this.state.carInspectionSubsection === "carIssue" ?
                                                    <div>
                                                        <Table responsive>
                                                            <thead>
                                                                <tr>
                                                                    {IssuesTable.map(tableHeader =>
                                                                        <th key={tableHeader.id}>{tableHeader.name}</th>
                                                                    )}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.props.carCondition && this.props.carCondition.incidentSet && this.props.carCondition.incidentSet.edges ? this.props.carCondition.incidentSet.edges.map(incident => incident.node.incidentdamageSet.edges.map((incidentDamage, i) =>
                                                                    <tr key={`incidentDamage__${i}`}>
                                                                        <td>{incidentDamage.node.damage.dateAdded ? <DatetimeRenderer datetime={incidentDamage.node.damage.dateAdded} /> : "---"}</td>
                                                                        <td>
                                                                            {this.state.openModal === "editCarIssue" + i && <CarIssueModal open={this.state.openModal === "editCarIssue" + i} isUpdate carIssue={incidentDamage.node.damage} handleClose={() => this.toggleModal("editCarIssue" + i)} refetchQuery={this.props.refetchQuery} />}
                                                                            {incidentDamage.node.damage.issueCategory ?
                                                                                <a className="bos-custom-link" onClick={() => this.toggleModal("editCarIssue" + i)} target="_blank">
                                                                                    {incidentDamage.node.damage.issueCategory.name}
                                                                                </a>
                                                                                : "---"}
                                                                        </td>
                                                                        <td>{incidentDamage.node.damage.status ? incidentDamage.node.damage.status : "---"}</td>
                                                                        <td>{incidentDamage.node.projectedCost ? `$${incidentDamage.node.projectedCost}` : "---"}</td>
                                                                        <td>{this.props.damageSeverityLevels && this.props.damageSeverityLevels.states && this.props.damageSeverityLevels.states.find(item => item.name == incidentDamage.node.severity).description}</td>
                                                                        <td>{incidentDamage.node.damage.reportedBy ? incidentDamage.node.damage.reportedBy : "---"}</td>
                                                                        <td>{incidentDamage.node.damage.canPickup ? <i className="fa fa-check"></i> : <i className="fa fa-times"></i>}</td>
                                                                        <td>
                                                                            {incidentDamage.node.pictures && incidentDamage.node.pictures.edges ?
                                                                                <div onClick={() => this.viewPictures(incidentDamage.node.pictures.edges)}>
                                                                                    <i className="fa fa-file-image-o fa-lg" aria-hidden="true"></i>
                                                                                    {incidentDamage.node.pictures.edges.length > 1 &&
                                                                                        <a className="bos-custom-link" target="_blank">
                                                                                            {" "}+{incidentDamage.node.pictures.edges.length - 1} more
                                                                                        </a>}
                                                                                </div> :
                                                                                "No Pictures Found"}
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                                    : <tr><td>No Car Issues Found</td></tr>}
                                                            </tbody>
                                                        </Table>
                                                    </div> :
                                                    <div>
                                                        <Table responsive>
                                                            <thead>
                                                                <tr>
                                                                    <th>ID</th>
                                                                    <th>Date Added</th>
                                                                    <th>Charge Type</th>
                                                                    <th>Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {this.props.carCondition && this.props.carCondition.incidentSet && this.props.carCondition.incidentSet.edges ? this.props.carCondition.incidentSet.edges.map(incident =>
                                                                    incident.node.damageTransaction &&
                                                                    <tr>
                                                                        <td>{incident.node.damageTransaction.pk}</td>
                                                                        <td>{incident.node.damageTransaction.dateAdded ? <DatetimeRenderer datetime={incident.node.damageTransaction.dateAdded} /> : "---"}</td>
                                                                        <td>{incident.node.damageTransaction.chargeType ? incident.node.damageTransaction.chargeType.name : "---"}<br /><small>{incident.node.damageTransaction.notes}</small></td>
                                                                        <td>{incident.node.damageTransaction.dynamicAmount ? `$${incident.node.damageTransaction.dynamicAmount}` : "---"}</td>
                                                                    </tr>
                                                                ) : <tr style={{ color: "red" }}><td colSpan={4}>No Damage Transactions Found!</td></tr>}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                            : <p style={{ color: "red" }}>No Car Condition Found!</p>
                                        }
                                    </Col>
                                </Row>
                            </div>
                            <br />
                            <Nav tabs>
                                <NavItem className={this.state.tab === "walkaroundPictures" ? "active" : "inactive"}>
                                    <NavLink className={this.state.picturesSetSubsection === "walkaroundPictures" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("picturesSetSubsection", "walkaroundPictures")}>
                                        Walkaround Pictures
                                    </NavLink>
                                </NavItem>
                                <NavItem className={this.state.tab === "otherPictures" ? "active" : "inactive"}>
                                    <NavLink className={this.state.picturesSetSubsection === "otherPictures" ? "active navItem" : "navItem"} onClick={() => this.toggleSectionNav("picturesSetSubsection", "otherPictures")}>
                                        Other Pictures
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            {this.state.picturesSetSubsection === "walkaroundPictures" ?
                                <div className="info-container" style={{ maxHeight: "500px", overflow: "auto" }}>
                                    <Row>
                                        <Col>
                                            <Fragment>
                                                <div className="DocumentCardContainer" >
                                                    {this.props.carCondition && this.props.carCondition.carpicturesSet && this.props.carCondition.carpicturesSet.edges.filter(picture => picture.node.pictureType.toLowerCase() === "exterior walkaround").map((document, index) =>
                                                        <Card className="DocumentCard" key={index}>
                                                            <div className="DocumentCardHeader" onClick={() => this.viewPictures(this.props.carCondition.carpicturesSet.edges.filter(picture => picture.node.pictureType.toLowerCase() === "exterior walkaround"), index)}>
                                                                <p className="DocumentCardTitle">
                                                                    <span><i className="fa fa-picture-o" aria-hidden="true"></i></span>&nbsp;&nbsp;
                                                                    <span>{document.node.pictureType} <small>({document.node.description})</small></span>
                                                                </p>
                                                                <p><i className="fa fa-arrows-alt"></i></p>
                                                            </div>
                                                            <div className="DocumentCardPreview" >
                                                                {document.node ? (
                                                                    <img className="DocumentCardPreviewEmbed DocViewerImage" src={document.node.pictureUrl} alt="Document" />
                                                                ) : ''}
                                                            </div>
                                                        </Card>
                                                    )}
                                                </div >
                                            </Fragment>
                                        </Col>
                                    </Row>
                                </div> :
                                <div className="info-container" style={{ maxHeight: "500px", overflow: "auto" }}>
                                    <Row>
                                        <Col>
                                            <Fragment>
                                                <div className="DocumentCardContainer" >
                                                    {this.props.carCondition && this.props.carCondition.carpicturesSet && this.props.carCondition.carpicturesSet.edges.filter(picture => picture.node.pictureType.toLowerCase() !== "exterior walkaround").map((document, index) =>
                                                        <Card className="DocumentCard" key={index}>
                                                            <div className="DocumentCardHeader" onClick={() => this.viewPictures(this.props.carCondition.carpicturesSet.edges.filter(picture => picture.node.pictureType.toLowerCase() !== "exterior walkaround"), index)}>
                                                                <p className="DocumentCardTitle">
                                                                    <span><i className="fa fa-picture-o" aria-hidden="true"></i></span>&nbsp;&nbsp;
                                                                    <span>{document.node.pictureType} <small>({document.node.description})</small></span>
                                                                </p>
                                                                <p><i className="fa fa-arrows-alt"></i></p>
                                                            </div>
                                                            <div className="DocumentCardPreview" >
                                                                {document.node ? (
                                                                    <img className="DocumentCardPreviewEmbed DocViewerImage" src={document.node.pictureUrl} alt="Document" />
                                                                ) : ''}
                                                            </div>
                                                        </Card>
                                                    )}
                                                </div >
                                            </Fragment>
                                        </Col>
                                    </Row>
                                </div>
                            }
                        </div>
                    </>}
                </Col >
            </>
        )
    }
}

export default compose(
    withApollo,
    graphql(CarConditionDetailQuery, {
        options: ({ id }) => ({ variables: { id }, fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true, }),
        props({ data: { carCondition, loading, refetch, variables } }) {
            return {
                carCondition, loading, variables,
                refetchQuery: () => {
                    return refetch({
                        query: CarConditionDetailQuery,
                        variables: {
                            ...variables,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            return { carCondition: fetchMoreResult.carCondition }
                        },
                    })
                },
            }
        }
    }),
    graphql(IncidentDamageSeverityLevelQuery, {
        props({ data: { damageSeverityLevels, loading } }) {
            return {
                damageSeverityLevels,
                loading
            }
        }
    })
)(CarInspectionDetail)